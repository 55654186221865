import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

import PageIntroContainer from '../../containers/PageIntroContainer';
import OurWorkContainer from '../../containers/OurWorkContainer';
import OurBusinessContainer from '../../containers/OurBusinessContainer';

export default class CorporateInformation extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'ourWork' }} />
        <PageIntroContainer curPage={"ourWork"} />
        <OurWorkContainer />
        <WebTrends />
      </main>
    );
  }
}
