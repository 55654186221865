import { connect } from 'react-redux';
import React, { Component}  from 'react';
import CorporateInformation from '../components/CorporateInformation/CorporateInformation';

const mapStateToProps = (state, ownProps) => ({
  homeReducer: state.homeReducer,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(CorporateInformation);


