import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const isETSPage =  window.location.href.toLowerCase().indexOf('/executive-trainee-programme') >  0 ;
let TDCHomepage;


export default class HeaderLogo extends Component {
  

  render() {
	
	switch(this.props.locale){
		case 'tc':
			TDCHomepage = 'https://www.hktdc.com/tc';
			break;
		case 'sc':
			TDCHomepage = 'https://www.hktdc.com/sc';
			break;
		default:
			TDCHomepage=  'https://www.hktdc.com';
			break;
	}
	
	
	
    return isETSPage?(<a href={TDCHomepage}  className="header-logo">
		<img src={logo} className="header-logo__img" alt="logo" />
	</a>):(
      <a href={`${process.env.PUBLIC_URL}`+"/"+this.props.locale+"/"} className="header-logo">
        <img src={logo} className="header-logo__img" alt="logo" />
        {/* <small className="header-logo__tag">Your Business • Our Mission</small> */}
      </a>
    );
  }
}