
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import PageItemSlider from '../components/PageItem/PageItemSlider';

const mapStateToProps = state => ({
  dataReducer: state.dataReducer,
	itemsReducer: state.itemsReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(PageItemSlider);