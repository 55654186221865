import React, { Component } from "react";
import store from "../../store/";
import { fetchSocialMediaData, fetchItemsData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';
import Modal from 'react-responsive-modal';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import MediaBlock from './MediaBlock';
import StackGrid from "react-stack-grid";
import sizeMe from 'react-sizeme';
import PageIntroContainer from '../../containers/PageIntroContainer';

class Media extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
		this.state = { isOpened: [], modalOpen: false, modalQrcode: '', modalQrInfo: '' };
		//store.dispatch(fetchSocialMediaData("/data/social-media.json"));
		store.dispatch(fetchSocialMediaData(CMSAPIs.apiSocialMedia));		
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		//store.dispatch(fetchItemsData("/data/page-items-" + currentLang + ".json"));
	}



	generateSocialMediaBlock(socialMediaData) {
		var socialMediaDataArr = [];
		if(!Array.isArray(socialMediaData)){
			socialMediaDataArr.push(socialMediaData);
			socialMediaData = socialMediaDataArr;
		}

		return socialMediaData.map((data, i) => {
			return (<MediaBlock key={i} media={data} onOpenModal={this.onOpenModal} />);
		});
	}

	onOpenModal = (e, link) => {
		e.preventDefault();
		console.log({link})
		this.setState({ modalOpen: true, modalQrcode: link.qrcode, modalQrInfo: link.qrcodeInfo })
	}

	onCloseModal = () => {
		this.setState({ modalOpen: false, modalQrcode: '' })
	}

	render() {
		const {
      size: { width },
    } = this.props;

    const { socialMediaReducer } = this.props;
    var socialMediaData = socialMediaReducer.data;
		// console.log("social media data");
		// console.log(this.props);
		return (
      <section className='page-items'>
        <div className='socialMedia'>
          <PageIntroContainer curPage={'socialMedia'} />
          <div className='wrapper'>
            <StackGrid
              columnWidth={width <= 768 ? '90%' : 350}
              gutterWidth={20}
              gutterHeight={20}
            >
              {this.generateSocialMediaBlock(socialMediaData)}
            </StackGrid>
            <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
              <div className='button-share__wcqr'>
								<img src={CMSAPIs.generateSrcPath(this.state.modalQrcode)} />
                <p className="button-share__wcqr-txt">{this.props.data?.data?.share?.wechatDesc}</p>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    );
	}
}

export default sizeMe()(Media);