import React, { Component } from 'react';

export default class Sitemap extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { dataReducer } = this.props;
    const { data } = dataReducer || {};

    const { footer = {} } = data || {};
    const { sitemap = {} } = footer || {};

    const sitemapCols = sitemap.sitemapCols;
    const quicklinks = sitemap.quicklinks;

    return (
      <div className="sitemap">
        <div className="content-wrapper">
          <div className="sitemap__row">
            {Object.keys(sitemapCols || {}).map(function (key) {
              let value = sitemapCols[key];
              let headStrong = value.headStrong;
              let headTxt = value.headTxt;
              let headHref = value.headHref;
              let items = value.items

              let childs = [];

              Object.keys(items || {}).map(function (subkey) {
                let childVal = items[subkey];
                let txt = childVal.txt;
                let href = childVal.href;

                childs.push(<a key={subkey} href={href} className="sitemap__link">{txt}</a>);
              })
              return (
                <div key={key} className="sitemap__col">
                  <a href={headHref} className="sitemap__head">
                    <strong>{headStrong}</strong>{headTxt}</a>
                  {childs}
                </div>
              );
            })}
            <div className="sitemap__col">
              <div className="sitemap__quicklinks">
                {Object.keys(quicklinks || {}).map(function (key) {
                  let value = quicklinks[key];
                  let txt = value.txt;
                  let href = value.href;
                  return (
                    <a key={key} href={href} className="sitemap__quicklink">{txt}</a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}