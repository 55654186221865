import React, { Component } from 'react';
import store from '../../store/';
import { fetchEventData } from "../../actions/";

export default class PageEventList extends Component {
  constructor(props) {
		super(props);
		this.state = {};
		this.selectFunc = this.selectFunc.bind(this);
		this.searchFunc = this.searchFunc.bind(this);
	}
	
	selectFunc(cond,e){
		this.setState({
			[cond]: e.target.value
		})
		let filterObj = this.state;
		filterObj[cond] = e.target.value;
		if( cond != "searchKey" ){
			switch (e.target.value) {
				case 'Lorem ipsum dolor sit':
					store.dispatch(fetchEventData('/data/more-events-hk-dummy-1.json'));
					break;
				case 'Lorem ipolor sit':
					store.dispatch(fetchEventData('/data/more-events-hk-dummy-2.json'));
					break;
				default:
					store.dispatch(fetchEventData('/data/more-events-hk.json'));
			}
		}
	}

	searchFunc(){
		if("searchKey" in this.state){
			switch (this.state.searchKey) {
				case 'Lorem ipsum dolor sit':
					store.dispatch(fetchEventData('/data/more-events-hk-dummy-1.json'));
					break;
				case 'Lorem ipolor sit':
					store.dispatch(fetchEventData('/data/more-events-hk-dummy-2.json'));
					break;
				default:
					store.dispatch(fetchEventData('/data/more-events-hk.json'));	
			}
		}
	}

  render() {
		const pageState = this.state;
		const getSelectVal = this.selectFunc;
		const searchClick = this.searchFunc;
		const { eventReducer } = this.props;
		const getEventObj = eventReducer.data || {};

		const filterObj = getEventObj.filterBy || {};
		let event_filter=[];
		{Object.keys(filterObj || {}).map(function (cond, i) {
			event_filter.push(
				<div className={"events-filter-cat-container events-filter-cat-container--"+cond}>
					{pageState.hasOwnProperty(cond)?
						<div className={"filter-cat__title"}>{pageState[cond]}</div>
					:
						<div className={"filter-cat__title"}>{cond}</div>
					}
					<select className={"events-filter-condList events-filter-condList--"+cond} onChange={(e) => getSelectVal(cond, e)}>
						<option className={"filter-condList__cond"} val={"default"}>{cond}</option>
						{filterObj[cond].map((item, i) => <option className={"filter-condList__cond"} value={item}>{item}</option>)}
					</select>
				</div>
			)
		});}

		const eventListHeader = getEventObj.eventListHeader || {};
		let event_list_header=[];
		{Object.keys(eventListHeader || {}).map(function (i) {
			event_list_header.push(
				<div className={"more-events-list-header__elem"}>{eventListHeader[i]}</div>
			);
		});} 

		const eventsTitle = getEventObj.title || {};
		let hasTitle = eventsTitle.length>0;

		const eventList = getEventObj.eventList || {};
		let event_list=[];
		{Object.keys(eventList || {}).map(function (eventKey) {
			let eventVal = eventList[eventKey];
			let applyVal = eventVal["applyBooth"] || {};
			let dlFormVal = eventVal["dlForm"] || {};
			let enquireVal = eventVal["enquire"] || {};

			event_list.push(
				<div className={"more-event-list-container"}>
					<div className={"more-event-list-left-container"}>
						<div className={"more-event-list__elem more-event-list__elem--date"}><div className={"more-list-event__mobile-elem"}>Date</div>{eventVal["date"]}</div>
						<div className={"more-event-list__elem more-event-list__elem--title"}><div className={"more-list-event__mobile-elem"}>Event</div>{eventVal["title"]}</div>
						<div className={"more-event-list__elem more-event-list__elem--details"}><div className={"more-list-event__mobile-elem"}>Details</div>{eventVal["details"]}</div>
						<div className={"more-event-list__elem more-event-list__elem--area"}><div className={"more-list-event__mobile-elem"}>Country / Region</div>{eventVal["countryRegion"]}</div>
						<div className={"more-event-list__elem more-event-list__elem--organiser"}><div className={"more-list-event__mobile-elem"}>Organiser</div>{eventVal["organiser"]}</div>
					</div>
					<div className={"more-event-list-right-container"}>
						<a href={applyVal["href"] || ''} className={"more-event-list__elem more-event-list__elem--apply"}><div className={"elem-img"}>{ applyVal["icon"] ? <img src={`${process.env.PUBLIC_URL}` + applyVal["icon"] || ''} alt={"Icon"}/> :''}</div><div className={"elem__desc"}>{applyVal["txt"] || ''}</div></a>
						<a href={dlFormVal["href"] || ''} className={"more-event-list__elem more-event-list__elem--form"}><div className={"elem-img"}>{ dlFormVal["icon"] ? <img src={`${process.env.PUBLIC_URL}` + dlFormVal["icon"]} alt={"Icon"}/> :''}</div><div className={"elem__desc"}>{dlFormVal["txt"] || ''}</div></a>
						<a href={enquireVal["href"] || ''} className={"more-event-list__elem more-event-list__elem--enquire"}><div className={"elem-img"}>{ enquireVal["icon"] ? <img src={`${process.env.PUBLIC_URL}` + enquireVal["icon"]} alt={"Icon"}/> :''}</div><div className={"elem__desc"}>{enquireVal["txt"] || ''}</div></a>
					</div>
				</div>
			);
		});} 

    return (
      <div className={"page-events-container"}>
				<div className={"events-filter"}>
					<div className="events-filter__title">Filter By</div>
					{event_filter}
					<div className="events-search__input-holder">
						<input name="search-query" type="search" placeholder={getEventObj.searchHints} className="events-serach__input" onChange={(e) => getSelectVal("searchKey", e)}/>
						<button type="submit" className="events-search__input-btn" onClick={() => {searchClick()} }/>
					</div>
				</div>
				{hasTitle ?
					<div className={"events-list-title"}>{eventsTitle}</div>
				: null }
				<div className={"more-events-list-conatiner"}>
					<div className={"more-events-list-header"}>
						{event_list_header}
						<div className={"more-events-list-header__elem"}></div>
					</div>
					{event_list}
				</div>
			</div>
		);
  }
}