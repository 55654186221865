
/*
 * action types for Home Static Data
 */
export const RECV_ERROR = 'RECV_ERROR';
export const REQ_DATA = 'REQ_DATA';
export const RECV_DATA = 'RECV_DATA';

/*
 * action types for Aboutus home Data
 */
export const RECV_HOME_ERROR = 'RECV_HOME_ERROR';
export const REQ_HOME_DATA = 'REQ_HOME_DATA';
export const RECV_HOME_DATA = 'RECV_HOME_DATA';

/*
 * action types for Our Mission Data
 */
export const RECV_MISSION_ERROR = 'RECV_MISSION_ERROR';
export const REQ_MISSION_DATA = 'REQ_MISSION_DATA';
export const RECV_MISSION_DATA = 'RECV_MISSION_DATA';

/*
 * action types for Career Data
 */
export const RECV_CAREER_ERROR = 'RECV_CAREER_ERROR';
export const REQ_CAREER_DATA = 'REQ_CAREER_DATA';
export const RECV_CAREER_DATA = 'RECV_CAREER_DATA';

/*
 * action types for Company Profile Data
 */
export const RECV_COMPANY_ERROR = 'RECV_COMPANY_ERROR';
export const REQ_COMPANY_DATA = 'REQ_COMPANY_DATA';
export const RECV_COMPANY_DATA = 'RECV_COMPANY_DATA';

/*
 * action types for Our Business Data
 */
export const RECV_BUSINESS_ERROR = 'RECV_BUSINESS_ERROR';
export const REQ_BUSINESS_DATA = 'REQ_BUSINESS_DATA';
export const RECV_BUSINESS_DATA = 'RECV_BUSINESS_DATA';

/*
 * action types for Our Work Data
 */
export const RECV_OURWORK_ERROR = 'RECV_OURWORK_ERROR';
export const REQ_OURWORK_DATA = 'REQ_OURWORK_DATA';
export const RECV_OURWORK_DATA = 'RECV_OURWORK_DATA';

/*
 * action types for Event Data
 */
export const RECV_EVENT_ERROR = 'RECV_EVENT_ERROR';
export const REQ_EVENT_DATA = 'REQ_EVENT_DATA';
export const RECV_EVENT_DATA = 'RECV_EVENT_DATA';

/*
 * action types for Management Data
 */
export const RECV_MANAGEMENT_ERROR = 'RECV_MANAGEMENT_ERROR';
export const REQ_MANAGEMENT_DATA = 'REQ_MANAGEMENT_DATA';
export const RECV_MANAGEMENT_DATA = 'RECV_MANAGEMENT_DATA';

/*
 * action types for Corporate Data
 */
export const RECV_CORPORATE_ERROR = 'RECV_CORPORATE_ERROR';
export const REQ_CORPORATE_DATA = 'REQ_CORPORATE_DATA';
export const RECV_CORPORATE_DATA = 'RECV_CORPORATE_DATA';

/*
 * action types for Social Media Data
 */
export const RECV_SOCIAL_MEDIA_ERROR = 'RECV_SOCIAL_MEDIA_ERROR';
export const REQ_SOCIAL_MEDIA_DATA = 'REQ_SOCIAL_MEDIA_DATA';
export const RECV_SOCIAL_MEDIA_DATA = 'RECV_SOCIAL_MEDIA_DATA';

/*
 * action types for Contact Us Data
 */
export const RECV_CONTACTUS_ERROR = 'RECV_CONTACTUS_ERROR';
export const REQ_CONTACTUS_DATA = 'REQ_CONTACTUS_DATA';
export const RECV_CONTACTUS_DATA = 'RECV_CONTACTUS_DATA';

/*
 * action types for Page Intro Data
 */
export const RECV_INTRO_ERROR = 'RECV_INTRO_ERROR';
export const REQ_INTRO_DATA = 'REQ_INTRO_DATA';
export const RECV_INTRO_DATA = 'RECV_INTRO_DATA';

/*
 * action types for Page Items Data
 */
export const RECV_ITEMS_ERROR = 'RECV_ITEMS_ERROR';
export const REQ_ITEMS_DATA = 'REQ_ITEMS_DATA';
export const RECV_ITEMS_DATA = 'RECV_ITEMS_DATA';

/*
 * action types for Header Search
 */
export const RECV_SEARCH_AUTOCOMPLETE_ERROR = 'RECV_SEARCH_AUTOCOMPLETE_ERROR';
export const REQ_SEARCH_AUTOCOMPLETE_DATA = 'REQ_SEARCH_AUTOCOMPLETE_DATA';
export const RECV_SEARCH_AUTOCOMPLETE_DATA = 'RECV_SEARCH_AUTOCOMPLETE_DATA';
export const SWITCH_TOP_SEARCH = 'SWITCH_TOP_SEARCH';

/*
 * action types for Search Result
 */
export const RECV_SEARCH_RESULT_ERROR = 'RECV_SEARCH_RESULT_ERROR';
export const REQ_SEARCH_RESULT = 'REQ_SEARCH_RESULT';
export const RECV_SEARCH_RESULT = 'RECV_SEARCH_RESULT';

/*
 * action types for Mobile App
 */
export const RECV_MOBILE_APP_ERROR = 'RECV_MOBILE_APP_ERROR';
export const REQ_MOBILE_APP_RESULT = 'REQ_MOBILE_APP_RESULT';
export const RECV_MOBILE_APP_RESULT = 'RECV_MOBILE_APP_RESULT';

/*
 * action types for Web Meta
 */
export const RECV_WEB_META_ERROR = 'RECV_WEB_META_ERROR';
export const REQ_WEB_META_DATA = 'REQ_WEB_META_DATA';
export const RECV_WEB_META_DATA = 'RECV_WEB_META_DATA';

/*
 * action types for Executive Trainee Scheme Page
 */
export const RECV_EXECUTIVETRAINEESCHEMEPAGE_ERROR = 'RECV_EXECUTIVETRAINEESCHEMEPAGE_ERROR';
export const REQ_EXECUTIVETRAINEESCHEMEPAGE_DATA = 'REQ_EXECUTIVETRAINEESCHEMEPAGE_DATA';
export const RECV_EXECUTIVETRAINEESCHEMEPAGE_DATA = 'RECV_EXECUTIVETRAINEESCHEMEPAGE_DATA';