const translationsObject = {
    en: {
        navLinks: {
            business: {
                big_title: 'Start',
                small_title: '& Grow Your Business',
            },
            events: {
                big_title: 'Join',
                small_title: 'Fairs, Conferences & Events',
            },
            services: {
                big_title: 'Source',
                small_title: 'Products & Services',
            },
            insights: {
                big_title: 'Explore',
                small_title: 'Industry & Market Insights',
            }
        }
    },
    tc: {
        navLinks: {
            business: {
                big_title: '開始',
                small_title: '發展業務',
            },
            events: {
                big_title: '參與',
                small_title: '嘉年華、會議及活動',
            },
            services: {
                big_title: '尋找',
                small_title: '產品及服務',
            },
            insights: {
                big_title: '探索',
                small_title: '行業及市場動向',
            }
        }
    },
    sc: {
        navLinks: {
            business: {
                big_title: '开始',
                small_title: '发展业务',
            },
            events: {
                big_title: '参与',
                small_title: '嘉年华丶会议及活动',
            },
            services: {
                big_title: '寻找',
                small_title: '产品及服务',
            },
            insights: {
                big_title: '探索',
                small_title: '行业及市场动向',
            }
        }
    }
};
export default translationsObject;