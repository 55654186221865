import React, { Component } from 'react';
import store from '../../store/';
import { fetchSearchAutocomplete, switchTopSearch } from '../../actions/';
import { fetchWebMetaData } from "../../actions/";
import NumberFormat from 'react-number-format';
import { Helmet } from "react-helmet";

export default class SearchPanel extends Component {
    constructor(props) {
        super(props);
        this.handleOptionChange = this.handleOptionChange.bind(this);
		store.dispatch(fetchWebMetaData("/data/web-meta.json"));
    }
    handleKeyPress = (event) => {
        if (event.key == 'Enter') {
            //console.log('enter press here! ');
        } else {
            //this.setState({'showSearchAutocomplete': true})
            store.dispatch(fetchSearchAutocomplete('/data/search-autocomplete.json'));
        }
    }
    handleOptionChange(changeEvent) {
        var tarVal = changeEvent.target.value;
        store.dispatch(switchTopSearch(tarVal));
    }
    render() {
        const { dataReducer } = this.props;
        const { data = {} } = dataReducer || {};

        const txtSearchPanel = data.searchPanel || {};

        //Search Data
        const { ownProps = {} } = this.props;
        const { searchData = {} } = ownProps || {};
        let paramsString = searchData.paramsString;
        let searchQuery = searchData.searchQuery;
        let searchSorting = searchData.searchSorting;
        let searchType = searchData.searchType;


        const { searchTypes = [] } = data || {};
        const searchTypesElems = [];
        const TopsearchElem = [];
        const handleOptionChange = this.handleOptionChange;
        const { topSearchReducer = {} } = this.props;
        const topSearchData = topSearchReducer.data || '';

        const { metaReducer } = this.props;
		const pageMeta = metaReducer.data || {};
		const searchMeta = pageMeta.search || {};
		let mate_container = [];
		mate_container.push(
			<Helmet>
				<meta itemprop="name" content={searchMeta.title} />
				<meta itemprop="description" content={searchMeta.description} />
				<meta itemprop="image" content={searchMeta.img} />

				<meta name="twitter:card" content={searchMeta.description} />
				<meta name="twitter:site" content={searchMeta.url} />

				<meta property="og:title" content={searchMeta.title} />
				<meta property="og:type" content={searchMeta.type} />
				<meta property="og:url" content={searchMeta.url} />
				<meta property="og:image" content={searchMeta.img} />
				<meta property="og:description" content={searchMeta.description} /> 
				<meta property="og:site_name" content={searchMeta.sitename} />	
				<meta name="keywords" content={searchMeta.keywords} />
			</Helmet>
		)

        Object.keys(searchTypes || {}).map(function (key) {
            let value = searchTypes[key];
            let txt = value.txt;
            let val = value.value;
            let id = "search-panel-filter-" + val;
            let defaultChecked = false;
            if (searchData.searchType) {
                if (searchData.searchType == val) {
                    defaultChecked = true;
                }
            } else if (key < 1) {
                defaultChecked = true;
            }


            if (defaultChecked) {
                searchTypesElems.push(<div key={key} className="search-panel__filter">
                    <input onChange={handleOptionChange} name="search-type" type="radio" key={id} id={id} defaultValue={val} defaultChecked /><label htmlFor={id}>{txt}</label>
                </div>);
            } else {
                searchTypesElems.push(<div key={key} className="search-panel__filter">
                    <input onChange={handleOptionChange} name="search-type" type="radio" key={id} id={id} defaultValue={val} /><label htmlFor={id}>{txt}</label>
                </div>);
            }
            let topSearchItems = value.top_searchs;
            //Top Search
            if (topSearchData == val && topSearchItems.length > 0) {
                let topSearchItemsElems = [];

                Object.keys(topSearchItems || {}).map(function (tsKey) {
                    let childVal = topSearchItems[tsKey];
                    let txt = childVal.txt;
                    let val = childVal.value;
                    let href = "#";
                    if (paramsString) {
                        if (paramsString.indexOf('search-query=') > -1) {
                            href = paramsString.replace('search-query=' + encodeURI(searchQuery), 'search-query=' + txt);
                        } else {
                            href = paramsString + '&search-query=' + txt;
                        }
                    } else {
                        href = '?search-query=' + txt;
                    }
                    topSearchItemsElems.push(<a key={tsKey} href={href} className="search-panel__topsearch-item">{txt}</a>);
                });
                TopsearchElem.push(<div key={key} className="search-panel__topsearch">
                    <h3 className="search-panel__topsearch-title">{data.topSearchTxt}:</h3>
                    {topSearchItemsElems}
                </div>);
            }
        });


        const { headerSearchReducer } = this.props;
        const dataAutoComplete = headerSearchReducer.data || {};
        const autoCompleteItems = dataAutoComplete.items || {};

        let autoCompleteItemsElems = [];
        if (autoCompleteItems.length) {
            Object.keys(autoCompleteItems || {}).map(function (key) {
                let value = autoCompleteItems[key];

                let itemTxt = value.txt;
                let itemHref = value.href;

                let autoCompleteSubItems = value.subitems;
                let autoCompleteSubItemsElems = [];

                Object.keys(autoCompleteSubItems || {}).map(function (cKey) {
                    let childVal = autoCompleteSubItems[cKey];
                    let txt = childVal.txt;
                    let href = childVal.href;
                    autoCompleteSubItemsElems.push(<a key={'cKey-' + cKey} href={'/search?search-type=' + href + '&search-query=' + itemTxt} className="search-panel__autocomplet-subitem">{txt}</a>);
                });
                autoCompleteItemsElems.push(<div key={key} className="search-panel__autocomplet-grp">
                    <a href={'/search?search-query=' + itemTxt} className="search-panel__autocomplet-item">{itemTxt}</a>
                    {autoCompleteSubItemsElems}
                </div>);
            });
        }


        const { getSearchResultReducer } = this.props;
        const searchResultData = getSearchResultReducer.data || {};
        const result_no = searchResultData.result_no;
        const organic_listing = searchResultData.organic_listing || '';

        let resultTxt = 'Results';
        if (parseInt(result_no) < 2) {
            resultTxt = 'Result';
        }

        let noResult = !searchQuery || result_no == 0 || !organic_listing;
        return (
            <form action="/search" className="search-panel" autoComplete="off">
			    <h1 className="access-elem">{searchMeta.h1}</h1>
                {mate_container}
                <div className="search-panel__panel">
                    <div className="content-wrapper">
                        <div className="search-panel__wrapper">
                            <h2 className="search-panel__title">{data.searchTxt}</h2>
                            <div className="search-panel__input-holder">
                                <input defaultValue={searchData.searchQuery} name="search-query" type="search" placeholder={data.searchHints} className="search-panel__input" onKeyDown={this.handleKeyPress} />
                                {autoCompleteItemsElems.length ?
                                    <div className="search-panel__autocomplete">
                                        {autoCompleteItemsElems}
                                    </div> :
                                    ''}
                                <button type="submit" className="search-panel__input-btn" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-panel__filters">
                    <div className="content-wrapper">
                        {searchTypesElems}
                    </div>
                </div>
                {searchQuery ?
                    <div className="search-panel__list-control">
                        <div className="content-wrapper">
                            <div className="search-panel__list-control-holder">
                                <div className="search-panel__keywords">
                                    <h2 className="search-panel__keywords-title">{txtSearchPanel.txt_keywords}:</h2>
                                    <div className="search-panel__keywords-holder">
                                        <span className="search-panel__keywords-words">{searchQuery}</span>
                                        <small className="search-panel__result-num">( <NumberFormat value={result_no} displayType={'text'} thousandSeparator={true} /> {resultTxt} )</small>
                                    </div>
                                </div>
                                {!noResult ?
                                    <div className="search-panel__sorting">
                                        <h2 className="search-panel__sorting-title">{txtSearchPanel.txt_sorting}:</h2>
                                        <div className="search-panel__sorting-items">
                                            <a href={(searchSorting) ? paramsString.replace(searchSorting, 'relevancy') : paramsString + '&search-sorting=relevancy'} className={(searchSorting == "relevancy" || !searchSorting) ? "search-panel__sorting-item is-active" : "search-panel__sorting-item"}>{txtSearchPanel.txt_sorting_relevancy}</a>
                                            <a href={(searchSorting) ? paramsString.replace(searchSorting, 'latest') : paramsString + '&search-sorting=latest'} className={(searchSorting == "latest") ? "search-panel__sorting-item is-active" : "search-panel__sorting-item"}>{txtSearchPanel.txt_sorting_latest}</a>
                                            <a href={(searchSorting) ? paramsString.replace(searchSorting, 'oldest') : paramsString + '&search-sorting=oldest'} className={(searchSorting == "oldest") ? "search-panel__sorting-item is-active" : "search-panel__sorting-item"}>{txtSearchPanel.txt_sorting_oldest}</a>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                 : ''}
            </form>
        )
    }
}
