import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestSearchResult() {
	return { type: types.REQ_SEARCH_RESULT }
};

function receiveSearchResult(json) {
	return {
		type: types.RECV_SEARCH_RESULT,
		data: json
	}
};

function receiveSearchResultError(json) {
	return {
		type: types.RECV_SEARCH_RESULT,
		data: json
	}
};

export function getSearchResult(url, data) {
	return function (dispatch) {
		dispatch(requestSearchResult());
		return axios({
			url: url,
			timeout: 20000,
			data:data,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				dispatch(receiveSearchResult(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSearchResultError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};