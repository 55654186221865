import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

import * as CMSAPIs from '../../constants/api_url.js';

export default class ThirdLevelBio extends Component {

	constructor(props){
		super(props);
	}

	render(){
		var itemThumb = this.props.member.thumbnail || '';
		if(itemThumb != '' && itemThumb.indexOf('//')<0){
			itemThumb = CMSAPIs.generateSrcPath(itemThumb);
		}
		const divStyle = {	
		    backgroundImage: 'url('+itemThumb+')'
		}
		var nameHTML = this.props.member.name;
		if(this.props.member.title){
			var titleHTML = this.props.member.title;
			titleHTML = '<i>'+titleHTML.replace(',', '</i>,<i>')+'</i>';
			nameHTML += ', '+titleHTML;
		}
		var posHTML = this.props.member.position;
		posHTML = posHTML.replace('\r\n', '<br>').replace('\n', '<br>');
		return (
			<div>
				{this.props.member.thumbnail === undefined || this.props.member.thumbnail === '' ? '' : <div className="img-holder" style={divStyle} data-abc={`${this.props.member.thumbnail}`} ></div>}
				<div className="bio">
						<p className="name" dangerouslySetInnerHTML={{ __html: nameHTML}} />
						<p className="position" dangerouslySetInnerHTML={{ __html: posHTML}} />
				</div>
			</div>
		);
	}
}