import React, { Component } from 'react';
import store from '../../store/';

import FeatureListing from './FeatureListing';
import OrganicListing from './OrganicListing';
import PaginationContainer from '../../containers/PaginationContainer';

import { fetchSearchAutocomplete, switchTopSearch, getSearchResult } from '../../actions/';

export default class ResultList extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { getSearchResultReducer } = this.props;
        const { dataReducer } = this.props;
        const { data = {} } = getSearchResultReducer || {};
        const StaticData = dataReducer.data || {};
        const { searchPanel = {} } = StaticData || {};

        const totalPage = data.total_page || 1;
        const result_no = data.result_no;

        const { no_result_suggestion = {} } = data || {};
        const { preferred_listing = {} } = data || {};
        const { organic_listing = {} } = data || {};

        const preferred_listing_top = preferred_listing.top || {};
        const preferred_listing_bottom = preferred_listing.bottom || {};

        const organicListingData = organic_listing.items || [];
        const topFeatureListData = preferred_listing_top.items || [];
        const bottomFeatureListData = preferred_listing_bottom.items || [];


        let noResultSuggestionItems = [];

        if (no_result_suggestion.length > 0) {
            Object.keys(no_result_suggestion || {}).map(function (tsKey) {
                let childVal = no_result_suggestion[tsKey];
                let txt = childVal.txt;
                let href = childVal.href;
                let sp = ',';
                if (tsKey >= no_result_suggestion.length-1) {
                    sp = '.';
                }
                noResultSuggestionItems.push(<a key={tsKey} href={href} className="search-nores__suggestion">{txt}{sp}</a>);
            });
        }


        let noResult = result_no == '0' || !organic_listing || !organicListingData.length;
        //Search Data
        const { ownProps = {} } = this.props;
        const { searchData = {} } = ownProps || {};
        return (
            <div className="search-listing">
                {noResult ?
                    <div className="search-nores">
                        <p className="search-nores__txt">
                            {searchPanel.txt_no_result}<br />
                            {searchPanel.txt_no_suggest}:{noResultSuggestionItems}
                        </p>
                    </div>
                    :
                    <div>
                        <FeatureListing listData={topFeatureListData} />
                        <OrganicListing listData={organicListingData} />
                        <FeatureListing listData={bottomFeatureListData} />
                        <PaginationContainer page={searchData.page} totalPage={totalPage} paramsString={searchData.paramsString} />
                    </div>
                }
            </div>
        )
    }
}
