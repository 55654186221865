import React, { Component } from 'react';
import store from '../../store/';
import { fetchEventData } from "../../actions/";
import ScrollAnimation from 'react-animate-on-scroll';

export default class PageEventList extends Component {
  constructor(props) {
		super(props);
		this.state = {};
		this.selectFunc = this.selectFunc.bind(this);
		this.searchFunc = this.searchFunc.bind(this);
	}

	selectFunc(cond,e){
		this.setState({
			title: this.props.catTitle,
			[cond]: e.target.value
		})
		let filterObj = this.state;
		filterObj["title"]=this.props.catTitle;
		filterObj[cond] = e.target.value;
		if( cond != "searchKey" ){
			switch (e.target.value) {
				case 'Lorem ipsum dolor sit':
					store.dispatch(fetchEventData('/data/page-tdc-events-dummy-1.json'));
					break;
				case 'Lorem ipolor sit':
					store.dispatch(fetchEventData('/data/page-tdc-events-dummy-2.json'));
					break;
				default:
					store.dispatch(fetchEventData('/data/page-tdc-events.json'));
			}
		}
	}

	searchFunc(){
		if("searchKey" in this.state){
			switch (this.state.searchKey) {
				case 'Lorem ipsum dolor sit':
					store.dispatch(fetchEventData('/data/page-tdc-events-dummy-1.json'));
					break;
				case 'Lorem ipolor sit':
					store.dispatch(fetchEventData('/data/page-tdc-events-dummy-2.json'));
					break;
				default:
					store.dispatch(fetchEventData('/data/page-tdc-events.json'));
			}
		}
	}

  render() {
		const pageState = this.state;
		const getSelectVal = this.selectFunc;
		const searchClick = this.searchFunc;
		const { eventReducer } = this.props;
		const getEventObj = eventReducer.data || {};

		const filterObj = getEventObj.filterBy || {};
		let event_filter=[];
		{Object.keys(filterObj || {}).map(function (cond, i) {
			event_filter.push(
				<div className={"events-filter-cat-container events-filter-cat-container--"+cond}>
					{pageState.hasOwnProperty(cond)?
						<div className={"filter-cat__title"}>{pageState[cond]}</div>
					:
						<div className={"filter-cat__title"}>{cond}</div>
					}
					<select className={"events-filter-condList events-filter-condList--"+cond} onChange={(e) => getSelectVal(cond, e)}>
						<option className={"filter-condList__cond"} val={"default"}>{cond}</option>
						{filterObj[cond].map((item, i) => <option className={"filter-condList__cond"} val={item}>{item}</option>)}
					</select>
				</div>
			)
		});}

		const eventHeaderList = getEventObj.eventListHeader || {};
		let event_list_header=[];
		{Object.keys(eventHeaderList || {}).map(function (i) {
			event_list_header.push(
				<div className={"events-list-header__elem"}>{eventHeaderList[i]}</div>
			);
		});} 

		const eventList = getEventObj.eventList || {};
		let event_list=[];
		{Object.keys(eventList || {}).map(function (eventKey) {
			let eventVal = eventList[eventKey];
			let applyVal = eventVal["apply-form"] || {};
			let regVal = eventVal["registration"] || {};
			let exhibtVal = eventVal["exhibitorList"] || {};
			let fairVal = eventVal["fairCat"] || {};
			let enquireVal = eventVal["enquire"] || {};

			event_list.push(
				<ScrollAnimation animateIn="fadeIn" animateOnce="true" className={"list-event-container"}>
					<div className={"list-event-left-container"}>
						<div className={"list-event__elem list-event__elem--img"}>
						{(eventVal["href"])
						?
							(<a target="_blank" href={eventVal["href"]}><img src={`${process.env.PUBLIC_URL}` + eventVal["img"]} alt={eventVal["title"]}/></a>)
						:
							(<img src={`${process.env.PUBLIC_URL}` + eventVal["img"]} alt={eventVal["title"]}/>)
						}
						</div>
					</div>
					<div className={"list-event-right-container"}>
						<div className={"list-event-header-container"}>
							<div className={"list-event__elem list-event__elem--date"}>{eventVal["date"]}</div>
							{(eventVal["href"])
							?
								<div className={"list-event__elem list-event__elem--title"}><a target="_blank" href={eventVal["href"]}>{eventVal["title"]}</a></div>
							:
								<div className={"list-event__elem list-event__elem--title"}>{eventVal["title"]}</div>
							}
						</div>
						<div className={"list-event-detail-container"}>
							<a href={applyVal["href"]} className={"list-event__elem list-event__elem--"+applyVal["txt"]} target="_blank"><img src={`${process.env.PUBLIC_URL}` + applyVal["icon"]} alt={"Icon"}/><div className={"elem__desc"}>{applyVal["txt"]}</div></a>
							<a href={regVal["href"]} className={"list-event__elem list-event__elem--regist"} target="_blank"><img src={`${process.env.PUBLIC_URL}` + regVal["icon"]} alt={"Icon"}/><div className={"elem__desc"}>{regVal["txt"]}</div></a>
							{eventVal["exhibitorList"]?
								<a href={exhibtVal["href"]} className={"list-event__elem list-event__elem--exhibit"} target="_blank"><img src={`${process.env.PUBLIC_URL}` + exhibtVal["icon"]} alt={"Icon"}/><div className={"list-event__mobile-elem elem__desc"}>{exhibtVal["txt"]}</div></a>
								:	<div className={"list-event__elem list-event__elem--exhibit list-event__empty-elem"}></div>
							}
							<a href={fairVal["href"]} className={"list-event__elem list-event__elem--fairCat"} target="_blank"><img src={`${process.env.PUBLIC_URL}` + fairVal["icon"]} alt={"Icon"}/><div className={"list-event__mobile-elem elem__desc"}>{fairVal["txt"]}</div><div className={"list-event__desktop-elem"}>{fairVal["date"]}</div></a>
							<a href={enquireVal["href"]} className={"list-event__elem list-event__elem--enquire"} target="_blank"><img src={`${process.env.PUBLIC_URL}` + enquireVal["icon"]} alt={"Icon"}/><div className={"elem__desc"}>{enquireVal["txt"]}</div></a>
						</div>
					</div>
				</ScrollAnimation>
			);
		});} 

    return (
      <div className={"page-events-container"}>
				<div className={"events-filter"}>
					<div className="events-filter__title">Filter By</div>
					{event_filter}
					<div className="events-search__input-holder">
						<input name="search-query" type="search" placeholder={getEventObj.searchHints} className="events-serach__input" onChange={(e) => getSelectVal("searchKey", e)}/>
						<button type="submit" className="events-search__input-btn" onClick={() => {searchClick()} }/>
					</div>
				</div>
				<div className={"events-list-conatiner"}>
					<div className={"events-list-header"}>
						<div className={"events-list-header__elem"}></div>
						{event_list_header}
					</div>
					{event_list}
				</div>
			</div>
		);
  }
}