import { connect } from 'react-redux';
import React, { Component}  from 'react';
import NotFound from '../components/NotFound/NotFound';

const mapStateToProps = (state, ownProps) => ({
  careerReducer: state.careerReducer,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(NotFound);


