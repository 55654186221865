import React, { Component } from 'react';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from '../AlertTemplate';
import { withAlert, Alert } from 'react-alert'

// optional cofiguration
const options = {
    position: 'bottom right',
    timeout: 0,
    offset: '30px',
    transition: 'scale',
    zIndex: 300
}

export default class FeedbackPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: true,
            clearTimer: false
        };
        this.closeAlertMsg = this.closeAlertMsg.bind(this);
        this.openAlertMsg = this.openAlertMsg.bind(this);
    }
    closeAlertMsg() {
        this.setState({
            showAlert: false,
            clearTimer: true
        });
    }
    openAlertMsg() {
        this.setState({
            showAlert: true,
            clearTimer: true
        });
    }
    componentDidMount() {
        //this.props.alert.show('Oh look, an alert!');
        setTimeout(() => {
            if (!this.state.clearTimer) {
                this.closeAlertMsg();
            }
        }, 5000);
    }
    render() {
        const { ownProps } = this.props;
        let alertClass = "feedback-panel__alert";
        if (!this.state.showAlert) {
            alertClass = "feedback-panel__alert is-hidden";
        }
        return (
            <div className="feedback-panel">
                <div className={alertClass}>
                    <AlertTemplate openAlertMsg={this.openAlertMsg} closeAlertMsg={this.closeAlertMsg} />
                </div>
                <button onClick={this.openAlertMsg} className="feedback-panel__btn" >
                    <span>We Have Revamped Our Homepage</span>
                </button>
            </div>
        )
    }
}
