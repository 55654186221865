import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestIntroData() {
	return { type: types.REQ_INTRO_DATA }
};

function receiveIntroData(json, lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_INTRO_DATA,
		data: data
	}
};

function receiveIntroError(json) {
	return {
		type: types.RECV_INTRO_ERROR,
		data: json
	}
};

export function fetchIntroData(url, lang) {
	return function (dispatch) {
		dispatch(requestIntroData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
		.then(function (response) {
			dispatch(receiveIntroData(response.data, lang));
		})
		.catch(function (response) {
			dispatch(receiveIntroError(response.data));
		})
	}
};