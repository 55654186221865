import React, { Component } from 'react';
import store from '../../store/';
import { fetchItemsData } from "../../actions/";

export default class PageItemSlider extends Component {
  constructor(props) {
		super(props);
  }
  
  componentDidUpdate() {
		for (var element of document.querySelectorAll(".item-has-slider")) {
			initSwiper(element);
		}

		function initSwiper(element) {
			let thisElem = element;
			if (thisElem && thisElem.swiper) {
				thisElem.swiper.destroy(false, false);
			}
			let pageSlider = element;
			let autoplayTime = 5000;
			let swiper = new window.Swiper(pageSlider, {
				spaceBetween: 20,
				slidesPerView: 1,
				slidesPerGroup: 1,
				loop: true,
				effect: "fade",
				paginationClickable: true,
				autoplayDisableOnInteraction: false,
				simulateTouch: true,
				autoplay: {
					delay: autoplayTime,
				},
				navigation: {
					prevEl: ".item-slider__btn--prev",
					nextEl: ".item-slider__btn--next",
				},
				pagination: {
					el: ".item-slider__pagination",
					type: "bullets",
				},
				fadeEffect: {
					crossFade: true
				}
			});
			swiper.on("init", function () {
				swiper.update();
			});
		}
	}

  render() {
    const { itemsReducer } = this.props;
    let sliderObj =this.props.sliderObj;
    let itemSlidersArr = sliderObj.sliders || {};
    
    let item_slider = [];
      {Object.keys(itemSlidersArr || {}).map(function (sliderKey) {
        let sliderVal = itemSlidersArr[sliderKey];
        let sliderImg = sliderVal.img;
        let sliderContentColor = sliderVal.sliderContentColor || "origin";
        let sliderTitle = sliderVal.title;
        let sliderDesc = sliderVal.desc;
        let sliderLink= sliderVal.link;
        let sliderLinkTxt= sliderVal.linkTxt;
        item_slider.push(
          <div className="item-slider-container swiper-slide">
            <div className="item-slider__img">{ sliderImg ? <img src={`${process.env.PUBLIC_URL}` + sliderImg} alt={sliderTitle} /> :''}</div>
            <div className="item-slider-text">
              <div className={"item-slider__title item-slider__title--"+sliderContentColor}>{sliderTitle}</div>
              <div className={"item-slider__desc item-slider__desc--"+sliderContentColor}>{sliderDesc}</div>
              <div className="item-slider__link"><a href={sliderLink} className={"item-slider__href item-slider__href--"+sliderContentColor} target="_blank">{sliderLinkTxt}</a></div>
            </div>
          </div>
        );
      });}

    return (
      <div className="item-sliders">
        <div id="item-inner-slider" className="item-sliders-container item-has-slider">
          <div className="swiper-wrapper">
            {item_slider}
          </div>
          <a href="javascript:;" className="item-slider__btn item-slider__btn--prev"></a>
            <a href="javascript:;" className="item-slider__btn item-slider__btn--next"></a>
          <div className="item-slider__pagination"></div>
        </div>
      </div>
    );
  }
}