import React, { Component } from 'react';
import store from '../../store/';
import { fetchItemsData } from '../../actions/';

export default class MoreEventsCat extends Component {
  constructor(props) {
    super(props);
  }

  render() {   
    const { itemsReducer } = this.props;
		const { events = {} } = itemsReducer.data || {};
    let eventsCats = events.eventsCats || {};

		let curCat =this.props.curCat;
		let eventsCat = eventsCats[curCat];
		let more_events_item = [];
		{Object.keys(eventsCat || {}).map(function (events) {
			let itemVal = eventsCat[events] || {};
			let itemBgColor = itemVal.backgroundColor || {};
			let titleColor = itemVal.titleColor || "origin";
			let contentColor = itemVal.contentColor || "origin";
			let itemBgImg = "url(" + itemVal.backgroundImg + ")";
			let itemBgImgX = itemVal.imgFocusX || "origin";
			let itemBgImgY = itemVal.imgFocusY || "origin";
			let itemTitle = itemVal.title;
			let itemDesc = itemVal.desc;

			let itemHrefVal = itemVal.href;
			let itemHrefArrowColor = itemVal.hrefArrowColor || "origin";
			let itemHrefTxt = itemVal.hrefTxt;

			more_events_item.push(
				<div 
				className={
					(itemVal["backgroundImg"]) ?
					 "item-container item-container--bg item-container--bg--x-"+itemBgImgX+" item-container--bg--y-"+itemBgImgY
					: ((itemVal["backgroundColor"]) ? "item-container item-container--bg--color" : "item-container")
				} style={
					(itemVal["backgroundImg"]) ?
					 ({ "backgroundImage": itemBgImg })
					: ({ "backgroundColor": itemBgColor})
				}>
					<div className={"item-content"}>
						<div className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
						<p className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc}</p>
						{itemHrefVal ?
							<div className="item-arrow">
								<a className={"item-arrow__link item-arrow__link--"+itemHrefArrowColor} href={itemHrefVal} target="_blank"></a>
							</div>
						: null
						}
					</div>
				</div>
			);
		});}

    return (
      <div className={"items-row items-row--cols"}><div className="row-container">{more_events_item}</div></div>
    );
  }
}