
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import FooterBar from '../components/Footer/FooterBar';

const mapStateToProps = (state) => ({
  dataReducer: state.dataReducer,
  i18n: state.i18n,
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(FooterBar);