import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

import * as CMSAPIs from '../../constants/api_url.js';

export default class SecondLevelBio extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		//console.log(this.props.background);
		var itemThumb = this.props.member.thumbnail || '';
		if(itemThumb != '' && itemThumb.indexOf('//')<0){
			itemThumb = CMSAPIs.generateSrcPath(itemThumb);
		}
		const divStyle = {	
		    backgroundImage: 'url('+itemThumb+')'
		}
		var itemProfile = this.props.member.profile;
		if(itemProfile != '' && itemProfile.indexOf('//')<0){
			itemProfile = CMSAPIs.generateSrcPath(itemProfile);
		}
		return (
			<div>
				{(this.props.member.thumbnail != "") ?
					<div className="img-holder" style={divStyle} ></div>
					: ""
				}
				<div className="bio">
					<p className="name" dangerouslySetInnerHTML={{ __html: this.props.member.name }} />
					<p className="position" dangerouslySetInnerHTML={{ __html: this.props.member.position }} />
					<p className="desc" dangerouslySetInnerHTML={{ __html: this.props.member.desc }} ref={(descElement) => this.descElement = descElement} />
					<button className={"ProfileBtn"} onClick={() => window.open(itemProfile, '_blank')}><span className="download-icon" />{this.props.meta}</button>
				</div>
			</div>
		);
	}
}