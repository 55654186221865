
import React, { Component } from "react";
import store from "../../store/";
import { fetchHomeData, fetchContactUsData, fetchOurWorkData } from "../../actions/";
import Popup from "reactjs-popup";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import GMap from '../ContactUsItems/GMap';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

import CorporateInformationItem from './CorporateInformationItem';
import Mission from './Mission';
import ServicePledge from './ServicePledge';
import OurWork from './OurWork';

const crossImage = require('../../assets/images/common/icons/svg/black/icon-close.svg');

export default class AboutHome extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, openCareer: false, isOverlayViewOpen: false, currentOfficeOnOverlayView: null, selectedLat: 22.2809863, selectedLng: 114.173800199999 };
    this.swicthLanJson();
  }

  componentWillMount() {
    this.swicthLanJson();
  }

  openOverlayView(index, offices) {
    var office = offices[index];
    this.setState({ isOverlayViewOpen: true, currentOfficeOnOverlayView: office, selectedLat: office.Latitude, selectedLng: office.Longitude });
  }

  closeOverlayView() {
    this.setState({ isOverlayViewOpen: false, currentOfficeOnOverlayView: null, highlightedPinIndex: -1 });
  }

  swicthLanJson() {
    const { i18n } = this.props;
    let currentLang = (i18n || {}).locale;
    if (currentLang == null) { currentLang = "en" }


    store.dispatch(fetchHomeData(CMSAPIs.apiHome, currentLang));
    store.dispatch(fetchContactUsData(CMSAPIs.apiContactUs, currentLang));
    store.dispatch(fetchOurWorkData(CMSAPIs.apiOurWork, currentLang));
    //store.dispatch(fetchHomeData("/data/about-home-"+currentLang+".json"));
    //store.dispatch(fetchContactUsData("/data/contact-us-" + currentLang + ".json"));


  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openCareer = () => {
    this.setState({ openCareer: true });
  };

  closeCareer = () => {
    this.setState({ openCareer: false });
  };

  render() {

    const { homeReducer, contactUsReducer, ourWorkReducer } = this.props;
    const staticData = homeReducer.data || {};
    const banner = staticData.banner || {};
    const mission = staticData.mission || {};
    const servicePledge = staticData.servicePledge || {};
    /*const pages = staticData.pages || [];*/
    const ourWorkData = ourWorkReducer.data || {};
    const ourWork = ourWorkData.ourWork || [];
    // const map = staticData.map || [];


    const map = contactUsReducer.data || {};
    const whatWeDo = staticData.whatWeDo || {};
    const corporatePublications = staticData.corporatePublications || {};
    const meanBusiness = staticData.meanBusiness || {};
    const career = staticData.career || {};
    const redirections = staticData.redirection || {};
    const metaData = staticData.metaData || {};

    var pins = (contactUsReducer.data || {}).offices;
    if (!Array.isArray(pins)) {
      var pinsArray = [];
      pinsArray.push(pins);
      pins = pinsArray;
    }

    var popupStyles = {
      background: 'rgb(0, 0, 0)'
    };

    let whatWeDoStatus = whatWeDo.status;
    let what_we_do = [];
    what_we_do.push(
      <div className="home-what-we-do">
        <div className="left-panel">
          <div onClick={this.openModal}>
            <a target="_blank">
              {whatWeDo.thumb ? <img src={`${process.env.PUBLIC_URL}` + whatWeDo.thumb || ""} /> : ''}
              <span className="icon-play-btn" />
            </a>
            <Popup
              open={this.state.open}
              closeOnDocumentClick
              onClose={this.closeModal}
              contentStyle={popupStyles}
            >
              <div className="modal">
                <a className="close" onClick={this.closeModal}>
                  <span className="popup-close-btn" />
                </a>
                {whatWeDo.videoLink ? <iframe src={`${process.env.PUBLIC_URL}` + whatWeDo.videoLink || ""} /> : ''}
              </div>
            </Popup>
          </div>
        </div>
        <div className="right-panel">
          <h4>{whatWeDo.title || ""}</h4>
          <p className="subTitle">{whatWeDo.subTitle || ""}</p>
          <p>{(whatWeDo.content || "")
            .split("\n")
            .map((text, i) => {
              return (
                <span key={i}>{text} <br /></span>
              );
            })}</p>
          <a href={whatWeDo.url || ""}><span className="home-arrow" /></a>
        </div>
      </div>
    )

    return (
      <div className="page-abouthome-container">
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />

          <meta itemprop="name" content={metaData.title} />
          <meta itemprop="description" content={metaData.description} />

          <meta name="twitter:card" content={metaData.description} />
          <meta name="twitter:site" content={metaData.url} />

          <meta property="og:title" content={metaData.title} />
          <meta property="og:url" content={metaData.url} />
          <meta property="og:description" content={metaData.description} />
          <meta name="keywords" content={metaData.keywords} />
        </Helmet>
        <section>
          {banner.image ? <img className="about-home-banner" src={CMSAPIs.generateSrcPath(banner.image) || ""} /> : ''}
        </section>
        <section className="home-mission-container">
          {
            <Mission
              items={mission}
              redirections={redirections}
            />
          }
        </section>
        <section>
          {
            <div className="home-ourwork-container" >
              <OurWork
                items={ourWork}
                redirections={redirections}
              />
            </div>
          }
        </section>
        <section>
          <div className="home-network-container">
            <div className="home-network left-panel">
              <div className="left-content">
                <h4>{map.globalNetworkTitle}</h4>
                {(() => {
                  let globalNetworkContentsHTML = [];
                  let globalNetworkContentsArr = [];
                  if (Array.isArray(map.globalNetworkContents)) {
                    globalNetworkContentsArr = map.globalNetworkContents;
                  } else {
                    globalNetworkContentsArr.push(map.globalNetworkContents);
                  }
                  map.globalNetworkContents = globalNetworkContentsArr;
                  (map.globalNetworkContents || []).map((content, index) => {
                    if (content) {
                      globalNetworkContentsHTML.push(
                        <span key={index} className="desc" dangerouslySetInnerHTML={{ __html: content.detail }} />
                      );
                    }
                  });
                  return globalNetworkContentsHTML;
                })()}
                {
                  (map.committes) ?
                    <p>{map.committesHeader}</p>
                    :
                    ''
                }
                <div className="committees-content">
                  {(() => {
                    if (map.committes) {
                      let committesArr = [];
                      if (Array.isArray(map.committes)) {
                        committesArr = map.committes;
                      } else {
                        committesArr.push(map.committes);
                      }
                      map.committes = committesArr;
                      return map.committes.map(function (item, i) {
                        return (item.url.trim() === "" ? <div key={i} className="committees-item"><span className="committees-item-nourl">{item.name}</span></div> : <div key={i} className="committees-item"><a target="_blank" className="committeLink" href={item.url}><span>{item.name}</span></a></div>);
                      });
                    }
                  })()}
                  <p>{map.committesFooter || ''}</p>

                  {(() => {
                    if (map.globalNetworkSubTitleLine3) {
                      if (map.globalNetworkSubTitleLine3Url.trim() === "") {
                        return <p><span dangerouslySetInnerHTML={{ __html: map.globalNetworkSubTitleLine3 }} /></p>;
                      } else {
                        return <a target="_blank" href={map.globalNetworkSubTitleLine3Url} ><span dangerouslySetInnerHTML={{ __html: map.globalNetworkSubTitleLine3 }} /><span className="icon-right-arrow-angle" /></a>;
                      }
                    } else {
                      return "";
                    }
                  })()}
                </div>

                {
                  (map.globalNetworkDiscoverBtn) ?
                    <a className="discover-btn" href={(`${process.env.PUBLIC_URL}` + map.globalNetworkDiscoverUrl) || ""}><span>{map.globalNetworkDiscoverBtn || ""}</span></a>
                    :
                    ''
                }
              </div>
            </div>
            <div className="home-network right-panel">
              {(this.state.selectedLat) ?
                <GMap
                  offices={pins}
                  openOverlayView={(index) => this.openOverlayView(index, pins)}
                  selectedCategory={null}
                  selectedLat={this.state.selectedLat}
                  selectedLng={this.state.selectedLng}
                  highlightedPinIndex={null}
                  setHighlightedPinIndex={(index) => { }}
                />
                : ""
              }
              {(this.state.isOverlayViewOpen) ?
                <div className="contactUsMapWrapper">
                  <div className="overlayView">
                    <p className="title">{this.state.currentOfficeOnOverlayView.title}</p>
                    {
                      (this.state.currentOfficeOnOverlayView.Thumbnail) ?
                        <img src={CMSAPIs.generateSrcPath(this.state.currentOfficeOnOverlayView.Thumbnail)} className="thumbnail" />
                        : ""
                    }

                    {
                      (this.state.currentOfficeOnOverlayView.ContactPerson) ?
                        <p className="directorName" dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.ContactPerson }} />
                        : ""
                    }

                    <p className="directorPosition">{this.state.currentOfficeOnOverlayView.ContactPersonTitle}</p>

                    <div className="contactWrapper">
                      {(() => {
                        let itemHTML = [];
                        let thisAddress = this.state.currentOfficeOnOverlayView.contact.address;
                        if (thisAddress) {
                          if (typeof thisAddress == "string") {
                            if (thisAddress.indexOf("\r\n") > 1) {
                              thisAddress = thisAddress.split("\r\n");
                            } else {
                              thisAddress = thisAddress.split("\n");
                            }
                          }
                          thisAddress.map(function (item, i) {
                            itemHTML.push(
                              <p className="contact" dangerouslySetInnerHTML={{ __html: item }} />
                            )
                          })
                        }
                        return itemHTML;
                      })()}

                      <p className="contact">{map.meta.tel || ""}: <span dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.contact.tel }} /> </p>
                      {
                        (this.state.currentOfficeOnOverlayView.contact.fax) ?
                          <p className="contact">{map.meta.fax || ""}: <span dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.contact.fax }} /> </p> :
                          ""
                      }
                      {(() => {
                        var emailText = this.state.currentOfficeOnOverlayView.contact.email;
                        if (emailText) {
                          return <p className="contact">{map.meta.email || ""}: <a href={'mailto:' + emailText}>{emailText}</a></p>
                        }
                      })()}
                      {(() => {
                        var websiteText = this.state.currentOfficeOnOverlayView.contact.website;
                        if (websiteText) {
                          return <p className="contact">{map.meta.website || ""}: <a href={websiteText}>{websiteText}</a></p>
                        }
                      })()}
                    </div>

                    <div className="socialMediaBar">
                      {
                        ((map.medias || []).map((media, index) => {
                          if (media.Office.toUpperCase() == this.state.currentOfficeOnOverlayView.BtnName.toUpperCase()) {
                            if (media.icon.indexOf('share-wc') > -1) {
                              //wechat
                              return (
                                <ButtonWechatContainer url={media.url} icon={media.icon} />
                              )
                            } else {
                              return (
                                <button><a href={media.url} target="_blank">{media.icon ? <img src={CMSAPIs.generateSrcPath(media.icon)} /> : ''}</a></button>
                              )
                            }
                          }
                          return ""
                        }))
                      }
                    </div>

                    <button onClick={() => this.closeOverlayView()} className={"crossBtn"} >{crossImage ? <img src={crossImage} /> : ''}</button>
                  </div></div> : ""}
            </div>
          </div>
        </section>
        {(whatWeDoStatus == "show") ? <section>{what_we_do}</section> : ""}
        <section>
          <div className="home-corporate-information">
            {
              <CorporateInformationItem
                items={corporatePublications}
                redirections={redirections}
              />
            }
          </div>
        </section>
        <section>
          <div className="home-business-container">
            <div className="home-mean-business">
              {meanBusiness.thumb ? <img className="business-logo" src={CMSAPIs.generateSrcPath(meanBusiness.thumb) || ""} /> : ''}
              <h4>{meanBusiness.title || ""}</h4>
              <p>{meanBusiness.content || ""}</p>
              <a href={meanBusiness.url || ""}><span className="home-arrow" /></a>
            </div>

            <div className="home-career">
              <h4>{career.title || ""}</h4>
              <p>{career.content || ""}</p>
              {
                (career.additionals || []).map((additional, index) => {
                  if (index == 0) {
                    return (
                      <div key={index}>
                        <div className="additional-item" onClick={this.openCareer}>
                          <a ><span className="item-text">{additional.title || ""}</span><span className="icon-right-arrow-angle" /></a>
                        </div>

                        <Popup
                          open={this.state.openCareer}
                          closeOnDocumentClick
                          onClose={this.closeCareer}
                          contentStyle={popupStyles}
                        >
                          <div className="careerPopup">
                            <a className="close" onClick={this.closeCareer}>
                              <span className="popup-close-btn" />
                            </a>
                            <h3>Post for open Application</h3>
                            <div className="bodyContent">
                              <p>
                                Please read the following notes before proceeding to fill in the application form or applying for a job.
                                <br /><br />
                                (a) The online application form is managed by a third party company and by connecting to the form, you are transferring to their website.
                                <br /><br />
                                (b) Whilst every care has been taken by the HKTDC in maintaining strict security standards and procedures to prevent unauthorised access to information about you, there is no warrant or guarantee the availability, effectiveness or performance of this website. To the extent permitted by law, you agree that no member of the HKTDC will be responsible in any way for direct, indirect, special or consequential damages, loss or unauthorised disclosure of information that you or any other person that may suffer or incur in connection with or arising out of the use, or misuse, of your login email name and password.
                                <br /><br />
                                (c) Your personal data may be used and processed by the HKTDC for application / employment purposes. The HKTDC and any third parties acting on its behalf will treat this information confidentially and will not use your personal data for any purpose other than that for which it was originally collected. Your personal data may be retained, unless precluded by applicable law, for up to one year in the candidate pool or six months after the selection of a particular position finalised. In any case, the HKTDC will retain and delete your personal data in accordance with applicable law.
                                <br /><br />
                                (d) Your application will not be considered if the mandatory information is not provided or it is not clear that you have the minimum qualifications, training, experience or other requirements as specified for the job. Please note that you need to submit application for each of your interested openings.
                                <br /><br />
                                (e) Once the online application is submitted, you should receive an acknowledgement of receipt by email. If you do not receive the acknowledgement, please re-submit your application. There is no need to make enquiry on the application status. Only shortlisted applicants will be contacted to go through the next phase of the recruitment process.
                                <br /><br />
                                (f) Your application will be referred to other suitable vacancies within the HKTDC and your personal data may then be made available to appropriate persons in the HKTDC.
                                <br /><br />
                                (g) If you lost internet connection during the process, please return to your application by logging in again. Should you experience difficulties in completing the application form, you can email to hr@recruit.hktdc.org.
                              </p>
                            </div>
                            <div>
                              <div className="button"><a href={additional.url || ""} target="_blank">{career.accept}</a></div>
                              <div className="button" onClick={this.closeCareer} >{career.decline}</div>
                            </div>
                          </div>
                        </Popup>

                      </div>
                    )
                  }
                  return (
                    <div key={index} className="additional-item">
                      <a href={additional.url || ""} ><span className="item-text">{additional.title || ""}</span><span className="icon-right-arrow-angle" /></a>
                    </div>
                  );
                })
              }
              <a href={(`${process.env.PUBLIC_URL}` + career.url) || ""}><span className="home-arrow" /></a>
            </div>
          </div>
        </section>
      </div>
    );
  }

}