import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class AlertBar extends Component {
    constructor(props) {
        super(props);
        this.closeAlertBar = this.closeAlertBar.bind(this);
        this.state = { 'showAlertBar': true };
    }
    closeAlertBar() {
        this.setState({
			showAlertBar: false
		});
    }
    render() {
        const { dataReducer } = this.props;
        const { data = {} } = dataReducer || {};
        const { alertBar = {} } = data || {};
        const closeAlertBar = this.closeAlertBar;
        const needShow = (alertBar.show == "true");
        let barClass = "alert-bar";
        let barClassForBody = "show";
        if(!this.state.showAlertBar || !needShow){
            barClass = "alert-bar is-hidden";
            barClassForBody = "not-show";
        } 
        return (
            <div className={barClass}>
                {(needShow ) ?
                    <div className="content-wrapper">
                        <div className="alert-bar__holder">
                            <div className="alert-bar__title">{alertBar.title}</div>
                            <a href={alertBar.href}  className="alert-bar__desc">
                                {alertBar.desc}
                            </a>
                            <div className="alert-bar__control">
                                <button onClick={closeAlertBar} className="alert-bar__close">
                                </button>
                            </div>
                        </div>
                    </div>
                    : ""}
                <Helmet bodyAttributes={{ 'data-alert-bar': barClassForBody }} />
            </div>
        )
    }
}
