
import React, { Component } from "react";
import AnimateHeight from 'react-animate-height';
export default class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpened: false };
  }

  render() {
    var categories = [];
    var defaultViewData = this.props.defaultViewData || {};
    //console.log(this.props.offices);
    return (
      <div className="dropDownBox" onClick={() => this.setState({isOpened: !this.state.isOpened}) }>
        <AnimateHeight className="dropDwonBoxWrapper" duration={ 500 } height={ this.state.isOpened ? 'auto' : 50 } >
        <li className={(this.props.selectedCategory == null) ? "clickable active" : "clickable"} 
            onClick={() => this.props.updateSelectedCategory(null)}>{this.props.defaultTitle}</li>
          { ( this.props.offices ? this.props.offices.map((item, i) => {
            if( !categories.includes(item.categories) )
            {
              categories.push(item.categories);
              var viewLabel = item.categories;
              if( item.categories != "Head Office" ) {
                var count = 0;
                let targetView = defaultViewData[item.categories];
                viewLabel = targetView.label;
                for( var j=0; j<this.props.offices.length; j++ ) {
                  if( this.props.offices[j].categories == item.categories ) {
                    count++;
                  }
                }
                return <li key={i} onClick={() => this.props.updateSelectedCategory(item)} className={(this.props.selectedCategory == item.categories) ? "clickable active" : "clickable"}>{viewLabel + " (" + count + ")"}</li>;
              }
              else {
                return <li key={i} onClick={() => this.props.updateSelectedCategory(item)} className={(this.props.selectedCategory == item.categories) ? "clickable active" : "clickable"}>{viewLabel}</li>;
              }
              
            } 
            }) : ""
          ) }
          </AnimateHeight>
      </div>
    );
  }

}