import React, { Component } from 'react';

import PageIntroContainer from '../../containers/PageIntroContainer';
import EventsItemsContainer from '../../containers/EventsItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

export default class Events extends Component {
  render() {
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'events' }} />    
        <PageIntroContainer curPage={"events"}/>
        <EventsItemsContainer />
        <PageAdsContainer />
        <WebTrends />
      </main>
    );
  }
}
