import React, { Component } from 'react';
import store from '../../store/';

export default class PageMultiSlider extends Component {
  constructor(props) {
		super(props);
  }

  componentDidUpdate() {
		for (var element of document.querySelectorAll(".multi-sliders-container")) {
			initSwiper(element);
		}

		function initSwiper(element) {
			let thisElem = element;
			if (thisElem && thisElem.swiper) {
				thisElem.swiper.destroy(false, false);
			}
			let pageSlider = element;
			let autoplayTime = 5000;
			let swiper = new window.Swiper(pageSlider, {
				slidesPerView: 3,
				slidesPerGroup: 3,
				spaceBetween: 40,
				observer: true,
				observeParents: true,
				loop: true,
				simulateTouch: true,
				// loopFillGroupWithBlank: true,
				watchSlidesProgress:true,
				autoplay: {
					delay: autoplayTime,
				},
				navigation: {
					prevEl: ".item-slider__btn--prev",
					nextEl: ".item-slider__btn--next",
				},
				pagination: {
					el: ".item-slider__pagination",
					type: "bullets"
				},
				fadeEffect: {
					crossFade: true
				},
				breakpoints: {
					1240: {
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 40,
					},
					768: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						spaceBetween: 40,
					}
				}
			});
			swiper.on("init", function () {
				swiper.update();
			});
		}
	}

  render() {
		let multiSlidersArr =this.props.multiSliderObj;

		let item_slider = [];

		{Object.keys(multiSlidersArr || {}).map(function (multiSlidersKey) {
			let sliderVal = multiSlidersArr[multiSlidersKey];
			let sliderImg = sliderVal.img;
			let sliderContentColor = sliderVal.sliderContentColor || "origin";
			let sliderTitle = sliderVal.title;
			let sliderDesc = sliderVal.desc;
			let sliderLoc = sliderVal.location;
			let sliderDate = sliderVal.date;
			let silderLink = sliderVal.link;
			item_slider.push(
				<div className="multi-slider-container swiper-slide">
					<div className="slider__img"><a href={silderLink} target="_blank">{ sliderImg ? <img src={`${process.env.PUBLIC_URL}` + sliderImg} alt={sliderTitle} /> :''}</a></div>
					<div className={"slider__title slider__title--"+sliderContentColor}><a href={silderLink} target="_blank">{sliderTitle}</a></div>
					<div className={"slider__desc slider__desc--"+sliderContentColor}>{sliderDesc}</div>
					<div className={"slider-location-date"}>
						<div className={"slider__location"}>{sliderLoc}</div>
						<div className={"slider__date"}>{sliderDate}</div>
					</div>
				</div>
			);
		});}

    return (
      <div className="item-sliders">
        <div id="item-inner-slider" className="multi-sliders-container item-has-multi-slider">
          <div className="swiper-wrapper">
            {item_slider}
          </div>
          <a href="javascript:;" className="item-slider__btn item-slider__btn--prev"></a>
					<a href="javascript:;" className="item-slider__btn item-slider__btn--next"></a>
          <div className="item-slider__pagination"></div>
        </div>
      </div>
    );
  }
}