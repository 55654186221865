import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class ThumbItem extends Component {
	constructor(props) {
		super(props);
	}

	renderRemark(remark) {
		return (
			<p className="thumb-item-remark">{remark}</p>
		);
	}
	renderImageView() {
		var itemThumb = this.props.item.thumb;
		if(itemThumb != '' && itemThumb.indexOf('//')<0){
			itemThumb = `${process.env.PUBLIC_URL}` + itemThumb;
		}
		if(this.props.type == "link"){
			return (
				<a href={this.props.item.url} target="_blank">
					<div>
						{ itemThumb ? <img className="item-image" src={itemThumb || ""} /> :''}
					</div>
					<p>{this.props.item.content || ""}</p>
					{
						(this.props.hasRemark) ? this.renderRemark(this.props.item.remark || "") : ""
					}
				</a>
			);
		} else {
			return (
				<div onClick={(e) => this.props.onClick()}>
					<a target="_blank">
						<div>
							{ itemThumb  ? <img className="item-image" src={itemThumb || ""} /> :''}
							<span className="icon-play-btn" />
						</div>
						<p>{this.props.item.content || ""}</p>
						{
							(this.props.hasRemark) ? this.renderRemark(this.props.item.remark || "") : ""
						}
					</a>
				</div>
			);
		}
	}

	renderVideoView() {
		return (
			<div>
				<iframe src={`${process.env.PUBLIC_URL}` + this.props.item.url || ""} />
				<p>{this.props.item.content || ""}</p>
				{
					(this.props.hasRemark) ? this.renderRemark(this.props.item.remark || "") : ""
				}
			</div>
		)
	}

	render() {
		return (
			<div className={this.props.isFullWidth ? "thumb-item-container is-full-width" : "thumb-item-container"}>
				{
					(this.props.item.type == "video") ? this.renderVideoView() : this.renderImageView()
				}
			</div>
		);
	}
}