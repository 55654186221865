import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestMobileAppData() {
	return { type: types.REQ_MOBILE_APP_RESULT }
};

function receiveMobileAppData(json) {
	return {
		type: types.RECV_MOBILE_APP_RESULT,
		data: json
	}
};

function receiveMobileAppDataError(json) {
	return {
		type: types.RECV_MOBILE_APP_ERROR,
		data: json
	}
};

export function fetchMobileAppData(url) {
	return function (dispatch) {
		dispatch(requestMobileAppData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				dispatch(receiveMobileAppData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveMobileAppDataError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};