import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import AnimateHeight from 'react-animate-height';

import * as CMSAPIs from '../../constants/api_url.js';

const upArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/upArrow.png";
const downArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/downArrow.png";


export default class CorporateStructure extends Component {
	constructor(props) {
		super(props);
	}

	toggleView()
	{
		this.props.toggleCorpStruct(this.props.index);
	}

	render() {
		return (
			<div className="corporateStructure">
				<div className="wrapper">
				<AnimateHeight duration={ 500 } height={ this.props.isOpened ? 'auto' : 99 } >
					<div className="titleWrapper" onClick={() => this.toggleView()}>
						<p><a href="javascript:void(0);">{this.props.section.title}<span className={`${this.props.isOpened && 'opened'} icon-right-arrow-angle`}></span></a></p>
						{/* <img className="arrowBtn"src={`${process.env.PUBLIC_URL}` +  this.props.isOpened ? upArrow : downArrow } /> */}
					</div>
					<div className="imageWrapper">
						{ this.props.section.image_path ? <img src={ CMSAPIs.generateSrcPath(this.props.section.image_path) } /> :''}
					</div>
				</AnimateHeight>
				</div>
			</div>
		);
	}
}