
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import SearchPanel from '../components/SearchPanel/';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  headerSearchReducer: state.headerSearchReducer,
  topSearchReducer: state.topSearchReducer,
	metaReducer: state.metaReducer,
  i18n: state.i18n,
  ownProps: ownProps,
  getSearchResultReducer: state.getSearchResultReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(SearchPanel);