import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import QRCode from 'qrcode.react';

import * as CMSAPIs from '../../constants/api_url.js';


export default class ButtonWechat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }


  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    //console.log('onCloseModal');
    this.setState({ open: false });
  };

  render() {
    const { dataReducer } = this.props;
    const { data = {} } = dataReducer || {};
    const { share = {} } = data || {};
    const url = this.props.ownProps.url;
    const txt = this.props.ownProps.txt;
    const icon = this.props.ownProps.icon;
    const btnClass = this.props.ownProps.btnClass || '';
    //const { open } = this.state;
    return (
      <span>
          <button className={btnClass} onClick={this.onOpenModal}>
            {txt}
            {icon?<img src={CMSAPIs.generateSrcPath(icon)} />:''}
          </button>
          <Modal open={this.state.open} onClose={this.onCloseModal} center>
            <div className="button-share__wcqr">
              <QRCode value={url} />
              <p className="button-share__wcqr-txt">{share.wechatDesc}</p>
            </div>
          </Modal>
      </span>
    );
  }
}