import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestEventData() {
	return { type: types.REQ_EVENT_DATA }
};

function receiveEventData(json) {
	return {
		type: types.RECV_EVENT_DATA,
		data: json
	}
};

function receiveEventError(json) {
	return {
		type: types.RECV_EVENT_ERROR,
		data: json
	}
};

export function fetchEventData(url) {
	return function (dispatch) {
		dispatch(requestEventData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				dispatch(receiveEventData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveEventError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};

/*
export const RECV_EVENT_ERROR = 'RECV_EVENT_ERROR';
export const REQ_EVENT_DATA = 'REQ_EVENT_DATA';
export const RECV_EVENT_DATA = 'RECV_EVENT_DATA';
*/ 