import React, { Component } from 'react';

import TopbarContainer from '../../containers/TopbarContainer';
import HeaderLogo from './HeaderLogo';
import MainNavContainer from '../../containers/MainNavContainer';
import HeaderSearchContainer from '../../containers/HeaderSearchContainer';

import store from '../../store/';
import { setLocale } from 'react-redux-i18n';

export default class Header extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    function toggleMenu(id) {
      var navSubholder = document.querySelectorAll('.nav-link__subholder');
      [].forEach.call(navSubholder, function (el) {
        el.classList.remove('is-active');
      });

      var holderId = document.getElementById(id);
      holderId.className += ' is-active';

      document.body.classList.toggle('mob-menu-open');
    }


    const { match } = this.props;
    const { params } = match || '';
    const lang = params.locale || 'en';
	
	const isETSPage =  window.location.href.toLowerCase().indexOf('/executive-trainee-programme') >  0 ;
	

    return (
      <header className="header--sub-portal">
        <div className="header__top">
          <div className="header__wrapper">
            <div className="header__item">
              <TopbarContainer />
            </div>
          </div>
        </div>
        <div className="header__main">
          <div className="header__wrapper">
            <HeaderLogo locale={lang}/>
            <div className="header__item">
              <MainNavContainer />
            </div>
            <div className="header__right">
			
			{isETSPage?"":(<a href="javascript:;" onClick={toggleMenu.bind(null, 'mob-share')} className="header__mob-share"></a>)}
            {isETSPage?"":(<a href="javascript:;" onClick={toggleMenu.bind(null, 'mob-lang')} className="header__mob-lang"></a>)}
			  
			  
              <a href="javascript:;" onClick={toggleMenu.bind(null, 'mob-menu')} className="header__mob-menu"></a>
              <a href="javascript:;" onClick={toggleMenu.bind(null, 'mob-menu')} className="header__mob-close"></a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}