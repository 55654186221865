import React, { Component } from 'react';

import FooterBarContainer from '../../containers/FooterBarContainer';
import SitemapContainer from '../../containers/SitemapContainer';
import FooterBottomContainer from '../../containers/FooterBottomContainer';
import ScrollAnimation from 'react-animate-on-scroll';


export default class Header extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <footer>
        <FooterBarContainer />
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" className="footer__body">
          <SitemapContainer />
          <FooterBottomContainer />
        </ScrollAnimation>
      </footer>
    );
  }
}