
import React, { Component } from "react";
import store from "../../store/";
import { fetchBusinessData } from "../../actions/";
import Popup from "reactjs-popup";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import ExpandableItem from './ExpandableItem';
import ThumbItem from './ThumbItem';

export default class OurBusiness extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, openVideo: false, videoLink: "" };
    this.swicthLanJson(this.props);
  }

  swicthLanJson(obj){
    const{i18n} = this.props;
    let currentLang = i18n.locale;
    if(currentLang==null){currentLang="en"}
    //store.dispatch(fetchBusinessData("/data/our-business-"+currentLang+".json"));
    store.dispatch(fetchBusinessData(CMSAPIs.apiOurBusiness, currentLang));
    
  }

  componentDidUpdate() {
    for (var element of document.querySelectorAll("#business-slider")) {
      initSwiper(element);
    }

    function initSwiper(element) {
      let thisElem = element;
      if (thisElem && thisElem.swiper) {
        thisElem.swiper.destroy(false, false);
      }
      let pageSlider = element;
      let autoplayTime = 5000;
      let swiper = new window.Swiper(pageSlider, {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        loop: true,
        simulateTouch: true,
        autoResize: true,
        // loopFillGroupWithBlank: true,
        watchSlidesProgress:true,
        autoplay: {
          delay: autoplayTime,
        },
        navigation: {
          prevEl: ".item-slider__btn--prev",
          nextEl: ".item-slider__btn--next",
        },
        fadeEffect: {
          crossFade: true
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 40,
          }
        }
      });
      swiper.on("init", function () {
        swiper.update();
      });
    }
  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openVideoModal = () => {
    this.setState({ openVideo: true });
  };

  closeVideoModal = () => {
    this.setState({ openVideo: false });
  };

  renderPopup (link) {
      this.setState({ videoLink: link, open: true });
  }

  render() {

    const { businessReducer } = this.props;
    const staticData = businessReducer.data || {};
    const mainbanner = staticData.mainbanner || {};
    const sliders = staticData.sliders || [];
    const whatWeDo = staticData.whatWeDo || {};
    const meta = staticData.meta || {};
    const wePromote = staticData.wePromote || {};
    const items = staticData.items || [];
    const metaData = staticData.metaData || {};

    var popupStyles = {
      background: 'rgb(0, 0, 0)'
    };

    let mate_container = [];
    mate_container.push(
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />

        <meta itemprop="name" content={metaData.title} />
        <meta itemprop="description" content={metaData.description} />

        <meta name="twitter:card" content={metaData.description} />
        <meta name="twitter:site" content={metaData.url} />

        <meta property="og:title" content={metaData.title} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
      </Helmet>
    )

    return (
      <div className="showcasing-container">
				{mate_container}
        <section>
          <div className="company-banner-container">
            <div className="left-panel">
              <h4>{mainbanner.mainTitle || ""}</h4>
              <h5>{mainbanner.title || ""}</h5>
              <p>{mainbanner.content || ""}</p>
            </div>
            <div className="right-panel">
              { mainbanner.thumb ? <img src={CMSAPIs.generateSrcPath(mainbanner.thumb) || ""} /> :''}
            </div>
          </div>
        </section>
        <section>
          <div className="company-whatwedo-container">
            <div className="bottom-panel">
              <div className="left-panel">
                <div onClick={this.openVideoModal}>
                  <a target="_blank">
                    { whatWeDo.thumb ? <img src={CMSAPIs.generateSrcPath(whatWeDo.thumb) || ""} /> :''}
                    <span className="icon-play-btn" />
                  </a>
                  <Popup
                    open={this.state.openVideo}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    contentStyle={popupStyles}
                  >
                    <div className="modal">
                      <a className="close" onClick={this.closeVideoModal}>
                        <span className="popup-close-btn" /> 
                      </a>
                      <iframe src={CMSAPIs.generateSrcPath(whatWeDo.videoLink) || ""} controls ></iframe>
                    </div>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </section>

        
        <section>
          <div className="business-container">
            <div id="business-slider" className="business-slider-container">
              <div className="swiper-wrapper">
              {
                sliders.map((item, index) => {
                  return (
                    <div className="swiper-slide">
                      <ThumbItem
                        item={item}
                        hasRemark={true}
                        isFullWidth={true}
                        onClick={() => {this.renderPopup(item.url)}}
                      />
                    </div>
                  )
                })
              }
              </div>
            </div>  
            <Popup
                open={this.state.open}
                closeOnDocumentClick
                onClose={this.closeModal}
                contentStyle={popupStyles}
              >
                <div className="modal">
                  <a className="close" onClick={this.closeModal}>
                    <span className="popup-close-btn" /> 
                  </a>
                  <iframe src={CMSAPIs.generateSrcPath(this.state.videoLink)} />
                </div>
              </Popup>
            
              <div className="nav-container">
                <span className="item-slider__btn item-slider__btn--prev"></span>
                <a href="javascript:;" className="item-slider__btn item-slider__btn--next"></a>
              </div>
          </div>
        </section>
        <section>
          <div className="company-whatwedo-container">
            <h4>{wePromote.title || ""}</h4>
            <p>{wePromote.content || ""}</p>
          </div>
          <div className="company-items-container">
            {
              items.map((item, index) => {
                return (
                  <ExpandableItem
                    title={item.title || ""}
                    descriptions={item.descriptions || []}
                    contents={item.contents ||[]}
                  />
                );
              })
            }
            <p>{meta.extract || ""}</p>
          </div>
        </section>
        <section>

        </section>
      </div>
    );
  }

}