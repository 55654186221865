
import { connect } from "react-redux";
import React, { Component}  from "react";
import InsightsItems from "../components/InsightsItems/";

const mapStateToProps = state => ({
	dataReducer: state.dataReducer,
	itemsReducer: state.itemsReducer,
	metaReducer: state.metaReducer,
	i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
	pure: false
})(InsightsItems);