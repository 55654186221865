import React, { Component } from "react";
import store from "../../store/";
import { fetchItemsData } from "../../actions/";
import { fetchWebMetaData } from "../../actions/";

import PageItemSearchContainer from "../../containers/PageItemSearchContainer";
import PageItemChildItem from "../../containers/PageItemChildItem";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

export default class InsightsItems extends Component {
	constructor(props) {
		super(props);
		//this.swicthLanJson(this.props);
		store.dispatch(fetchWebMetaData("/data/web-meta.json"));
	}

	swicthLanJson(obj){
		const{i18n} = this.props;
		let currentLang = i18n.locale;
		if(currentLang==null){currentLang="en"}
		//store.dispatch(fetchItemsData("/data/page-items-"+currentLang+".json"));
	}

	render() {    
		const getCols = (str) => ({
			"1": "full",
			"2": "cols",
			default: "full"
		})[str];
	
		const getLinksCols = (str1, str2) =>{
			if(str1<2){
				if(str2>4){
					return "item--full__links--cols";
				}else{
					return "item--full__links--col";
				}
			}else{
				return "";
			}
		};

		const getSliderContainer = (bool) => {
			if(bool){
				return " item--full__slider--cols";
			}else{
				return "";
			}
		};

		const getChildContainer = (num) => {
			if(num > 0){
				if(num > 1){
					return " item--full__child--cols";
				}else{
					return " item--full__child--col";
				}
			}else{
				return "";
			}
		};

		const { itemsReducer } = this.props;
		const { insights = {} } = itemsReducer.data || {};
		let rows = insights.rows || {};

		const { metaReducer } = this.props;
		const insightsMeta = metaReducer.data.insights || {};
		let mate_container = [];
		mate_container.push(
			<Helmet>
				<meta itemprop="name" content={insightsMeta.title} />
				<meta itemprop="description" content={insightsMeta.description} />
				<meta itemprop="image" content={insightsMeta.img} />

				<meta name="twitter:card" content={insightsMeta.description} />
				<meta name="twitter:site" content={insightsMeta.url} />

				<meta property="og:title" content={insightsMeta.title} />
				<meta property="og:type" content={insightsMeta.type} />
				<meta property="og:url" content={insightsMeta.url} />
				<meta property="og:image" content={insightsMeta.img} />
				<meta property="og:description" content={insightsMeta.description} /> 
				<meta property="og:site_name" content={insightsMeta.sitename} />	
				<meta name="keywords" content={insightsMeta.keywords} />
			</Helmet>
		)
		return (
			<section className="page-items">
				<h1 className="access-elem">{insightsMeta.h1}</h1>
				{mate_container}
				<div className="items-container">
					{Object.keys(rows || {}).map(function (key) {
						let value = rows[key];
						let items = value.items;
						let itemsNum = items.length;
						let rowHasChild = "row-container";

						let sub_item = [];
						{Object.keys(items || {}).map(function (sbKey) {
							let itemVal = items[sbKey];
							let itemBgColor = itemVal.backgroundColor || {};
              				let titleColor = itemVal.titleColor || "origin";
							let contentColor = itemVal.contentColor || "origin";
							let itemBgImg = "url(" + itemVal.backgroundImg + ")";
							let itemBgImgX = itemVal.imgFocusX || "origin";
							let itemBgImgY = itemVal.imgFocusY || "origin";
							let itemTitle = itemVal.title;
							let itemDesc = itemVal.desc;

							let itemTips = itemVal.tips || {};

							let itemHrefVal = itemVal.href;
							let itemHrefArrowColor = itemVal.hrefArrowColor || "origin";
							let itemHrefTxt = itemVal.hrefTxt;

							let itemLinks = itemVal.linkList;
							let itemLinksTitleColor = itemLinks.titleColor || "origin";
							let hyperlinkColor = itemLinks.hyperlinkColor || "origin";
							let itemLinksTitle = itemLinks.title;
							let itemLinksVal = itemLinks.links || {};
							let itemLinksNum = itemLinksVal.length;
							let hasItemLinks = itemLinksNum>0;

							let item_link = [];
							{Object.keys(itemLinksVal || {}).map(function (linkKey) {
								let linkVal = itemLinksVal[linkKey];
								let linkTxt = linkVal.txt;
								let linkHref = linkVal.href;
								item_link.push(
									<li><a href={linkHref} target="_blank" className="item-links__link" style={{ "color":hyperlinkColor }}>{linkTxt}</a></li>
								);
							});}

							let itemReload = itemVal;
							let itemSlidersVal = itemReload.innerSliders || {};
							let itemSlidersArr = itemSlidersVal.sliders || {};

							let showSlider = itemSlidersArr.length>0;
							if(showSlider){rowHasChild = rowHasChild + " row-container--slider"}

							let item_slider = [];
							{Object.keys(itemSlidersArr || {}).map(function (sliderKey) {
								let sliderVal = itemSlidersArr[sliderKey];
								let sliderImg = sliderVal.img;
								let sliderContentColor = sliderVal.sliderContentColor || "origin";
								let sliderTitle = sliderVal.title;
								let sliderDesc = sliderVal.desc;
								let sliderLink= sliderVal.link;
								let sliderLinkTxt= sliderVal.linkTxt;
								item_slider.push(
									<div className="item-slider-container swiper-slide">
										{ sliderImg ? <div className="item-slider__img"><img src={`${process.env.PUBLIC_URL}` + sliderImg} alt={sliderTitle} /></div> :''}
										<div className="item-slider-text">
											<div className={"item-slider__title item-slider__title--"+sliderContentColor}>{sliderTitle}</div>
											<div className={"item-slider__desc item-slider__desc--"+sliderContentColor}>{sliderDesc}</div>
											<div className="item-slider__link"><a href={sliderLink} className={"item-slider__href item-slider__href--"+sliderContentColor} target="_blank">{sliderLinkTxt}</a></div>
										</div>
									</div>
								);
							});}

							let itemSeachBar = itemVal.searchBar;

							let itemChildItem = itemVal.childItem || {};
							let childItemArr = itemChildItem.childItems || {};
							let childItemNum = childItemArr.length;
							if(childItemNum>0){rowHasChild = rowHasChild + " row-container--child"}

							sub_item.push(
								<div 
								className={
									(itemVal["backgroundImg"]) ?
									 "item-container item-container--bg item-container--bg--x-"+itemBgImgX+" item-container--bg--y-"+itemBgImgY
									: ((itemVal["backgroundColor"]) ? "item-container item-container--bg--color" : "item-container")
								} style={
									(itemVal["backgroundImg"]) ?
									 ({ "backgroundImage": itemBgImg })
									: ({ "backgroundColor": itemBgColor})
								}>
									<div className={"item-content "+getLinksCols(itemsNum, itemLinksNum)+getSliderContainer(showSlider)+getChildContainer(childItemNum)}>
										{(itemVal["label"])
											? (<div className={"side-title side-title--"+titleColor}>{itemVal["label"]}</div>)
											: null
										}
										{(itemVal["icon"])
											? (<div className={"item-icon"}><img src={`${process.env.PUBLIC_URL}` + itemVal["icon"]} alt={itemTitle}/></div>)
											: null
										}
										<div className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
										<p className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc}</p>
										{(Object.getOwnPropertyNames(itemTips).length != 0) ? 
											(<div className="item-tips"><div className={"item-tips__title item-tips__title--"+titleColor}>{itemTips["title"]}</div><div className="item-tips__desc">{itemTips["desc"]}</div></div>)
											: null
										}
										{hasItemLinks?
											<div className={"item-links"}>
												<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
												{(getCols(itemsNum)=="full") 
													? ((itemLinksNum < 5)
														? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
														: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
													)
													: ((itemLinksNum < 3)
														? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
														: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
													)
												}
											</div>
										: null
										}
										{showSlider ?
											<div className="item-sliders">
												<div id="item-inner-slider" className="item-sliders-container item-has-slider">
													<div className="swiper-wrapper">
														{item_slider}
													</div>
													<a href="javascript:;" className="item-slider__btn item-slider__btn--prev"></a>
    												<a href="javascript:;" className="item-slider__btn item-slider__btn--next"></a>
													<div className="item-slider__pagination"></div>
												</div>
											</div>
										 : null }
										 {itemSeachBar ?
											 <PageItemSearchContainer />
											 : null
										 }
										 {itemChildItem ?
											 <PageItemChildItem childObj={itemChildItem}/>
											 : null
										 }
										{itemHrefVal ?
											<div className="item-arrow">
											{ itemHrefTxt ?
													<a target="_blank" href={itemHrefVal} className={"item-text__link item-text__link--"+itemHrefArrowColor}>{itemHrefTxt}</a>
												:
													<a target="_blank" href={itemHrefVal} className={"item-arrow__link item-arrow__link--"+itemHrefArrowColor}></a>
												}
											</div>
											: null
										}
									</div>
								</div>
							);
						});}
						return(
							<ScrollAnimation animateIn="fadeIn" animateOnce="true" className={"items-row items-row--"+getCols(itemsNum)}>
							<div className={rowHasChild}>{sub_item}</div>
							</ScrollAnimation>
						);
					})} 
				</div>
			</section>
		);
	}
}