import React, { Component } from 'react';

import PageIntroContainer from '../../containers/PageIntroContainer';
import InsightsItemsContainer from '../../containers/InsightsItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

export default class Insights extends Component {
  render() {
    return (
      <main>        
      <Helmet bodyAttributes={{ 'data-page': 'insights' }} />     
        <PageIntroContainer curPage={"insights"}/>
        <InsightsItemsContainer />
        <PageAdsContainer />
        <WebTrends />
      </main>
    );
  }
}
