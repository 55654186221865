import React, { Component } from "react";
import Masonry from 'react-masonry-component';
import store from "../../store/";
import { fetchItemsData } from "../../actions/";

import PageItemSliderContainer from "../../containers/PageItemSliderContainer";
import PageItemSearchContainer from "../../containers/PageItemSearchContainer";
import PaginationContainer from '../../containers/PaginationContainer';

const masonryOptions = {
	transitionDuration: 0
};

export default class BusinessItems extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson(this.props);
		this.state = {};
		this.getSelectVal = this.getSelectVal.bind(this);
	}

	swicthLanJson(obj) {
		//console.log(obj);
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		//store.dispatch(fetchItemsData("/data/page-items-" + currentLang + ".json"));
	}

	getSelectVal(cond, e) {
		this.setState({
			[cond]: e.target.value
		})
	}

	render() {
		const pageState = this.state;
		const getValFunc = this.getSelectVal;
		const { itemsReducer } = this.props;
		const { successStories = {} } = itemsReducer.data || {};
		const filterTxt = successStories.filterTxt || "";
		const filterObj = successStories.filterBy || {};

		let story_filter = [];
		{
			Object.keys(filterObj || {}).map(function (cond, i) {
				story_filter.push(
					<div className={"story-filter-cat-container story-filter-cat-container--" + cond}>
						{pageState.hasOwnProperty(cond) ?
							<div className={"filter-cat__title"}>{pageState[cond]}</div>
							:
							<div className={"filter-cat__title"}>{cond}</div>
						}
						<select className={"story-filter-condList story-filter-condList--" + cond} onChange={(e) => getValFunc(cond, e)}>
							<option className={"filter-condList__cond"} value="default">{cond}</option>
							{filterObj[cond].map((item, i) => <option className={"filter-condList__cond"} value={item}>{item}</option>)}
						</select>
					</div>
				)
			});
		}

		let story_list = [];
		let storyList = successStories.storyList || {};
		{
			Object.keys(storyList || {}).map(function (storyKey) {
				let storyVal = storyList[storyKey];
				let storyDesc = storyVal.desc;

				let storyTagsArr = storyVal.tags || {};

				let story_tags = [];
				{
					Object.keys(storyTagsArr || {}).map(function (tagKey) {
						let tagVal = storyTagsArr[tagKey];
						let tagName = tagVal.name;
						let tagHref = tagVal.href;
						story_tags.push(
							<div className="story-tag">
								<div className={"tag__link"}>{tagVal["name"]}</div>
							</div>
						)
					});
				}
				story_list.push(
					<div className={"story-container"}>
						<div className={"story-img"}><a href={storyVal["href"]} target="_blank">{ storyVal["img"] ? <img src={`${process.env.PUBLIC_URL}` + storyVal["img"]} alt={storyVal["title"]} /> :''}</a></div>
						<div className={"story-title"}><a href={storyVal["href"]} target="_blank">{storyVal["title"]}</a></div>
						<div className={"story-desc"}>{storyVal["desc"]}</div>
						<div className={"story-tags"}>{story_tags}</div>
					</div>
				);
			});
		}

		let curPage = successStories.currentPage || '';
		let totalPage = successStories.totalPage || '';
		if (!curPage) { curPage = 1 };
		if (!totalPage) { totalPage = 1 };

		return (
			<section className={"sec-page-story"}>
				<div className={"story-filter"}>
					<div className="story-filter__title">{filterTxt}</div>
					{story_filter}
				</div>
				<div className={"story-list"}>
					{story_list}
				</div>
				{/*{totalPage > 1 ?*/}
					<PaginationContainer totalPage={10} />
					:
					''
				{/*}*/}
			</section>
		);
	}
}