
import React, { Component } from "react";
import { Helmet } from "react-helmet";


export default class WebTrends extends Component {
    componentWillMount() {
    }
    render() {
        return (<div id="wts-app"></div>);
    }

}