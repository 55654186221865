
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import MoreEventsCat from '../components/PageItem/MoreEventsCat';

const mapStateToProps = (state, ownProps) => ({
  itemsReducer: state.itemsReducer,
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(MoreEventsCat);