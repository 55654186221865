import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

const styles = {
  map: {
    maxWidth: '100%'
  },
  container: {
  }
};

const pin = `${process.env.PUBLIC_URL}` + "/images/contactUs/pin.png";
const highlightedPin = `${process.env.PUBLIC_URL}` + "/images/contactUs/highlightPin.png"

export class GMap extends Component {
	constructor(props) {
		super(props);
		this.generateMarkers = this.generateMarkers.bind(this);
	}
	onMarkerClick(index)
	{
		//map.setCenter(index.getPosition());
		this.props.openOverlayView(index);
		this.props.setHighlightedPinIndex(index);
	}
	generateMarkers(offices: []) {
		var markers = [];

		if( offices != null ) {
			for( var i=0; i<offices.length; i++ ){
				if(( this.props.selectedCategory != null && this.props.selectedCategory == offices[i].categories ) || 
					 ( this.props.selectedCategory == null )){
						if(!offices[i]){
							offices[i] = {};
						}
						if(offices[i].BtnName == undefined) return;
						var str = offices[i].BtnName.toLowerCase();
						// function string_to_slug (str) {
						str = str.replace(/^\s+|\s+$/g, ''); // trim
						str = str.toLowerCase();
					
						str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
							.replace(/\s+/g, '-') // collapse whitespace and replace by -
							.replace(/-+/g, '-'); // collapse dashes
						
							//return str;
						//}
						markers.push(
							<Marker
									key={i}
									title={'m_'+str}
									visible={true}
									onClick={this.onMarkerClick.bind( this, i )}
									options={{icon: this.props.highlightedPinIndex == i ? highlightedPin : pin}} 
									position={{lat: offices[i].Latitude, lng: offices[i].Longitude}} 
									onDragEnd={this.onDragEnd}
									/>
						);
					}
			}
		}
		return markers;
	}


	render() {
    const {google, coord, desc, title} = this.props;
	const {onMapClicked, onMarkerClick, generateMarkers,onDragEnd} = this;
		// const {activeMarker, showingInfoWindow} = this.state;
		let cusCenter = '';
		if(this.props.selectedLat&&this.props.selectedLng){
			cusCenter = { "lat": this.props.selectedLat, "lng": this.props.selectedLng };
		}
		let zoom = 5;
		if(this.props.zoom){
			zoom = this.props.zoom;
		}
    return (
      <Map
        style={styles.map}
        containerStyle={styles.container}
        className="super-map-wrapper"
        google={google}
        initialCenter={{ "lat": this.props.selectedLat, "lng": this.props.selectedLng }}
        center={cusCenter}
		zoom={zoom}
		>

	    {generateMarkers(this.props.offices)}
      </Map>
    );
  }
}


export default GoogleApiWrapper({ apiKey: "AIzaSyC_O2wg-CjHUoPZhcCShdQkEXNmUoqbiAY"})(GMap); // live key
