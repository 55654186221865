
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import OurBusiness from '../components/OurBusiness/OurBusiness';

const mapStateToProps = state => ({
    businessReducer: state.businessReducer,
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(OurBusiness);