
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import PageItemSearch from '../components/PageItem/PageItemSearch';

const mapStateToProps = state => ({
  dataReducer: state.dataReducer,
  headerSearchReducer: state.headerSearchReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(PageItemSearch);