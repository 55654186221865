
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import HeaderSearch from '../components/Header/HeaderSearch';

const mapStateToProps = state => ({
  dataReducer: state.dataReducer,
  headerSearchReducer: state.headerSearchReducer,
  topSearchReducer: state.topSearchReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(HeaderSearch);