import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestOurWorkData() {
	return { type: types.REQ_OURWORK_DATA }
};

function receiveOurWorkData(json, lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_OURWORK_DATA,
		data: data
	}
};

function receiveOurWorkError(json) {
	return {
		type: types.RECV_OURWORK_ERROR,
		data: json
	}
};

export function fetchOurWorkData(url, lang) {
	return function (dispatch) {
		dispatch(requestOurWorkData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
		.then(function (response) {
			dispatch(receiveOurWorkData(response.data, lang));
		})
		.catch(function (response) {
			dispatch(receiveOurWorkError(response.data));
		})
	}
};