
import React, { Component } from "react";
import * as CMSAPIs from '../../constants/api_url.js';


export default class ServicePledge extends Component {

  constructor(props) {
    super(props);
  }

  render() {
  	const pledge = this.props.items;
  	return (
          <div className="home-pledge-content">
            <div className="left-panel">
              { pledge.thumb ? <img src={CMSAPIs.generateSrcPath(pledge.thumb) || ""} /> :''}
            </div>
            <div className="right-panel">
              <div className="item-content">
                <h4 className="title">{pledge.title || "" }</h4>
                <p className="content">{pledge.content || "" }</p>
                <a target="_blank" href={pledge.url || "" } className="report-download-btn"><span className="download-icon" />{pledge.download || ""}</a>
              </div>
            </div>
          </div>
	);
  }

}