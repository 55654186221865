import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import ContactUsContainer from '../../containers/ContactUsContainer';
import SocialMediaContainer from '../../containers/SocialMediaContainer';

import WebTrends from '../WebTrends/';

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'contactus' }} />
        <ContactUsContainer curPage={"contactUs"} />
        <SocialMediaContainer />
        <WebTrends />
      </main>
    );
  }
}
