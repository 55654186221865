import React, { Component } from 'react';
import ConformanceContainer from '../../containers/ConformanceContainer';
import { NavLink } from 'react-router-dom';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

export default class FooterBottom extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { dataReducer } = this.props;
    const { data } = dataReducer || {};

    const { share = {} } = data || {};

    const { footer = {} } = data || {};
    const { footerBottom = {} } = footer || {};

    const logo = footerBottom.logo;
    const logoMobile = footerBottom.logoMobile;
    const links = footerBottom.links;
    const tncLinks = footerBottom.tncLinks;
    const langLinks = footerBottom.langLinks;
    const copyRightTitle = footerBottom.copyRightTitle;
    const copyRight = footerBottom.copyRight;
    return (
      <div className="footer-bottom">
        <div className="content-wrapper">
          <a href="/" target="_blank" className="footer-bottom__logo">
            { logo ? <img src={`${process.env.PUBLIC_URL}` + logo} className="footer-bottom__logo-img-dt" alt="logo" /> :''}
            { logoMobile ? <img src={`${process.env.PUBLIC_URL}` + logoMobile} className="footer-bottom__logo-img-mb" alt="logo" /> :''}
          </a>
          <div className="footer-bottom__row">
            {Object.keys(links || {}).map(function (key) {
              let value = links[key];
              let txt = value.txt;
              let href = value.href;
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile == "true")?" footer-bottom__item--hidden-mb":"";

              let isTextsize = href.indexOf('/textsize')>-1;
              if(isTextsize){
                return (
                  <NavLink to="/textsize" key={key} className={"footer-bottom__item"+mobClass}>{txt}</NavLink>
                );
              } else {
                return (
                  <div key={key} className={"footer-bottom__item"+mobClass}><a href={href} className="footer-bottom__link">{txt}</a></div>
                );
              }
            })}
          </div>
          <div className="footer-bottom__row footer-bottom__row--lang">
            {Object.keys(langLinks || {}).map(function (key) {
              let value = langLinks[key];
              let txt = value.txt;
              let href = value.href;
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile == "true")?" footer-bottom__item--hidden-mb":"";
              return (
                <div key={key} className={"footer-bottom__item"+mobClass}><a target="_blank" href={href} className={"footer-bottom__link"}>{txt}</a></div>
              );
            })}
          </div>
          <div className="footer-bottom__row">
            {Object.keys(tncLinks || {}).map(function (key) {
              let value = tncLinks[key];
              let txt = value.txt;
              let href = value.href;
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile == "true")?" footer-bottom__item--hidden-mb":"";
              return (
                <div key={key} className={"footer-bottom__item"+mobClass}><a href={href} className="footer-bottom__link">{txt}</a></div>
              );
            })}
          </div>
          <div className="button-share__holder">
            <h4 className="button-share__title">{share.desc}</h4>
            <div className="button-share__btn-holder">
              <a target="_blank" href="https://www.facebook.com/hktdc.hk/" className="button-share button-share--fb"></a>
              <a target="_blank" href="https://twitter.com/hktdc" className="button-share button-share--tw"></a>
              <a target="_blank" href="https://www.linkedin.com/company/hong-kong-trade-development-council/?originalSubdomain=hk" className="button-share button-share--ln"></a>
              <a target="_blank" href="https://www.weibo.com/hktdc" className="button-share button-share--wb"></a>
              <ButtonWechatContainer url="http://weixin.qq.com/r/ty_Dm73EIeBerfn493ql" btnClass="button-share button-share--wc" />
              <a target="_blank" href={share.youtubeLink} className="button-share button-share--yt"></a>
              <a target="_blank" href="http://i.youku.com/hktdcsimpchi" className="button-share button-share--yk"></a>
            </div>
          </div>
          <div className="footer-bottom__row">
            <p className="footer-bottom__copyright">
              <span className="footer-bottom__copyright-title">{copyRightTitle}</span>
              {copyRight}
            </p>
          </div>

        </div>
      </div>
    );
  }
}