import React, { Component } from "react";
import store from "../../store/";
import { fetchItemsData } from "../../actions/";
import { fetchEventData } from "../../actions/";
import { fetchWebMetaData } from "../../actions/";

import PageMultiSliderContainer from "../../containers/PageMultiSliderContainer";
import PageEventListContainer from "../../containers/PageEventListContainer";
import MoreEventsListContainer from "../../containers/MoreEventsListContainer";
import PaginationContainer from '../../containers/PaginationContainer';
import MoreEventsCatContainer from '../../containers/MoreEventsCatContainer';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

export default class EventsItems extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson(this.props);
		store.dispatch(fetchEventData("/data/page-tdc-events.json"));
		this.state = {	isClickActive: false, title:" ", tabsShow: true, curCat: "tdcevents" };
		this.clickSwitchFunc = this.clickSwitchFunc.bind(this);
	}

	swicthLanJson(obj){
		const{i18n} = this.props;
		let currentLang = i18n.locale;
		if(currentLang==null){currentLang="en"}
		//store.dispatch(fetchItemsData("/data/page-items-"+currentLang+".json"));
	}


	clickSwitchFunc(title){
		this.setState({
			isClickActive: true,
			title: title
		})
		switch (title) {
			case 'Events in Hong Kong':
				store.dispatch(fetchEventData('/data/page-hk-events.json'));
				this.setState({
					tabsShow: true,
					curCat: "tdcevents"
				});
				break;
			case 'Events outside Hong Kong':
				store.dispatch(fetchEventData('/data/page-os-events.json'));
				this.setState({
					tabsShow: true,
					curCat: "tdcevents"
				});
				break;
			case 'moreEventsIn':
				store.dispatch(fetchEventData('/data/more-events-hk.json'));
				this.setState({
					tabsShow: false,
					curCat: "hkevents"
				});
				break;
			case 'moreEventsOutside':
				store.dispatch(fetchEventData('/data/more-events-os.json'));
				this.setState({
					tabsShow: false,
					curCat: "osevents"
				});
				break;
			default:
				store.dispatch(fetchEventData('/data/page-tdc-events.json'));
				this.setState({
					tabsShow: true,
					isClickActive: false,
					curCat: "tdcevents"
				});
		}
	}

	render() {
		const clickState = this.state;
		const switchFunc = this.clickSwitchFunc;
        const { itemsReducer } = this.props;
		const { events = {} } = itemsReducer.data || {};
		//Intro
		const { introContent = {} } = events || {};
		const tabsTitle = introContent.tabsTitle;

		let tabs_title=[];
		{Object.keys(tabsTitle || {}).map(function (tabsKey, i) {
			let tagTitle = tabsTitle[tabsKey];
			{clickState.isClickActive ?
				tabs_title.push(<a className={clickState.isClickActive&&clickState.title==tagTitle ? "page-hktdc-events__filter is-active page-hktdc-events__filter--"+tagTitle : "page-hktdc-events__filter page-hktdc-events__filter--"+tagTitle} onClick={() => {switchFunc(tagTitle)}}>{tagTitle}</a>)
			:
				tabs_title.push(<a className={i==0 ? "page-hktdc-events__filter is-active page-hktdc-events__filter--"+tagTitle : "page-hktdc-events__filter page-hktdc-events__filter--"+tagTitle} onClick={() => {switchFunc(tagTitle)}}>{tagTitle}</a>)
			}	
		});}

        const { eventReducer } = this.props;
		const { eventSliders = {} } = eventReducer.data || {};
		let hasSliders = eventSliders.length>0;

		const { eventList = {} } = eventReducer.data || {};
		let hasEventList = eventList.length>0;

		let curPage = eventReducer.data.currentPage || '';
		let totalPage = eventReducer.data.totalPage || '';
		if(!curPage){curPage=1};
		if(!totalPage){totalPage=1};

		const { metaReducer } = this.props;
		const eventsMeta = metaReducer.data.events || {};
		let mate_container = [];
		mate_container.push(
			<Helmet>
				<meta itemprop="name" content={eventsMeta.title} />
				<meta itemprop="description" content={eventsMeta.description} />
				<meta itemprop="image" content={eventsMeta.img} />

				<meta name="twitter:card" content={eventsMeta.description} />
				<meta name="twitter:site" content={eventsMeta.url} />

				<meta property="og:title" content={eventsMeta.title} />
				<meta property="og:type" content={eventsMeta.type} />
				<meta property="og:url" content={eventsMeta.url} />
				<meta property="og:image" content={eventsMeta.img} />
				<meta property="og:description" content={eventsMeta.description} /> 
				<meta property="og:site_name" content={eventsMeta.sitename} />	
				<meta name="keywords" content={eventsMeta.keywords} />
			</Helmet>
		)

		return(
			<ScrollAnimation animateIn="fadeIn" animateOnce="true" className="page-hktdc-events">
			<h1 className="access-elem">{eventsMeta.h1}</h1>
				{mate_container}
				{clickState.tabsShow ?
				<div className="page-hktdc-events-intro">
					<div className="side-title">{introContent['label']}</div>
					<div className="page-hktdc-events__title">{introContent['title']}</div>
					<div className="page-hktdc-events__filters">
						{tabs_title}	
					</div>
				</div>
				: null }
				<div className="page-hktdc-events-slider">
					{eventReducer.isLoading ? '' : ''}
					{hasSliders ?
						<PageMultiSliderContainer multiSliderObj={eventSliders}/>
					: null }
				</div>
				{hasEventList ?
				<div className="page-hktdc-events-list">
					{eventReducer.isLoading ? '' : ''}
					{clickState.tabsShow ?
						<PageEventListContainer catTitle = {clickState.title}/>
						: <MoreEventsListContainer/> 
					}
				</div>
				: null }
				<PaginationContainer totalPage={totalPage} />
				<MoreEventsCatContainer curCat={clickState.curCat}/>
			</ScrollAnimation>
		);
	}
}