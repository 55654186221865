
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import MoreEventsList from '../components/PageItem/MoreEventsList';

const mapStateToProps = state => ({
  eventReducer: state.eventReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(MoreEventsList);