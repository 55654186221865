
import React, { Component } from "react";
import store from "../../store/";
import { fetchMissionData } from "../../actions/";
import Popup from "reactjs-popup";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import Mission from '../AboutHome/Mission';
import ServicePledge from '../AboutHome/ServicePledge';

export default class OurMission extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, videoLink: "" };
    this.swicthLanJson(this.props);
  }

  swicthLanJson(obj) {
    const { i18n } = this.props;
    let currentLang = i18n.locale;
    if (currentLang == null) { currentLang = "en" }
    //store.dispatch(fetchMissionData("/data/our-mission-" + currentLang + ".json"));
    store.dispatch(fetchMissionData(CMSAPIs.apiOurMission, currentLang));
  }

  render() {

    const { missionReducer } = this.props;
    const staticData = missionReducer.data || {};
    const mission = staticData.mission || {};
    const servicePledge = staticData.servicePledge || {};
    const metaData = staticData.metaData || {};

    return (
      <div>
        <section className="our-mission-container">
          <Helmet>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />

            <meta itemprop="name" content={metaData.title} />
            <meta itemprop="description" content={metaData.description} />

            <meta name="twitter:card" content={metaData.description} />
            <meta name="twitter:site" content={metaData.url} />

            <meta property="og:title" content={metaData.title} />
            <meta property="og:url" content={metaData.url} />
            <meta property="og:description" content={metaData.description} />
            <meta name="keywords" content={metaData.keywords} />
          </Helmet>
          {
            <Mission
              items={mission}
            />
          }
        </section>
        <section className="our-mission-pledge-container">
          {
            <ServicePledge
              items={servicePledge}
            />
          }
        </section>
      </div>
    );
  }

}