/*
 * Function control the path
 */
export const generateSrcPath = function (path) {
    if (!path) return;
    path = String(path);
    if (path.indexOf("//") < 0) {
        return `${process.env.PUBLIC_URL}` + path;
    } else {
        return path;
    }
};

export const getPublicDataPath = (path) => {
    if (!path) return;
    path = String(path);
    if (path.indexOf("//") < 0) {
        return process.env.PUBLIC_URL + path.substring(path.indexOf("/data"));
    } else {
        return path;
    }
};

/*
 * APIs path provided by HKTDC
 */

let env_settings = "";
let isPreview = false;
let domain = "";

switch (process.env.REACT_APP_STAGE) {
    case 'buildSIT':
        env_settings = "sit";
        isPreview = false;
        break;
    case 'buildSITPreview':
        env_settings = "sit";
        isPreview = true;
        break;
    case 'buildUAT':
        env_settings = "uat";
        isPreview = false;
        break;
    case 'buildUATPreview':
        env_settings = "uat";
        isPreview = true;
        break;
    case 'buildPRD':
        env_settings = "prod";
        isPreview = false;
        break;
    case 'buildPRDPreview':
        env_settings = "prod";
        isPreview = true;
        break;
    case 'localhost':
    default:
        env_settings = "sit";
        isPreview = false;
        break;
}

switch (env_settings) {
    case "sit":
    default:
        domain = isPreview ? "http://cmsappdev01.hktdc.org" : "https://api-cms-sit.hktdc.com";
        break;
    case "uat":
        domain = isPreview ? "http://cmsappuat01.hktdc.org" : "https://api-cms-uat.hktdc.com";
        break;
    case "prod":
        domain = isPreview ? "http://cms.tdc.org.hk" : "https://api-cms.hktdc.com";
        break;
};

console.log(env_settings);
console.log(isPreview);
console.log(domain);

const composeApiUrl = (contentType, xml) => {
    return isPreview ?
        `${domain}/lscs/v1/document?format=json&start=0&max=10&project=/default/main/About%20Us&context=/default/main/About%20Us/WORKAREA/default&q=((TeamSite/Templating/DCR/Type:${contentType})%20AND%20(Path:${xml}.xml))&includeDCRContent=true&sameOrigin=true`
        :
        `${domain}/ls/v1/s/?project=//cmsapp/About%20Us&q=((TeamSite/Templating/DCR/Type:${contentType})%20AND%20(Path:${xml}.xml))`;
};

export const global_env_settings = env_settings;
//Common Sections
// 1. Page Intro (Integrated)
export const apiPageIntro = composeApiUrl("Common%20Sections/Page%20Intro", "page-intro");
// 2. Social Media (Integrated)
export const apiSocialMedia = composeApiUrl("Common%20Sections/Social%20Media", "social-media");
// 3. Static Content: (Integrated)
export const apiStaticContent = composeApiUrl("Common Sections/Static Content", "static-content");

//Pages
// 4. Career (Integrated)
export const apiCareer = composeApiUrl("Pages/Career", "about-career");
// 5. Company Profile (Integrated)
export const apiCompanyProfile = composeApiUrl("Pages/Our Work_Explore", "company-profile");
// 6. Contact Us (Integrated)
export const apiContactUs = composeApiUrl("Pages/Contact Us", "contact-us");
// 7. Corporate Structure (Integrated)
export const apiCorporateStructure = composeApiUrl("Pages/Corporate Structure", "corporate-structure");
// 8. Council (Integrated)
export const apiCouncil = composeApiUrl("Pages/Council", "council");
// 9. Enhancing Capability (Integrated)
export const apiEnhancingCapability = composeApiUrl("Pages/Our Work_Enhancing", "enhancing-capability");
// 10. Home (Integrated)
export const apiHome = composeApiUrl("Pages/Home", "about-home");
// 11. Management (Integrated)
export const apiManagement = composeApiUrl("Pages/Management", "management");
// 12. Our Business (Integrated)
export const apiOurBusiness = composeApiUrl("Pages/Our Work_Showcasing", "our-business");
// 13. Our Mission
export const apiOurMission = composeApiUrl("Pages/Our Mission", "our-mission");
// 14. Our Work (Integrated)
export const apiOurWork = composeApiUrl("Pages/Our%20Work", "ourWork");
// 15. Executive Trainee Scheme Page
export const apiExecutiveTraineeSchemePage =  composeApiUrl("Pages/Executive Trainee Scheme","");





//FS Testing
//Common Sections
// export const apiPageIntro = 'http://localhost/hktdc-aboutus-api/1_page_intro.json';
// export const apiSocialMedia = 'http://localhost/hktdc-aboutus-api/2_social_media.json';
// export const apiStaticContent = 'http://localhost/hktdc-aboutus-api/3_static_content.json';
// export const apiCareer = 'http://localhost/hktdc-aboutus-api/4_career.json';
// export const apiCompanyProfile = 'http://localhost/hktdc-aboutus-api/5_company_profile.json';
// export const apiContactUs = 'http://localhost/hktdc-aboutus-api/6_contact_us.json';
// export const apiCorporateStructure = 'http://localhost/hktdc-aboutus-api/7_corp_strucuture.json';
// export const apiCouncil = 'http://localhost/hktdc-aboutus-api/8_council.json';
// export const apiEnhancingCapability = 'http://localhost/hktdc-aboutus-api/9_enhancing_cap.json';
// export const apiHome = 'http://localhost/hktdc-aboutus-api/10_home.json';
// export const apiManagement = 'http://localhost/hktdc-aboutus-api/11_management.json';
// export const apiOurBusiness = 'http://localhost/hktdc-aboutus-api/12_our_business.json';
// export const apiOurMission = 'http://localhost/hktdc-aboutus-api/13_our_mission.json';