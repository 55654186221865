import React, { Component } from 'react';
import store from '../../store/configureStore.js';
import { Helmet } from "react-helmet";
import ScrollAnimation from 'react-animate-on-scroll';
import CorporateStructureContainer from '../../containers/CorporateStructureContainer';
import PageIntroContainer from '../../containers/PageIntroContainer';
import WebTrends from '../WebTrends/';

export default class CorporateStructure extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    return (
      <main>
        <PageIntroContainer curPage={"corporateStructure"} />
        <CorporateStructureContainer />
        <WebTrends />
      </main>
    );
  }
}
