import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import AnimateHeight from 'react-animate-height';
import $ from 'jquery';

import * as CMSAPIs from '../../constants/api_url.js';

export default class OneManInARow extends Component {

	constructor(props){
		super(props);
		this.state = { height: 120, expandBtnLabel: (this.props.meta.detail || "") }
	}

	expand(){
		var height = this.descElement.clientHeight;
		if( this.state.height === 'auto' ) {
			this.setState({ height: this.shortDescElement.clientHeight + 20, expandBtnLabel: (this.props.meta.detail || "") });
		}
		else {
			this.setState({ height: 'auto', expandBtnLabel: (this.props.meta.hide || "") });
		}
	}

	loadProfile()
	{
		//console.log(this.props.member.profile );
	}

	componentDidMount () {
		//console.log('shortDescElement' + this.shortDescElement.clientHeight );
		this.setState({ height: this.shortDescElement.clientHeight + 20});
	}

	render(){
		//console.log(this.props.background);

		var itemThumb = this.props.member.thumbnail || '';
		if(itemThumb != '' && itemThumb.indexOf('//')<0){
			itemThumb = CMSAPIs.generateSrcPath(itemThumb);
		}
		const divStyle = {	
		    backgroundImage: 'url('+itemThumb+')',
		    backgroundSize: 'cover',
		    backgroundPosition: '0 -10px'
		}

		/* var itemProfile = this.props.member.profile;
		if(itemProfile != '' && itemProfile.indexOf('//')<0){
			itemProfile = CMSAPIs.generateSrcPath(itemProfile);
		} */
		var itemProfile = CMSAPIs.getPublicDataPath(this.props.member.profile);

		return (
			<section className="management-onemen" style={{"backgroundImage": "url(" + CMSAPIs.generateSrcPath(this.props.background) + ")"}}>
				<div className="wrapper">
					{this.props.member.thumbnail === undefined || this.props.member.thumbnail === '' ? '' : <div className="img-holder" style={divStyle} ></div>}
					<div className="bio">
						<p className="name" dangerouslySetInnerHTML={{ __html: this.props.member.name}} />
						<p className="position" dangerouslySetInnerHTML={{ __html: this.props.member.position}} />
						<p className="shortDesc desc" dangerouslySetInnerHTML={{ __html: this.props.member.shortDesc }}  ref={ (descElement) => this.shortDescElement = descElement } />
						<AnimateHeight duration={ 500 } height={ this.state.height } >
							<div ref={ (descElement) => this.descElement = descElement}>
							{
								(this.props.member.desc || "").split('<br /><br />').map((desc, i) => {
									desc = desc.replace('<p>', '').replace('</p>', '');
									return (
										<p key={i} className="desc" dangerouslySetInnerHTML={{ __html: desc }}   />
									);
								})
							}
							</div>
						</AnimateHeight>
						<button className={"ExpandBtn"} onClick={() => this.expand()}><span>{this.state.expandBtnLabel}</span></button>
						<button className={"ProfileBtn"} onClick={() => window.open(itemProfile, '_blank') }><span className="download-icon" /><span>{this.props.meta.downloadProfile || ""}</span></button>
					</div>
				</div>
			</section>
			
		);
	}
}