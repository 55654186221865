
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import EventsItems from '../components/EventsItems/EventsItems';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  itemsReducer: state.itemsReducer,
  eventReducer: state.eventReducer,
  metaReducer: state.metaReducer,
  curPage: ownProps.curPage,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(EventsItems);