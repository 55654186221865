import React, { Component } from "react";
import store from "../../store/";
import { fetchItemsData } from "../../actions/";
import { fetchWebMetaData } from "../../actions/";

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

export default class BusinessItems extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson(this.props);
		store.dispatch(fetchWebMetaData("/data/web-meta.json"));
	}

	swicthLanJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		//store.dispatch(fetchItemsData("/data/page-items-" + currentLang + ".json"));
	}

	render() {
		const getCols = (str) => ({
			"1": "full",
			"2": "cols",
			default: "full"
		})[str];

		const getLinksCols = (str1, str2) => {
			if (str1 < 2) {
				if (str2 > 4) {
					return "item--full__links--cols";
				} else {
					return "item--full__links--col";
				}
			} else {
				return "item--cols__links";
			}
		}

		const { metaReducer } = this.props;
		const businessMeta = metaReducer.data.business || {};
		let mate_container = [];
		mate_container.push(
			<Helmet>
				<meta itemprop="name" content={businessMeta.title} />
				<meta itemprop="description" content={businessMeta.description} />
				<meta itemprop="image" content={businessMeta.img} />

				<meta name="twitter:card" content={businessMeta.description} />
				<meta name="twitter:site" content={businessMeta.url} />

				<meta property="og:title" content={businessMeta.title} />
				<meta property="og:type" content={businessMeta.type} />
				<meta property="og:url" content={businessMeta.url} />
				<meta property="og:image" content={businessMeta.img} />
				<meta property="og:description" content={businessMeta.description} />
				<meta property="og:site_name" content={businessMeta.sitename} />
				<meta name="keywords" content={businessMeta.keywords} />
			</Helmet>
		)

		const { itemsReducer } = this.props;
		const { business = {} } = itemsReducer.data || {};
		let rows = business.rows || {};
		return (
			<section className="page-items">
				<h1 className="access-elem">{businessMeta.h1}</h1>
				{mate_container}
				<div className="items-container">
					{Object.keys(rows || {}).map(function (key) {
						let value = rows[key];
						let items = value.items;
						let itemsNum = items.length;

						let sub_item = [];
						{
							Object.keys(items || {}).map(function (sbKey) {
								let itemVal = items[sbKey];
								let itemBgColor = itemVal.backgroundColor || {};
								let titleColor = itemVal.titleColor || "origin";
								let contentColor = itemVal.contentColor || "origin";
								let itemBgImg = "url(" + itemVal.backgroundImg + ")";
								let itemBgImgX = itemVal.imgFocusX || "origin";
								let itemBgImgY = itemVal.imgFocusY || "origin";
								let itemTitle = itemVal.title;
								let itemDesc = itemVal.desc;

								let itemTips = itemVal.tips || {};

								let itemHrefVal = itemVal.href;
								let itemHrefArrowColor = itemVal.hrefArrowColor || "origin";
								let itemHrefTxt = itemVal.hrefTxt;

								let itemLinks = itemVal.linkList;
								let itemLinksTitleColor = itemLinks.titleColor || "origin";
								let hyperlinkColor = itemLinks.hyperlinkColor || "origin";
								let itemLinksTitle = itemLinks.title;
								let itemLinksVal = itemLinks.links || {};
								let itemLinksNum = itemLinksVal.length;
								let hasItemLinks = itemLinksNum > 0;

								let item_link = [];
								{
									Object.keys(itemLinksVal || {}).map(function (linkKey) {
										let linkVal = itemLinksVal[linkKey];
										let linkTxt = linkVal.txt;
										let linkHref = linkVal.href;
										item_link.push(
											<li><a href={linkHref} target="_blank" className="item-links__link" style={{ "color": hyperlinkColor }}>{linkTxt}</a></li>
										);
									});
								}

								sub_item.push(
									<div
										className={
											(itemVal["backgroundImg"]) ?
												"item-container item-container--bg item-container--bg--x-" + itemBgImgX + " item-container--bg--y-" + itemBgImgY
												: ((itemVal["backgroundColor"]) ? "item-container item-container--bg--color" : "item-container")
										} style={
											(itemVal["backgroundImg"]) ?
												({ "backgroundImage": itemBgImg })
												: ({ "backgroundColor": itemBgColor })
										}>
										<div className={"item-content " + getLinksCols(itemsNum, itemLinksNum)}>
											{(itemVal["label"])
												? (<div className={"side-title"}>{itemVal["label"]}</div>)
												: null
											}
											{(itemVal["icon"])
												? (<div className={"item-icon"}><img src={`${process.env.PUBLIC_URL}` + itemVal["icon"]} alt={itemTitle} /></div>)
												: null
											}
											<div className={"item-content__title item-content__title--" + titleColor}>{itemTitle}</div>
											<p className={"item-content__desc item-content__desc--" + contentColor} dangerouslySetInnerHTML={{ __html: itemDesc }}></p>
											{(Object.getOwnPropertyNames(itemTips).length != 0)
												? (<div className="item-tips"><div className={"item-tips__title item-tips__title--" + titleColor}>{itemTips["title"]}</div><div className="item-tips__desc" dangerouslySetInnerHTML={{ __html: itemTips["desc"] }}></div></div>)
												: null
											}
											{hasItemLinks ?
												<div className={"item-links"}>
													<div className={"item-links__title item-links__title--" + itemLinksTitleColor}>{itemLinksTitle}</div>
													{(getCols(itemsNum) == 'full')
														? ((itemLinksNum < 5)
															? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
															: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
														)
														: ((itemLinksNum < 3)
															? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
															: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
														)
													}
												</div>
												: null
											}
											{itemHrefVal ?
												<div className="item-arrow">
													<a href={itemHrefVal} target="_blank" className={"item-arrow__link item-arrow__link--" + itemHrefArrowColor}>{itemHrefTxt}</a>
												</div>
												: null
											}
										</div>
									</div>
								);
							});
						}
						return (
							<ScrollAnimation animateIn="fadeIn" animateOnce="true" className={"items-row items-row--" + getCols(itemsNum)}>
								<div className="row-container">{sub_item}</div>
							</ScrollAnimation>
						);
					})}
				</div>
			</section>
		);
	}
}