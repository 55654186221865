
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import OverSeaEvents from '../components/EventsItems/OverSeaEvents';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  itemsReducer: state.itemsReducer,
  eventReducer: state.eventReducer,
	metaReducer: state.metaReducer,
  curPage: ownProps.curPage
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(OverSeaEvents);