
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import CookiesBar from '../components/CookiesBar/';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  i18n: state.i18n,
  item: ownProps.item
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(CookiesBar);