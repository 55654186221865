import React, { Component } from "react";
import store from "../../store/";
import { fetchItemsData } from "../../actions/";
import { fetchEventData } from "../../actions/";
import { fetchWebMetaData } from "../../actions/";

import PageMultiSliderContainer from "../../containers/PageMultiSliderContainer";
import MoreEventsListContainer from "../../containers/MoreEventsListContainer";
import PaginationContainer from '../../containers/PaginationContainer';
import MoreEventsCatContainer from '../../containers/MoreEventsCatContainer';
import { Helmet } from "react-helmet";

export default class EventsInHK extends Component {
	constructor(props) {
		super(props);
		store.dispatch(fetchEventData("/data/more-events-hk.json"));
		store.dispatch(fetchWebMetaData("/data/web-meta.json"));
		this.state = {	isClickActive: false, title:" ", tabsShow: true, curCat: "hkevents" };
	}

	render() {
		const clickState = this.state;
        const { itemsReducer } = this.props;
		const { events = {} } = itemsReducer.data || {};

        const { eventReducer } = this.props;
		const { eventSliders = {} } = eventReducer.data || {};
		let hasSliders = eventSliders.length>0;

		const { eventList = {} } = eventReducer.data || {};
		let hasEventList = eventList.length>0;

		let curPage = eventReducer.data.currentPage || '';
		let totalPage = eventReducer.data.totalPage || '';
		if(!curPage){curPage=1};
		if(!totalPage){totalPage=1};

		const { metaReducer } = this.props;
		const eventsMeta = metaReducer.data.events || {};
		let mate_container = [];
		mate_container.push(
			<Helmet>
				<meta itemprop="name" content={eventsMeta.title} />
				<meta itemprop="description" content={eventsMeta.description} />
				<meta itemprop="image" content={eventsMeta.img} />

				<meta name="twitter:card" content={eventsMeta.description} />
				<meta name="twitter:site" content={eventsMeta.url} />

				<meta property="og:title" content={eventsMeta.title} />
				<meta property="og:type" content={eventsMeta.type} />
				<meta property="og:url" content={eventsMeta.url} />
				<meta property="og:image" content={eventsMeta.img} />
				<meta property="og:description" content={eventsMeta.description} /> 
				<meta property="og:site_name" content={eventsMeta.sitename} />	
				<meta name="keywords" content={eventsMeta.keywords} />
			</Helmet>
		)
		return(
			<section className="page-hktdc-events">
			<h1 className="access-elem">{eventsMeta.h1}</h1>
				{mate_container}
				<div className="page-hktdc-events-slider">
					{eventReducer.isLoading ? 'Loading...' : ''}
					{hasSliders ?
						<PageMultiSliderContainer multiSliderObj={eventSliders}/>
					: null }
				</div>
				{hasEventList ?
				<div className="page-hktdc-events-list">
					{eventReducer.isLoading ? 'Loading...' : ''}
					<MoreEventsListContainer/> 
				</div>
				: null }
				<PaginationContainer totalPage={totalPage} />
				<MoreEventsCatContainer curCat={clickState.curCat}/>
			</section>
		);
	}
}