import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import ScrollAnimation from 'react-animate-on-scroll';
import PageIntroContainer from '../../containers/PageIntroContainer';
import SocialMediaContainer from '../../containers/SocialMediaContainer';

import WebTrends from '../WebTrends/';

export default class SocialMedia extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <ScrollAnimation className="sec-break" animateIn="fadeIn" animateOnce="true" />
        <PageIntroContainer curPage={"socialMedia"}/>
        <SocialMediaContainer />
        <WebTrends />
      </main>
    );
  }
}
