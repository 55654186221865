import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import SecondLevelBio from './SecondLevelBio';
import AnimateHeight from 'react-animate-height';

import * as CMSAPIs from '../../constants/api_url.js';

export default class ThreeMenInARow extends Component {

	constructor(props){
		super(props);
		this.state = { height: 120, expandBtnLabel: (this.props.meta.detail || "") }
	}

	expand(){
		//console.log(this.descElement);
		var height = this.descElement.clientHeight;
		if( this.state.height == height + 20 ) {
			this.setState({ height: this.shortDescElement.clientHeight + 28, expandBtnLabel: (this.props.meta.detail || "") });
		}
		else {
			this.setState({ height: height + 20, expandBtnLabel: (this.props.meta.hide || "") });
		}
	}

	updateRef (descElement) {
		//console.log(descElement);
		//console.log(this.descElement);

		if (this.descElement == undefined) {
			this.descElement = descElement;
			return;
		}

		if (this.descElement != undefined && descElement != undefined) {
			if (this.descElement.clientHeight < descElement.clientHeight) {
				this.descElement = descElement;
			}
		}
	}


	componentDidMount () {
		this.setState({ height: this.shortDescElement.clientHeight + 28 });
	}

	generateBios() {
		var bios = [];
		for( var i=0; i<this.props.members.length; i++ )
		{
			var itemThumb = this.props.members[i].thumbnail || '';
			if(itemThumb != '' && itemThumb.indexOf('//')<0){
				itemThumb = CMSAPIs.generateSrcPath(itemThumb);
			}
			const divStyle = {	
				backgroundImage: 'url('+itemThumb+')'
			}
			bios.push(
				<div key={i}>
					{this.props.members[i].thumbnail === undefined || this.props.members[i].thumbnail === '' ? '' : <div className="img-holder" style={divStyle} ></div>}
					<div className="bio">
						<p className="name" dangerouslySetInnerHTML={{ __html: this.props.members[i].name}} />
						<p className="position" dangerouslySetInnerHTML={{ __html: this.props.members[i].position}} />
						<p className="shortDesc desc" dangerouslySetInnerHTML={{ __html: this.props.members[i].shortDesc }}  ref={ (descElement) => this.shortDescElement = descElement } />
						<AnimateHeight duration={ 500 } height={ this.state.height } >
							<div ref={ (descElement) => this.updateRef(descElement) }>
							{
								(this.props.members[i].desc || "").split('<br /><br />').map((desc, index) => {
									desc = desc.replace('<p>', '').replace('</p>', '');
									return (
										<p key={index} className="desc" dangerouslySetInnerHTML={{ __html: desc }}   />
									);
								})
							}
							</div>
						</AnimateHeight>
						<button className={"ExpandBtn"} onClick={() => this.expand()}><span>{this.state.expandBtnLabel}</span></button>
						{(() => {
							var pro = CMSAPIs.getPublicDataPath(this.props.members[i].profile);
							/* if(pro != '' && pro.indexOf('//')<0){
								pro = CMSAPIs.generateSrcPath(pro);
							} */
							return (<button className={"ProfileBtn"} onClick={() => window.open(pro, '_blank') }><span className="download-icon" /><span>{this.props.meta.downloadProfile || ""}</span></button>);
						})()}
					</div>
				</div>
			);
		}
		return bios;
	}

	render(){
		return (
			<section className="management-threemen" style={{"backgroundColor": this.props.backgroundColor}}>
				<div className="wrapper">
					{ this.generateBios() }
				</div>
			</section>
		);
	}
}