import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class CookiesBar extends Component {
    constructor(props) {
        super(props);
        this.closeCookiesBar = this.closeCookiesBar.bind(this);
        this.state = { 'showCookiesBar': true };
    }
    closeCookiesBar() {
        this.setState({
            showCookiesBar: false
        });
    }
    render() {
        const { dataReducer } = this.props;
        const { data = {} } = dataReducer || {};
        const { cookiesBar = {} } = data || {};
        const btn_accept = cookiesBar.btn_accept || {};
        const btn_refuse = cookiesBar.btn_refuse || {};

        const closeCookiesBar = this.closeCookiesBar;
        const needShow = (cookiesBar.show == "false");
        let barClass = "cookies-bar";
        let barClassForBody = "show";
        if (!this.state.showCookiesBar || !needShow) {
            barClass = "cookies-bar is-hidden";
            barClassForBody = "not-show";
        }
        return (
            <div className={barClass}>
                {(needShow) ?
                    <div className="content-wrapper">
                        <div className="cookies-bar__holder">
                            <div className="cookies-bar__desc" dangerouslySetInnerHTML={{ __html: cookiesBar.desc }}></div>
                            <div className="cookies-bar__control">
                                <button onClick={closeCookiesBar} className="button button--s button--ghost-white cookies-bar__btn_refuse">{btn_refuse.txt}</button>
                                <button onClick={closeCookiesBar} className="button button--s button--ghost-white cookies-bar__btn-accept">{btn_accept.txt}</button>
                            </div>
                        </div>
                    </div>
                    : ""}
                <Helmet bodyAttributes={{ 'data-cookies-bar': barClassForBody }} />
            </div>
        )
    }
}
