import React, { Component } from "react";
import store from "../../store/";
import { fetchCareerData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import CareerItem from './CareerItem';

export default class CorporateInformation extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson(this.props);
	}

	swicthLanJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		store.dispatch(fetchCareerData(CMSAPIs.apiCareer, currentLang));
	}

	render() {

		const { careerReducer } = this.props;
		const staticData = careerReducer.data || {};
		const items = staticData.items || [];

		const metaData = staticData.metaData || {};

		return (
			<div className="career-focus">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:description" content={metaData.description} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				{
					<CareerItem
						items={items}
					/>
				}
			</div>
		);
	}
}