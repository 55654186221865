import React, { Component, useRef, useEffect } from 'react';
import store from "../../store/";
import { fetchContactUsData, fetchItemsData } from "../../actions/";

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

import PageIntroContainer from '../../containers/PageIntroContainer';
import GMap from './GMap';
import * as CMSAPIs from '../../constants/api_url.js';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

import DropDown from '../ContactUsDropDown/DropDown';

const crossImage = require('../../assets/images/common/icons/svg/black/icon-close.svg');


// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
// const useMountEffect = (fun) => useEffect(fun, [])
// const myRef = useRef(null)

// General scroll to element function
export default class ContactUs extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
		this.state = { isOverlayViewOpen: false, currentOfficeOnOverlayView: null, selectedCategory: null, highlightedPinIndex: -1, selectedLat: 22.2809863, selectedLng: 114.173800199999, zoom: false };
		// store.dispatch(fetchContactUsData("/data/contact-us.json"));
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		store.dispatch(fetchContactUsData(CMSAPIs.apiContactUs, currentLang));
		//store.dispatch(fetchContactUsData("/data/contact-us-" + currentLang + ".json"));
	}

	openOverlayView(index, offices) {
		var office = offices[index];
		this.setState({ isOverlayViewOpen: true, currentOfficeOnOverlayView: office, selectedLat: office.Latitude, selectedLng: office.Longitude });
	}

	closeOverlayView() {
		this.setState({ isOverlayViewOpen: false, currentOfficeOnOverlayView: null, highlightedPinIndex: -1 });
	}

	setHighlightedPinIndex(index) {
		this.setState({ highlightedPinIndex: index });
	}

	updateSelectedCategory(item, defaultView, defaultViewData) {
		if (item == null) {
			return;
		}
		var targetView = defaultView;
		if (defaultViewData[item.categories]) {
			targetView = defaultViewData[item.categories];
		}
		this.setState({ selectedCategory: item.categories, selectedLat: targetView.Latitude, selectedLng: targetView.Longitude, zoom: targetView.zoom })
	}

	render() {
		const { contactUsReducer } = this.props;
		var contactUsData = contactUsReducer.data || {};
		var mediaData = contactUsData.medias || [];

		const metaData = contactUsData.metaData || {};
		var defaultViewData = contactUsData.categoriesDefaultView || {};
		var defaultView = defaultViewData['Default'];
		//window.a = defaultViewData;

		/*
		renameObjKey(defaultViewData);
		function renameObjKey(json){
			var keys = Object.keys(json);
			for (var j=0; j < keys.length; j++) {
			   var key = keys[j]; // key
			   var value = json[key]; // data
			   delete json[key]; // deleting data with old key
			   key = key.replace(/\-/g, ' '); // renaming key
			   json[key] = value; // setting data with new key
			}
		}
		*/

		let globalNetworkContentsArr = [];

		if (Array.isArray(contactUsData.globalNetworkContents)) {
			globalNetworkContentsArr = contactUsData.globalNetworkContents;
		} else {
			globalNetworkContentsArr.push(contactUsData.globalNetworkContents);
		}

		let globalNeworkDetail = (globalNetworkContentsArr || []).map((content, index) => {
			if (content) {
				if (content.detail) {
					return (<p key={index} className="globalNetworkSubtitle" dangerouslySetInnerHTML={{ __html: content.detail }} />);
				}
			}
		})
		var categories = [];

		//Adjust office
		if (contactUsData.offices) {
			let officesArr = [];
			if (Array.isArray(contactUsData.offices)) {
				officesArr = contactUsData.offices;
			} else {
				officesArr.push(contactUsData.offices);
			}
			contactUsData.offices = officesArr;
		}

		return (
			<section className="page-items contactUsPage">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:description" content={metaData.description} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				<PageIntroContainer curPage={this.props.curPage} />
				{
					(this.props.curPage === "contactUs") ? (
						<div className="contactWrapper">
							<div className="contactMethodWrapper">
								<p>
									<img src={`${process.env.PUBLIC_URL}` + "/images/contactUs/phone.png"} />
									<span>{contactUsData.phone}</span>
								</p>
								<p>
									<img src={`${process.env.PUBLIC_URL}` + "/images/contactUs/fax.png"} />
									<span>{contactUsData.fax}</span>
								</p>
								<p>
									<img src={`${process.env.PUBLIC_URL}` + "/images/contactUs/pen.png"} />
									<a href={contactUsData.emailLink} target="_blank"><span>{contactUsData.email}</span></a>
								</p>
							</div>
							<div className="separateLine" />
							<div className="addressWrapper">
								<p className="addressTitle">{contactUsData.addressShort}</p>
								<p className="addressBody">{contactUsData.address}</p>
								<p className="addressBody">{contactUsData.OpeningHr}</p>
								<p className="addressBody">{contactUsData.ClosingHr}</p>

								<a className="staticImage" href="http://www.caringcompany.org.hk/index.php?&lang=1" target="_blank" ><img src={`${process.env.PUBLIC_URL}` + "/images/footer/icon-logo-demo-13.png"} /></a>
								<a className="staticImage" href={contactUsData.connectExpoUrl || ""} target="_blank" >{contactUsData.connectExpoImg ? <img src={CMSAPIs.generateSrcPath(contactUsData.connectExpoImg) || ""} /> : ''}</a>
							</div>
							<div>
							</div>
						</div>
					) : ""
				}

				<div id="globalNetwork" className="globalNetworkWrapper">
					<div className="wrapper">
						<p className="globalNetworkTitle">{contactUsData.globalNetworkTitle}</p>
						{globalNeworkDetail}
						<p className="committeDesc">{contactUsData.committesHeader}</p>
						{(contactUsData.committes) ?
							<div className="committeLinks">
								{(() => {
									if (Array.isArray(contactUsData.committes)) {
										return contactUsData.committes.map(function (item, i) {
											return item.url.trim() === "" ? <span key={i} className="committeLink">{item.name}</span> : <a key={i} target="_blank" className="committeLink" href={item.url}>{item.name}</a>;
										})
									} else if (contactUsData.committes.name) {
										return contactUsData.committes.url.trim() === "" ? <span className="committeLink">{contactUsData.committes.name}</span> : <a target="_blank" className="committeLink" href={contactUsData.committes.url}>{contactUsData.committes.name}</a>
									}

								})()}
							</div>
							:
							""
						}
						{(contactUsData.committesFooter) ?
							<p className="committeDesc">{contactUsData.committesFooter}</p>
							:
							""
						}
						{(() => {
							if (contactUsData.globalNetworkSubTitleLine3) {
								if (contactUsData.globalNetworkSubTitleLine3Url.trim() === "") {
									return <span className="committeDesc" dangerouslySetInnerHTML={{ __html: contactUsData.globalNetworkSubTitleLine3 }} />;
								} else {
									return <a target="_blank" href={contactUsData.globalNetworkSubTitleLine3Url} ><span className="committeDesc" dangerouslySetInnerHTML={{ __html: contactUsData.globalNetworkSubTitleLine3 }} /><span className="icon-right-arrow-angle" /></a>;
								}
							} else {
								return "";
							}
						})()}
						<div className="separateLine" />
						{(contactUsData.offices ?
							<div className="filter desktopFilter" >
								<li><b>{(contactUsData.meta || {}).filterBy || ""}</b></li>
								<li className={(this.state.selectedCategory == null) ? "clickable active" : "clickable"}
									onClick={() => this.setState({ selectedCategory: null, selectedLat: defaultView.Latitude, selectedLng: defaultView.Longitude, zoom: defaultView.zoom })}>{(contactUsData.meta || {}).viewAll || ""}</li>

								{(contactUsData.offices ?
									contactUsData.offices.map((item, i) => {
										var itemCat = item.categories;
										itemCat = itemCat.replace(' ', '-');
										if (!categories.includes(item.categories)) {
											categories.push(item.categories);
											var targetView = defaultView;
											var viewLabel = item.categories;
											if (defaultViewData[item.categories]) {
												targetView = defaultViewData[item.categories];
												viewLabel = targetView.label;
											}

											if (item.categories != "Head Office") {
												var count = 0;
												for (var j = 0; j < contactUsData.offices.length; j++) {
													if (contactUsData.offices[j].categories == item.categories) {
														count++;
													}
												}
												if (item.categories) {
													return <li key={i} onClick={() => this.setState({ selectedCategory: item.categories, selectedLat: targetView.Latitude, selectedLng: targetView.Longitude, zoom: targetView.zoom })} className={(this.state.selectedCategory == item.categories) ? "clickable active" : "clickable"}>{viewLabel + " (" + count + ")"}</li>;
												}
											}
											else {
												return <li key={i} onClick={() => this.setState({ selectedCategory: item.categories, selectedLat: targetView.Latitude, selectedLng: targetView.Longitude, zoom: targetView.zoom })} className={(this.state.selectedCategory == item.categories) ? "clickable active" : "clickable"}>{viewLabel}</li>;
											}

										}
									})
									:
									""
								)}
							</div>
							:
							""
						)}
						<div className="filter mobileFilter" >
							<li><b>{(contactUsData.meta || {}).filterBy || ""}</b></li>
							<DropDown defaultViewData={defaultViewData} defaultTitle={(contactUsData.meta || {}).viewAll || ""} offices={contactUsData.offices} categories={categories} selectedCategory={this.state.selectedCategory} updateSelectedCategory={(item) => this.updateSelectedCategory(item, defaultView, defaultViewData)} />
						</div>
						<div>
							{(contactUsData.globalNetworkSubTitleLine4) &&
								<a target="_blank" href={contactUsData.globalNetworkSubTitleLine4Url} ><span className="committeDesc" dangerouslySetInnerHTML={{ __html: contactUsData.globalNetworkSubTitleLine4 }} /><span className="icon-right-arrow-angle" /></a>
							}
						</div>
						<div>
							{(contactUsData.globalNetworkSubTitleLine5) &&
								<a target="_blank" href={contactUsData.globalNetworkSubTitleLine5Url} ><span className="committeDesc display-block" dangerouslySetInnerHTML={{ __html: contactUsData.globalNetworkSubTitleLine5 }} /><span className="icon-right-arrow-angle" /></a>
							}
						</div>
					</div>
				</div>
				<div id="globalNetworkMap" className="contactUsMapWrapper" >
					<div style={{ "height": "676px" }}>
						<GMap

							offices={contactUsData.offices}
							openOverlayView={(index) => this.openOverlayView(index, contactUsData.offices)}
							selectedCategory={this.state.selectedCategory}
							selectedLat={this.state.selectedLat}
							selectedLng={this.state.selectedLng}
							zoom={this.state.zoom}
							highlightedPinIndex={this.state.highlightedPinIndex}
							setHighlightedPinIndex={(index) => this.setHighlightedPinIndex(index)}
						/>
					</div>
					{(this.state.isOverlayViewOpen) ?
						<div className="overlayView">
							<p className="title">{this.state.currentOfficeOnOverlayView.title}</p>
							{
								(this.state.currentOfficeOnOverlayView.Thumbnail) ?
									<img src={CMSAPIs.generateSrcPath(this.state.currentOfficeOnOverlayView.Thumbnail)} className="thumbnail" />
									: ""
							}

							{
								(this.state.currentOfficeOnOverlayView.ContactPerson) ?
									<p className="directorName" dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.ContactPerson }} />
									: ""
							}

							<p className="directorPosition">{this.state.currentOfficeOnOverlayView.ContactPersonTitle}</p>

							<div className="contactWrapper">
								{(() => {
									let itemHTML = [];
									let thisAddress = this.state.currentOfficeOnOverlayView.contact.address;
									if (thisAddress) {
										if (typeof thisAddress == "string") {
											if (thisAddress.indexOf("\r\n") > 1) {
												thisAddress = thisAddress.split("\r\n");
											} else {
												thisAddress = thisAddress.split("\n");
											}
										}
										thisAddress.map(function (item, i) {
											itemHTML.push(
												<p className="contact" dangerouslySetInnerHTML={{ __html: item }} />
											)
										})
									}
									return itemHTML;
								})()}


								<p className="contact">{contactUsData.meta.tel || ""}: <span dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.contact.tel }} /> </p>
								{
									(this.state.currentOfficeOnOverlayView.contact.fax) ?
										<p className="contact">{contactUsData.meta.fax || ""}: <span dangerouslySetInnerHTML={{ __html: this.state.currentOfficeOnOverlayView.contact.fax }} /> </p> :
										""
								}
								{(() => {
									var emailText = this.state.currentOfficeOnOverlayView.contact.email;
									if (emailText) {
										return <p className="contact">{contactUsData.meta.email || ""}: <a href={'mailto:' + emailText}>{emailText}</a></p>
									}
								})()}
								{(() => {
									var websiteText = this.state.currentOfficeOnOverlayView.contact.website;
									if (websiteText) {
										return <p className="contact">{contactUsData.meta.website || ""}: <a href={websiteText}>{websiteText}</a></p>
									}
								})()}
							</div>

							<div className="socialMediaBar">
								{
									((mediaData || []).map((media, index) => {
										if (media.Office.toUpperCase() == this.state.currentOfficeOnOverlayView.BtnName.toUpperCase()) {
											if (media.icon.indexOf('share-wc') > -1) {
												//wechat
												return (
													<ButtonWechatContainer url={media.url} icon={media.icon} />
												)
											} else {
												if(media.Channel == "Twitter"){
													return (
														<button><a href={media.url} target="_blank">{media.icon ? <img src={CMSAPIs.generateSrcPath(media.icon)} style={{"height": "14px"}}/> : ''}</a></button>
													)
												}else {
													return (
														<button><a href={media.url} target="_blank">{media.icon ? <img src={CMSAPIs.generateSrcPath(media.icon)} /> : ''}</a></button>
													)
												}
											}
										}
										return ""
									}))
								}
							</div>

							<button onClick={() => this.closeOverlayView()} className={"crossBtn"} >{crossImage ? <img src={crossImage} /> : ''}</button>
						</div> : ""}
				</div>
			</section>
		);
	}
}