import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BtnShareContainer from "../../containers/BtnShareContainer";
import { Helmet } from "react-helmet";


const isETSPage =  window.location.href.toLowerCase().indexOf('/executive-trainee-programme') >  0 ;

export default class TopBar extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { dataReducer = {} } = this.props || {};
        let { data = {} } = dataReducer || {};
        let { topBar = {} } = data || {};
        let langTxt = data.lang || {};
        let langEN = langTxt['en'] || {};
        let langTC = langTxt['tc'] || {};
        let langSC = langTxt['sc'] || {};

        let { i18n } = this.props || {};
        let lang = i18n.locale;
        //change cookieConsent locale
        window.reloadPopup(false, lang);

        let lang_path = i18n.locale;
        let localeName = {
            'en': 'en',
            'tc': 'zh-hk',
            'sc': 'zh-cn'
        };

        let langElems = [];
        let currentURL = window.location.href;
        let rootPath = window.location.protocol + '//' + window.location.host + '/';
        if (currentURL.indexOf('/preview/aboutus') > 0) {
            rootPath = window.location.protocol + '//' + window.location.host + '/preview/aboutus/';
        }
        let hashPath = rootPath + '#/';
        let langCurrPath = "/" + lang + "/"
        if (currentURL.indexOf('/#/') > 0) {
            rootPath = hashPath;
        }
        let langLink1Path = currentURL.replace(rootPath, rootPath + "tc/");
        let langLink2Path = currentURL.replace(rootPath, rootPath + "sc/");
        if (currentURL.indexOf(langCurrPath) > 0) {
            langLink1Path = currentURL.replace(langCurrPath, "/tc/");
            langLink2Path = currentURL.replace(langCurrPath, "/sc/");
        } else if (currentURL.indexOf("/en") > 0) {
            langLink1Path = currentURL.replace("/en", "/tc/");
            langLink2Path = currentURL.replace("/en", "/sc/");
        }
        let langLink1Txt = langTC['txt_short'];
        let langLink2Txt = langSC['txt_short'];
        if (lang == 'tc') {
            if (currentURL.indexOf(langCurrPath) > 0) {
                langLink1Path = currentURL.replace(langCurrPath, "/en/");
                langLink2Path = currentURL.replace(langCurrPath, "/sc/");
            } else {
                langLink1Path = currentURL.replace("/tc", "/en/");
                langLink2Path = currentURL.replace("/tc", "/sc/");
            }
            langLink1Txt = langEN['txt_short'];
            langLink2Txt = langSC['txt_short'];
        } else if (lang == 'sc') {
            if (currentURL.indexOf(langCurrPath) > 0) {
                langLink1Path = currentURL.replace(langCurrPath, "/en/");
                langLink2Path = currentURL.replace(langCurrPath, "/tc/");
            } else {
                langLink1Path = currentURL.replace("/sc", "/en/");
                langLink2Path = currentURL.replace("/sc", "/tc/");
            }
            langLink1Txt = langEN['txt_short'];
            langLink2Txt = langTC['txt_short'];
        }
        //langLink1Path = langLink1Path.replace(rootPath,"");
        //langLink2Path = langLink2Path.replace(rootPath,"");

        let quick_links = topBar['quick_links'] || {};
        // let quick_links_elems = [];

        let quick_links_elems = Object.keys(quick_links || {})
            .map(function (key) {
                let val = quick_links[key];
                let txt = val.txt;
                let href = val.href;
                let external = val.external;

                if (external == 'true') {
                    return (
                        <a key={key} href={href} target="_blank" className="top-bar__link">
                            <span className="top-bar__txt">{txt}</span>
                        </a>
                    );
                } else {
                    return (
                        <a key={key} href={href} className="top-bar__link">
                            <span className="top-bar__txt">{txt}</span>
                        </a>
                    );
                }
            });

        return isETSPage?(
				<nav className="top-bar">
					<div className="top-bar__holder">
						{quick_links_elems}
					</div>
					<Helmet htmlAttributes={{ lang: localeName[lang] }} />
					<Helmet>
						<meta http-equiv="X-UA-Compatible" content="IE=edge" />
					</Helmet>
				</nav>
			):(
				<nav className="top-bar">
					<div className="top-bar__holder">
						{quick_links_elems}
					</div>
					<div className="top-bar__holder">
						<div className="top-bar__link">
							<div className="top-bar__subholder">
								<BtnShareContainer />
							</div>
							<span className="top-bar__icon top-bar__icon--share"></span>
						</div>
					</div>
					<div className="top-bar__holder">
						<a href={langLink1Path} className="top-bar__link">
							<span className="top-bar__txt top-bar__lang">{langLink1Txt}</span>
						</a>
						<a href={langLink2Path} className="top-bar__link">
							<span className="top-bar__txt top-bar__lang">{langLink2Txt}</span>
						</a>
					</div>
					<Helmet htmlAttributes={{ lang: localeName[lang] }} />
					<Helmet>
						<meta http-equiv="X-UA-Compatible" content="IE=edge" />
					</Helmet>
				</nav>
        );
    }
}