import React, { Component } from 'react';
import store from '../../store/configureStore.js';
import { Helmet } from "react-helmet";
import ExecutiveTraineeSchemePageContainer from '../../containers/ExecutiveTraineeSchemePageContainer';
import WebTrends from '../WebTrends/';

export default class ExecutiveTraineeSchemePage extends Component {
	
  constructor(props) {
    super(props);
  }
  
  render() {
	const pageName  =  this.props.match.params.pageName || "executive-trainee-programme"; 
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': pageName }} />
        <ExecutiveTraineeSchemePageContainer curPage={pageName}/>
        <WebTrends />
      </main>
    );
  }
  
}
