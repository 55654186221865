import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestSocialMediaData() {
	return { type: types.REQ_SOCIAL_MEDIA_DATA }
};

function receiveSocialMediaData(json) {
	var data = json["result"]["assets"][0]["content"]["content"]["social-media"];
	return {
		type: types.RECV_SOCIAL_MEDIA_DATA,
		data: data
	}
};

function receiveSocialMediaError(json) {
	return {
		type: types.RECV_SOCIAL_MEDIA_ERROR,
		data: json
	}
};

export function fetchSocialMediaData(url) {
	return function (dispatch) {
		dispatch(requestSocialMediaData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				//console.log(response.data);
				dispatch(receiveSocialMediaData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSocialMediaError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};