import React, { Component } from "react";
import store from "../../store/";
import { fetchManagementData, fetchItemsData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';

import OneManInARow from './OneManInARow';
import TwoMenInARow from './TwoMenInARow';
import ThreeMenInARow from './ThreeMenInARow';
import ManyMenInARow from './ManyMenInARow';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import { isArray } from "util";

export default class Management extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		store.dispatch(fetchManagementData(CMSAPIs.apiManagement, currentLang));
	}

	generateRows(managementRows) {
		var managementRowsArr = [];
		if (!Array.isArray(managementRows)) {
			managementRowsArr.push(managementRows);
			managementRows = managementRowsArr;
		}

		var rows = [];
		for (var i = 0; i < managementRows.length; i++) {
			var membersArr = [];
			if (!Array.isArray(managementRows[i].members)) {
				membersArr.push(managementRows[i].members);
				managementRows[i].members = membersArr;
			}

			if (managementRows[i].members.length == 1 || !managementRows[i].members.length) {
				rows.push(<OneManInARow key={i} meta={managementRows[i].meta} member={managementRows[i].members[0]} background={managementRows[i].background} />);
			}
			else if (managementRows[i].members.length == 2) {
				rows.push(<TwoMenInARow key={i} meta={managementRows[i].meta} members={managementRows[i].members} backgroundColor={managementRows[i].backgroundColor} />);
			}
			else if (managementRows[i].members.length == 3) {
				rows.push(<ThreeMenInARow key={i} meta={managementRows[i].meta} members={managementRows[i].members} backgroundColor={managementRows[i].backgroundColor} />);
			}
			else if (managementRows[i].members.length > 3) {
				rows.push(<ManyMenInARow key={i} members={managementRows[i].members} />);
			}
		}
		return rows;
	}

	render() {
		const { managementReducer } = this.props;
		const managementRows = managementReducer.data.managementData || [];
		const metaData = managementReducer.data.metaData || {};
		var rows = this.generateRows(managementRows);

		return (
			<section className="page-items">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />
					<meta itemprop="image" content={metaData.img} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:type" content={metaData.type} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:image" content={metaData.img} />
					<meta property="og:description" content={metaData.description} />
					<meta property="og:site_name" content={metaData.sitename} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				{rows}
			</section>
		);
	}
}