import React, { Component } from 'react';

import PageIntroContainer from '../../containers/PageIntroContainer';
import SuccessStoriesItemsContainer from '../../containers/SuccessStoriesItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

export default class Success extends Component {
  constructor(props) {
		super(props);
  }
  
  render() {
    return (
      <main>        
      <Helmet bodyAttributes={{ 'data-page': 'successStories' }} />    
        <PageIntroContainer curPage={"successStories"}/>
        <SuccessStoriesItemsContainer />
        <PageAdsContainer />
        <WebTrends />
      </main>
    );
  }
}
