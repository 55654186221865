import React, { Component } from 'react';
import store from '../../store/';
import { fetchMobileAppData } from '../../actions/';

export default class Accordion extends Component {
    constructor(props) {
        super(props);
        store.dispatch(fetchMobileAppData('/data/mobile-app-static.json'));
    }
    render() {
        const { mobileAppReducer } = this.props;
        const { data = {} } = mobileAppReducer || {};

        let itemElems = [];
        Object.keys(data || {}).map(function (key) {
            let value = data[key]
            let id = value.id;
            let big_icon = value.big_icon;
            let big_title = value.big_title;
            let big_thumb = value.big_thumb;
            let paragraphics = value.paragraphics;
            let txt_color = value.txt_color;
            let background_color = value.background_color;
            let background_image = value.background_image;
            let background_image_display = 'url(' + background_image + ')';
            let more_link = value.more_link;
            let links = value.links;
            let imgFocusX = value.imgFocusX;
            let imgFocusY = value.imgFocusY;
            let links_title = value.links_title;

            let itemClassName = "app-sec__item";
            if (txt_color) {
                itemClassName += " app-sec__item--" + txt_color;
            }

            let paragraphics_elems = [];
            Object.keys(paragraphics || {}).map(function (ckey) {
                let value = paragraphics[ckey];
                let title = value.title;
                let desc = value.desc;
                paragraphics_elems.push(<div className="app-sec__detail-item">
                    <h4 className="app-sec__detail-title">{title}</h4>
                    <p className="app-sec__detail-desc">{desc}</p>
                </div>);
            });

            let links_elems = [];
            Object.keys(links || {}).map(function (lkey) {
                let value = links[lkey];
                let icon = value.icon;
                let txt = value.txt;
                let href = value.href;
                links_elems.push(<a href={href} className="app-sec__link">
                    <img className="app-sec__link-icon" src={`${process.env.PUBLIC_URL}` + icon} alt="" />
                    <span>{txt}</span>
                </a>);
            });

            itemElems.push(<div className={itemClassName} style={{ "backgroundColor": background_color, "backgroundImage": background_image_display, "backgroundPositionX": imgFocusX + "%", "backgroundPositionY": imgFocusY + "%" }}>
                <div className="app-sec__inner">
                    <div className="app-sec__img">
                        { big_thumb ? <img src={`${process.env.PUBLIC_URL}` + big_thumb} alt="" /> :''}
                    </div>
                    <div className="app-sec__detail">
                        { big_icon ? <img className="app-sec__icon" src={`${process.env.PUBLIC_URL}` + big_icon} alt="" /> :''}
                        <h3 className="app-sec__big-title">{big_title}</h3>
                        <div className="app-sec__detail-items">
                            {paragraphics_elems}
                        </div>
                        <div className="app-sec__links">
                            <h4 className="app-sec__links-title">{links_title}</h4>
                            {links_elems}
                        </div>
                    </div>
                </div>
            </div>);
        });
        return (
            <div className="app-sec">
                <div class="app-sec__items">
                    {itemElems}
                </div>
            </div>
        )
    }
}
