import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";
import PageIntroContainer from '../../containers/PageIntroContainer';
import CompanyProfileContainer from '../../containers/CompanyProfileContainer';
import * as CMSAPIs from '../../constants/api_url.js';

import WebTrends from '../WebTrends/';

export default class EnhancingCapability extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'enhancingCapability' }} />
        <PageIntroContainer curPage={"enhancingCapability"}/>
        <CompanyProfileContainer json={CMSAPIs.apiEnhancingCapability}/>
        <WebTrends />
      </main>
    );
  }
}
