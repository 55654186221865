import React, { Component } from 'react';
import store from '../../store/';
import { fetchItemsData } from '../../actions/';

export default class PageAds extends Component {
  constructor(props) {
    super(props);		
    this.swicthLanJson(this.props);
	}

	swicthLanJson(obj){
		const{i18n} = this.props;
		let currentLang = i18n.locale;
		if(currentLang==null){currentLang="en"}
		//store.dispatch(fetchItemsData("/data/page-items-"+currentLang+".json"));
	}

  render() {   
    
    const { itemsReducer } = this.props;
    const { ads = {} } = itemsReducer.data || {};
    let ad = ads.ad || {};
    let adLink = ad['href'];
    return (
      <section className="sec-page-ads">
        <div className="ads-container">
          <div className="ad-container">
            <div className="ad-container__img">
              {(adLink&&ad['img'])
              ? <a href={adLink} target="_blank"><img src={`${process.env.PUBLIC_URL}` + ad['img']} alt={"adImg"}/></a>
              : <img src={`${process.env.PUBLIC_URL}` + ad['img']} alt={"adImg"}/>
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}