
import React, { Component } from "react";
import store from "../../store/";
import { fetchCompanyData } from "../../actions/";
import Popup from "reactjs-popup";

import { Helmet } from "react-helmet";
import ExpandableItem from './ExpandableItem';
import * as CMSAPIs from '../../constants/api_url.js';

export default class CompanyProfile extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false };
    this.swicthLanJson(this.props);
    // store.dispatch(fetchWebMetaData("/data/web-meta.json"));
    //store.dispatch(fetchCompanyData("/data/"+this.props.json));
  }

  swicthLanJson(obj) {
    const { i18n } = this.props;
    let currentLang = i18n.locale;
    if (currentLang == null) { currentLang = "en" }
    
    store.dispatch(fetchCompanyData(this.props.json, currentLang));
    //store.dispatch(fetchCompanyData("/data/" + this.props.json + currentLang + ".json"));
  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  render() {

    const { companyReducer } = this.props;
    const staticData = companyReducer.data || {};
    const mainbanner = staticData.mainbanner || {};
    const whatWeDo = staticData.whatWeDo || {};
    const items = staticData.items || [];
    const meta = staticData.meta || {};
    const metaData = staticData.metaData || {};

    var popupStyles = {
      background: 'rgb(0, 0, 0)'
    };

    let mate_container = [];
    mate_container.push(
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />

        <meta itemprop="name" content={metaData.title} />
        <meta itemprop="description" content={metaData.description} />

        <meta name="twitter:card" content={metaData.description} />
        <meta name="twitter:site" content={metaData.url} />

        <meta property="og:title" content={metaData.title} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
      </Helmet>
    )

    //console.log(companyReducer);

    return (
      <div className="page-items">
        {mate_container}
        <section>
          <div className="company-banner-container">
            <div className="left-panel">
              <h4>{mainbanner.mainTitle}</h4>
              <h5>{mainbanner.title || ""}</h5>
              <p>{mainbanner.content || ""}</p>
            </div>
            <div className="right-panel">
              { mainbanner.thumb ? <img src={CMSAPIs.generateSrcPath(mainbanner.thumb) || ""} /> :''}
            </div>
          </div>
        </section>
        <section>
          <div className="company-whatwedo-container">
            <div className="bottom-panel">
              <div className="left-panel">
                <div onClick={this.openModal}>
                  <a target="_blank">
                    { whatWeDo.thumb ? <img src={CMSAPIs.generateSrcPath(whatWeDo.thumb) || ""} /> :''}
                    <span className="icon-play-btn" />
                  </a>
                  <Popup
                    open={this.state.open}
                    closeOnDocumentClick
                    onClose={this.closeModal}
                    contentStyle={popupStyles}
                  >
                    <div className="modal">
                      <a className="close" onClick={this.closeModal}>
                        <span className="popup-close-btn" />
                      </a>
                      { whatWeDo.videoLink ? <iframe src={CMSAPIs.generateSrcPath(whatWeDo.videoLink) || ""} controls /> :''}
                    </div>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="company-items-container">
          {
            items.map((item, index) => {
              var itemContent;
              if(item.contents){
                itemContent = item.contents;
              } else {
                itemContent = item.content
              }
              return (
                <ExpandableItem
                  title={item.title || ""}
                  content={itemContent || []}
                  externals={item.externals || []}
                  url={item.url || ""}
                  meta={meta}
                />
              );
            })
          }
          <p>{meta.extract || ""}</p>
        </div>
        <section>

        </section>
      </div>
    );
  }

}