import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestWebMetaData() {
	return { type: types.REQ_WEB_META_DATA }
};

function receiveWebMetaData(json) {
	return {
		type: types.RECV_WEB_META_DATA,
		data: json
	}
};

function receiveWebMetaError(json) {
	return {
		type: types.RECV_WEB_META_ERROR,
		data: json
	}
};

export function fetchWebMetaData(url) {
	return function (dispatch) {
		dispatch(requestWebMetaData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
		.then(function (response) {
			dispatch(receiveWebMetaData(response.data));
		})
		.catch(function (response) {
			dispatch(receiveWebMetaError(response.data));
		})
	}
};