import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import PageIntroContainer from '../../containers/PageIntroContainer';
import NotFoundContainer from '../../containers/NotFoundContainer';
import WebTrends from '../WebTrends/';

export default class NotFound extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'career' }} />
        <PageIntroContainer curPage={"notFound"}/>
        <NotFoundContainer />
        <WebTrends />
      </main>
    );
  }
}
