
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import Council from '../components/ManagementItems/Council';

const mapStateToProps = (state, ownProps) => ({
  managementReducer: state.managementReducer,
  curPage: ownProps.curPage,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(Council);