
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import ContactUs from '../components/ContactUsItems/ContactUs';

const mapStateToProps = (state, ownProps) => ({
  contactUsReducer: state.contactUsReducer,
  curPage: ownProps.curPage,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(ContactUs);