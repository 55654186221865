
import React, { Component } from "react";
import store from "../../store/";
import { fetchOurWorkData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import OurWorkContent from '../AboutHome/OurWork';

export default class OurWork extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, videoLink: "" };
    this.swicthLanJson(this.props);
    // store.dispatch(fetchWebMetaData("/data/web-meta.json"));
    // store.dispatch(fetchHomeData("/data/about-home-en.json"));
  }

  swicthLanJson(obj) {
    const { i18n } = this.props;
    let currentLang = i18n.locale;
    if (currentLang == null) { currentLang = "en" }
    //store.dispatch(fetchHomeData("/data/about-home-" + currentLang + ".json"));
    store.dispatch(fetchOurWorkData(CMSAPIs.apiOurWork, currentLang));
  }

  render() {

    const { ourWorkReducer } = this.props;
    const staticData = ourWorkReducer.data || {};
    const ourWorkData = staticData.ourWork || [];
    const metaData = staticData.metaData || {};

    let mate_container = [];
    mate_container.push(
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />

        <meta itemprop="name" content={metaData.title} />
        <meta itemprop="description" content={metaData.description} />

        <meta name="twitter:card" content={metaData.description} />
        <meta name="twitter:site" content={metaData.url} />

        <meta property="og:title" content={metaData.title} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
      </Helmet>
    )

    return (
      <section className="ourwork-container">
        {mate_container}
        {
          <OurWorkContent
            items={ourWorkData}
          />
        }
      </section>
    );
  }

}