import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestCareerData() {
	return { type: types.REQ_CAREER_DATA }
};

function receiveCareerData(json, lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_CAREER_DATA,
		data: data
	}
};

function receiveCareerError(json) {
	return {
		type: types.RECV_CAREER_ERROR,
		data: json
	}
};

export function fetchCareerData(url, lang) {
	return function (dispatch) {
		dispatch(requestCareerData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
		.then(function (response) {
			dispatch(receiveCareerData(response.data, lang));
		})
		.catch(function (response) {
			dispatch(receiveCareerError(response.data));
		})
	}
};