import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import AnimateHeight from 'react-animate-height';

const upArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/upArrow.png";
const downArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/downArrow.png";


export default class ExpandableItem extends Component {
	constructor(props) {
		super(props);
		this.state={
			isOpened: false
		}
	}

	toggleView()
	{
		this.setState({ isOpened: !this.state.isOpened})
	}

	render() {
		return (
			<div class="expandableItem">
				<div class="wrapper">
				<AnimateHeight duration={ 500 } height={ this.state.isOpened ? 'auto' : 100 } >
					<div className="titleWrapper" onClick={() => this.toggleView()}>
						<p>{this.props.title}</p>
						<img className="arrowBtn" src={this.state.isOpened ? upArrow : downArrow } />
					</div>
					<div className="imageWrapper">
      					{(() => {
							let itemHTML = [];
							let contents = this.props.content;
							if(typeof contents == "string"){
								contents = contents.split("\r\n");
							}
							if(Array.isArray(contents)) {
								(contents || []).map((content) => {
									//console.log('@@@'+content);
									itemHTML.push (
										<li><span>{content}</span></li>
									)
								})
							} else {
								itemHTML.push (
									<li><span>{contents}</span></li>
								)
							}
							return itemHTML;
						})()}
						{
							(Array.isArray(this.props.externals)) ?
								(this.props.externals || []).map((extenral) => {
									return (
										<li className="emptyLi" ><a target="_blank" href={extenral.url} ><span>{extenral.title}</span><span className="icon-right-arrow-angle" /></a></li>
									)
								})
							:
							(
								(this.props.externals.url) ?
								(<li className="emptyLi" ><a target="_blank" href={this.props.externals.url} ><span>{this.props.externals.title}</span><span className="icon-right-arrow-angle" /></a></li>)
								:''

							)
						}
					</div>
					<div className="buttonWrapper">
						<button className="downloadBtn" onClick={() => window.open(this.props.url, '_blank')}><span />{this.props.meta.downloadBtn || ""}</button>
					</div>
				</AnimateHeight>
				</div>
			</div>
		);
	}
}