import { connect } from 'react-redux';
import React, { Component}  from 'react';
import Corporate from '../components/CorporateItems/Corporate';

const mapStateToProps = (state, ownProps) => ({
  corporateReducer: state.corporateReducer,
  curPage: ownProps.curPage,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(Corporate);


