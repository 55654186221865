
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import Sitemap from '../components/Footer/Sitemap';

const mapStateToProps = (state) => ({
  dataReducer: state.dataReducer,
  i18n: state.i18n,
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(Sitemap);