
import React, { Component } from 'react';
import Popup from "reactjs-popup";

export default class CareerItem extends Component {

  constructor(props) {
    super(props);
    this.state = { openCareer: false };
  }

  openCareer = () => {
    this.setState({ openCareer: true });
  };

  closeCareer = () => {
    this.setState({ openCareer: false });
  };

  render() {
    const items = this.props.items;

    var popupStyles = {
      background: 'rgb(0, 0, 0)'
    };

    return (
      <div className="market-focus__items">
        <div className="content-wrapper">
          {Object.keys(items || {}).map((key, index) => {

            const value = items[key];
            const title = value.title;
            const desc = value.desc;
            var bgImg = value.background || '';
            if (bgImg != '' && bgImg.indexOf('//') < 0) {
              bgImg = `${process.env.PUBLIC_URL}` + bgImg;
            }
            const background = "url(" + bgImg + ")";
            const target = "mf-detail-" + key;



            if (index == 0) {

              return (
                <div key={key} className="market-focus__item" onClick={this.openCareer}>
                  <a key={'mf-' + key} data-tar={target}>
                    <span className="market-focus__bg" style={{ "backgroundImage": background }}></span>
                    <span className="market-focus__info top">
                      <h3 className="market-focus__title">{title}</h3>
                    </span>
                    <span className="market-focus__info">
                      <span>{desc}</span><span className="icon-right-arrow-angle"></span>
                    </span>
                  </a>

                  <Popup
                    open={this.state.openCareer}
                    closeOnDocumentClick
                    onClose={this.closeCareer}
                    contentStyle={popupStyles}
                  >
                    <div className="careerPopup">
                      <a className="close" onClick={this.closeCareer}>
                        <span className="popup-close-btn" />
                      </a>
                      <h3>Post for open Application</h3>
                      <div className="bodyContent">
                        <p>
                          Please read the following notes before proceeding to fill in the application form or applying for a job.
                          <br /><br />
                          (a) The online application form is managed by a third party company and by connecting to the form, you are transferring to their website.
                          <br /><br />
                          (b) Whilst every care has been taken by the HKTDC in maintaining strict security standards and procedures to prevent unauthorised access to information about you, there is no warrant or guarantee the availability, effectiveness or performance of this website. To the extent permitted by law, you agree that no member of the HKTDC will be responsible in any way for direct, indirect, special or consequential damages, loss or unauthorised disclosure of information that you or any other person that may suffer or incur in connection with or arising out of the use, or misuse, of your login email name and password.
                          <br /><br />
                          (c) Your personal data may be used and processed by the HKTDC for application / employment purposes. The HKTDC and any third parties acting on its behalf will treat this information confidentially and will not use your personal data for any purpose other than that for which it was originally collected. Your personal data may be retained, unless precluded by applicable law, for up to one year in the candidate pool or six months after the selection of a particular position finalised. In any case, the HKTDC will retain and delete your personal data in accordance with applicable law.
                          <br /><br />
                          (d) Your application will not be considered if the mandatory information is not provided or it is not clear that you have the minimum qualifications, training, experience or other requirements as specified for the job. Please note that you need to submit application for each of your interested openings.
                          <br /><br />
                          (e) Once the online application is submitted, you should receive an acknowledgement of receipt by email. If you do not receive the acknowledgement, please re-submit your application. There is no need to make enquiry on the application status. Only shortlisted applicants will be contacted to go through the next phase of the recruitment process.
                          <br /><br />
                          (f) Your application will be referred to other suitable vacancies within the HKTDC and your personal data may then be made available to appropriate persons in the HKTDC.
                          <br /><br />
                          (g) If you lost internet connection during the process, please return to your application by logging in again. Should you experience difficulties in completing the application form, you can email to hr@recruit.hktdc.org.
                        </p>
                      </div>
                      <div>
                        <div className="button"><a href={value.url || ""} target="_blank">{value.accept}</a></div>
                        <div className="button" onClick={this.closeCareer} >{value.decline}</div>
                      </div>
                    </div>
                  </Popup>
                </div>
              )

            }
            return (
              <a target="_blank" className="market-focus__item" key={'mf-' + key} data-tar={target} href={value.url}>
                <span className="market-focus__bg" style={{ "backgroundImage": background }}></span>
                <span className="market-focus__info top">
                  <h3 className="market-focus__title">{title}</h3>
                </span>
                <span className="market-focus__info">
                  <span>{desc}</span><span className="icon-right-arrow-angle"></span>
                </span>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

}