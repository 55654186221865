
import React, { Component } from 'react';

import SearchPanelContainer from '../../containers/SearchPanelContainer';
import SearchListingContainer from '../../containers/SearchListingContainer';

import store from '../../store/';
import { getSearchResult } from '../../actions/';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

export default class SearchResult extends Component {
  constructor(props) {
    super(props);
  }
  getSearchData(){
    let paramsString = this.props.location.search;
    let params = new URLSearchParams(paramsString);
    let searchType = params.get('search-type');
    let searchQuery = params.get('search-query');
    let searchSorting = params.get('search-sorting');
    let page = params.get('page');
    if(!page){
      page = 1;
    }
    let searchData = {
      "paramsString":paramsString,
      "searchType": searchType,
      "searchQuery": searchQuery,
      "searchSorting": searchSorting,
      "page": page
    }
    return searchData;
  }
  componentDidMount() {
    let searchData = this.getSearchData();
    store.dispatch(getSearchResult('/data/search-result.json', searchData));
  }
  render() {
    let searchData = this.getSearchData();
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'search' }} />    
        <SearchPanelContainer searchData={searchData} />
        {searchData.searchQuery ?
          <SearchListingContainer searchData={searchData} />
          : ''}
          <WebTrends />
      </main>
    );
  }
}
