import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestContactUsData() {
	return { type: types.REQ_CONTACTUS_DATA }
};

function receiveContactUsData(json, lang) {
	if(lang){
		var data = json["result"]["assets"][0]["content"]["content"][lang];
	} else {
		data = json;
	}
	return {
		type: types.RECV_CONTACTUS_DATA,
		data: data
	}
};

function receiveContactUsError(json) {
	return {
		type: types.RECV_CONTACTUS_ERROR,
		data: json
	}
};

export function fetchContactUsData(url, lang) {
	return function (dispatch) {
		dispatch(requestContactUsData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				//console.log(response);
				dispatch(receiveContactUsData(response.data, lang));
			})
			.catch(function (response) {
				dispatch(receiveContactUsError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};