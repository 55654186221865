import React, { Component } from 'react';

import store from '../../store/configureStore.js';
import PageIntroContainer from '../../containers/PageIntroContainer';
import AboutHomeContainer from '../../containers/AboutHomeContainer';

import { Helmet } from "react-helmet";
import WebTrends from '../WebTrends/';

import ScrollAnimation from 'react-animate-on-scroll';

export default class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'aboutHome' }} />
        <PageIntroContainer curPage={"aboutHome"}/>
        <AboutHomeContainer />
        <WebTrends />
      </main>
    );
  }
}
