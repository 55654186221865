import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';
import PageIntroContainer from '../../containers/PageIntroContainer';
import OurCouncilContainer from '../../containers/OurCouncilContainer';

export default class OurCouncil extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <PageIntroContainer curPage={"ourCouncil"}/>
        <OurCouncilContainer />
        <WebTrends />
      </main>
    );
  }
}
