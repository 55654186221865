
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import OurMission from '../components/OurMission/OurMission';

const mapStateToProps = state => ({
    missionReducer: state.missionReducer,
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(OurMission);