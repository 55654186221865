import React, { Component } from 'react';
import store from '../../store/';
import { fetchItemsData } from "../../actions/";

export default class PageItemChildItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const getChildCol = (num) => {
      if (num > 0) {
        if (num > 1) {
          return " item-child-item--cols";
        } else {
          return " item-child-item--col";
        }
      } else {
        return "";
      }
    };

    const { itemsReducer } = this.props;
    let childItemObj = this.props.childObj;

    let childTitleColor = childItemObj.titleColor || "origin";
    let childContentColor = childItemObj.contentColor || "origin";

    let childMoreItemsHrefVal = childItemObj.href;
    let childMoreItemsHrefColor = childItemObj.hrefColor;
    let childMoreItemsHrefTxt = childItemObj.hrefTxt;

    let childItems = childItemObj.childItems || {};
    let childItemNum = childItems.length;

    let child_items = [];
    {
      Object.keys(childItems || {}).map(function (childItemKey) {
        let childItemVal = childItems[childItemKey];

        let childItemImg = childItemVal.img;
        let childItemTitle = childItemVal.title;
        let childItemDesc = childItemVal.desc;
        let childItemHrefVal = childItemVal.href;
        let childItemHrefArrowColor = childItemVal.hrefArrowColor || "origin";
        let childItemHrefTxt = childItemVal.hrefTxt;

        child_items.push(
          <div className="child-item-container">
            <div className="child-item__img"><a target="_blank" href={childItemHrefVal}>{ childItemImg ? <img src={`${process.env.PUBLIC_URL}` + childItemImg} alt={childItemTitle} /> :''}</a></div>
            <div className={"child-item__title child-item__title--" + childTitleColor}><a target="_blank" href={childItemHrefVal} className={"child-item__title child-item__title--" + childTitleColor}>{childItemTitle}</a></div>
            <div className="child-item-content">
              <span className={"child-item__desc child-item__desc--" + childContentColor} dangerouslySetInnerHTML={{ __html: childItemDesc }}></span>
              {childItemHrefTxt ?
                <div className="child-item__link-container">
                  <a target="_blank" href={childItemHrefVal} className={"child-item__link child-item__link--" + childItemHrefArrowColor}>
                    {childItemHrefTxt}
                  </a>
                </div>
                : null
              }
            </div>
            {!childItemHrefTxt ?
              <div className="child-item-arrow">
                <a target="_blank" href={childItemHrefVal} className={"child-item-arrow__link child-item-arrow__link--" + childItemHrefArrowColor}></a>
              </div>
              :
              <div className="child-item-arrow">
                <a target="_blank" href={childItemHrefVal} className={"item-arrow__link item-arrow__link--" + childItemHrefArrowColor}></a>
              </div>
            }
          </div>
        );
      });
    }

    return (
      <div class={"item-child-item " + getChildCol(childItemNum)}>
        <div class={"child-items-container"}>
          {child_items}
        </div>
        {childMoreItemsHrefVal ?
          <div className="child-more-item">
            {childMoreItemsHrefTxt ?
              <a target="_blank" href={childMoreItemsHrefVal} className={"child-more-item-text__link child-more-item-text__link--" + childMoreItemsHrefColor}>{childMoreItemsHrefTxt}</a>
              :
              <div className="item-arrow item-arrow--al-center">
                <a target="_blank" href={childMoreItemsHrefVal} className={"item-arrow__link item-arrow__link--" + childMoreItemsHrefColor}></a>
              </div>
            }
          </div>
          : null
        }
      </div>
    );
  }
}