import React, { Component } from "react";
import store from "../../store/";
import { fetchManagementData, fetchItemsData } from "../../actions/";

import OneManInARow from './OneManInARow';
import TwoMenInARow from './TwoMenInARow';
import ThreeMenInARow from './ThreeMenInARow';
import ManyMenInARow from './ManyMenInARow';
import * as CMSAPIs from '../../constants/api_url.js';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

export default class Council extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		//store.dispatch(fetchManagementData("/data/council-" + currentLang + ".json"));
		store.dispatch(fetchManagementData(CMSAPIs.apiCouncil, currentLang));
	}

	generateRows(managementRows) {
		var managementRowsArr = [];
		// if(!Array.isArray(managementRows)){
		// 	managementRowsArr.push(managementRows);
		// 	managementRows = managementRowsArr;
		// }
		var rows = [];

		if (managementRows.chairman) {
			rows.push(<OneManInARow key="OneManInARow" meta={managementRows.chairman.meta} member={managementRows.chairman} background={managementRows.chairman.background} />);
		}
		//rows.push(<OneManInARow meta={managementRows.chairman.meta} member={managementRows.chairman} background={managementRows.chairman.background} />);
		var memberArr = [];
		if (managementRows.members) {
			memberArr = managementRows.members;
			if (!Array.isArray(managementRows.members)) {
				memberArr.push(managementRows.members);
			} else {
				memberArr = managementRows.members;
			}
		}
		rows.push(<ManyMenInARow key="ManyMenInARow" members={memberArr} />);
		return rows;
	}

	render() {
		const { managementReducer } = this.props;
		const managementRows = managementReducer.data.managementData || [];
		const detail = managementReducer.data.detail;
		const metaData = managementReducer.data.metaData || {};
		var rows = this.generateRows(managementRows);
		
		return (
			<section className="page-items">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />
					<meta itemprop="image" content={metaData.img} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:type" content={metaData.type} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:image" content={metaData.img} />
					<meta property="og:description" content={metaData.description} />
					<meta property="og:site_name" content={metaData.sitename} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				{rows}
				<div className="counil-committee">
					<p>{((detail || [])).map((t, i) => {
						var thisUrl = t.url;
						if (thisUrl != '' && thisUrl.indexOf('//') < 0) {
							thisUrl = CMSAPIs.generateSrcPath(thisUrl);
						}
						return (
							<div key={i}>
								<span>{t.title || ""}</span>
								<a target="_blank" href={thisUrl || ""}><span>{t.button || ""}</span></a>
							</div>
						);
					})}</p>
				</div>
			</section>
		);
	}
}