import React, { Component } from "react";
import store from "../../store/";
import { fetchIntroData } from "../../actions/";

import BtnShareContainer from "../../containers/BtnShareContainer";

export default class PageIntro extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson();
	}
	componentWillMount() {
		this.swicthLanJson();
	}

	swicthLanJson() {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
	}

	getMenuView(menuArr, isFullScreen) {
		return (<section className={isFullScreen ? "sec-menu fullscreen" : "sec-menu"}>
			<div className="separator"></div>
			<div>
				{
					menuArr.map((menu, index) => {
						return (
							<div key={index}>
								<a href={menu.link} class={(this.props.curPage === menu.key) ? "top-bar__link active" : "top-bar__link"}><span class="top-bar__txt">{menu.title}</span></a>
								{
									(menu.child && Array.isArray(menu.child)) ?
										<div className="sec-submenu">
											{(menu.child || []).map((subitem, index) => {
												return (
													<div>
														<a href={subitem.link} class={(this.props.curPage === subitem.key) ? "top-bar__link active" : "top-bar__link"}><span class="top-bar__txt">{subitem.title}</span></a>
													</div>
												);
											})}
										</div> : ""
								}
							</div>
						);
					})
				}
			</div>
		</section>);
	}

	render() {
		const { introReducer } = this.props;
		let curPageStr = this.props.curPage;
		let introsArr = introReducer.data || {};
		let intro_content = [];
		let intro_detail = [];
		let hasDetail = false;
		let alignSubMenuTop = false;
		let fullScreenBackground = false;


		Object.keys(introsArr || {})
			.forEach(pageIntroKey => {
				if (pageIntroKey == curPageStr) {
					let introVal = introsArr[pageIntroKey];
					alignSubMenuTop = introVal.subMenuFlag;
					fullScreenBackground = introVal.fullscreenBackground;
					intro_content.push(
						<div key={pageIntroKey} className="content-wrapper">
							{
								(introVal.title.length) ? (
									<h2 className="sec-intro__title">
										{
											introVal.title.split("</br>").map(
												(t, i) => {
													return (
														<span key={i}>{t}<br></br></span>
													);
												}
											)
										}
									</h2>) : ""
							}
							{introVal.description ? <p className="sec-intro__desc" dangerouslySetInnerHTML={{ __html: introVal.description }}></p>
								: ''}
						</div>
					);
					let introDetail = introVal.details || '';
					if (introDetail) {
						hasDetail = true;
						Object.keys(introDetail || {})
							.forEach(pDKey => {
								let introDetailVal = introDetail[pDKey];
								let introDetailTitle = introDetailVal.detail_title;
								let introDetailSubtitle = introDetailVal.detail_subtitle;
								let introDetailDesc = introDetailVal.detail_desc;
								intro_detail.push(
									<div key={pDKey} className="sec-intro__detail-item">
										<h3 className="sec-intro__detail-title">{introDetailTitle}</h3>
										<h4 className="sec-intro__detail-subtitle">{introDetailSubtitle}</h4>
										<p className="sec-intro__detail-desc" dangerouslySetInnerHTML={{ __html: introDetailDesc }}></p>
									</div>
								);

							})
					}
				}
			});

		let secMenu = this.getMenuView((introsArr.subMenu || []), alignSubMenuTop);

		return (
			<div>
				<section className={(fullScreenBackground) ? "sec-intro no-background" : "sec-intro"}>
					{
						(fullScreenBackground) ? <div className="fix-background-sec"></div> : ''
					}
					{/* !alignSubMenuTop ? secMenu : '' */}
					{intro_content}
					{/* alignSubMenuTop ? secMenu : '' */}
				</section>
				{hasDetail ?
					<section className="sec-intro__detail">
						<div class="sec-intro__detail-wrapper">
							{intro_detail}
						</div>
					</section>
					: ''}
			</div>
		);
	}
}