import React, { Component } from 'react';

import PageIntroContainer from '../../containers/PageIntroContainer';
import BusinessItemsContainer from '../../containers/BusinessItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';
import WebTrends from '../WebTrends/';

import { Helmet } from "react-helmet";

export default class Business extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'business' }} />
        <PageIntroContainer curPage={"business"} />
        <BusinessItemsContainer />
        <PageAdsContainer />
        <WebTrends />
      </main>
    );
  }
}
