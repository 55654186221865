import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class Pagination extends Component {
    constructor(props) {
        super(props);
    }
    getPageHref(tarPage, currPage, paramsString) {
        let href = '';
        if (paramsString) {
            if (paramsString.indexOf('page=') > -1) {
                href = paramsString.replace('page=' + currPage, 'page=' + tarPage);
            } else {
                href = paramsString + '&page=' + tarPage;
            }
        } else {
            href = '?page=' + tarPage;
        }
        return href;
    }
    render() {
        const { ownProps = {} } = this.props;
        let page = ownProps.page || 1;
        let total_page = ownProps.totalPage || 1;

        page = parseInt(page);
        total_page = parseInt(total_page);
        if (page > total_page) {
            page = total_page;
        }

        let prevPage = page * 1 - 1;
        let nextPage = page * 1 + 1;

        let paramsString = ownProps.paramsString || '';

        let prevPageHref = this.getPageHref(prevPage, page, paramsString);
        let nextPageHref = this.getPageHref(nextPage, page, paramsString);

        let start_page = page * 1 - 5;
        if (start_page < 1) {
            start_page = 1;
        }

        let end_page = start_page + 9;
        if (end_page > total_page) {
            let end_exceed = end_page - total_page;
            end_page = total_page;
            start_page = start_page - end_exceed;
            if (start_page < 1) {
                start_page = 1;
            }
        }

        //console.log('start_page:' + start_page);
        let pageElems = []
        let len = end_page;
        let i;
        for (i = start_page; i <= len; i++) {
            let pageHref = this.getPageHref(i, page, paramsString);
            let pageClass = "pagination__page";
            if (i == page) {
                pageClass += " is-active";
            }
            pageElems.push(<a href={pageHref} className={pageClass}>{i}</a>);
        }

        return (
            <ScrollAnimation animateIn="fadeIn" animateOnce="true" className="pagination">
                <div className="content-wrapper">
                    {/*{page > 1 ? */}
                    <a href={prevPageHref} className="pagination__btn-prev">Previous</a>
                    {/*: ''} */}
                    <div className="pagination__pages">
                        {pageElems}
                    </div>
                    {/*{page < total_page ?  */}
                    <a href={nextPageHref} className="pagination__btn-next">Next</a>
                    {/*: ''} */}
                </div>
            </ScrollAnimation>
        )
    }
}
