import React, { Component } from 'react';
import store from '../../store/';
import { fetchSearchAutocomplete, switchTopSearch } from '../../actions/';

export default class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.state = { 'showSearchAutocomplete': false };
  }
  toggleSearch(id) {
    document.body.classList.toggle('search-panel-open');
  }
  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      //console.log('enter press here! ');
    } else {
      //this.setState({'showSearchAutocomplete': true})
      store.dispatch(fetchSearchAutocomplete('/data/search-autocomplete.json'));
    }
  }
  handleOptionChange(changeEvent) {
    var tarVal = changeEvent.target.value;
    store.dispatch(switchTopSearch(tarVal));
  }

  render() {
    const { dataReducer } = this.props;
    const { data = {} } = dataReducer || {};

    const { searchTypes = [] } = data || {};
    const searchTypesElems = [];
    const TopsearchElem = [];
    const handleOptionChange = this.handleOptionChange;
    const { topSearchReducer = {} } = this.props;
    const topSearchData = topSearchReducer.data || '';

    Object.keys(searchTypes || {}).map(function (key) {
      let value = searchTypes[key];
      let txt = value.txt;
      let val = value.value;
      let id = "header-search-filter-" + val;
      if (key < 1) {
        searchTypesElems.push(<div key={key} className="header-search__filter">
          <input onChange={handleOptionChange} name="search-type" type="radio" key={id} id={id} value={val} defaultChecked /><label htmlFor={id}>{txt}</label>
        </div>);
      } else {
        searchTypesElems.push(<div key={key} className="header-search__filter">
          <input onChange={handleOptionChange} name="search-type" type="radio" key={id} id={id} value={val} /><label htmlFor={id}>{txt}</label>
        </div>);
      }
      let topSearchItems = value.top_searchs;
      //Top Search
      if (topSearchData == val && topSearchItems.length > 0) {
        let topSearchItemsElems = [];

        Object.keys(topSearchItems || {}).map(function (tsKey) {
          let childVal = topSearchItems[tsKey];
          let txt = childVal.txt;
          //let val = childVal.value;
          let href = '/search?search-type='+val+'&search-query=' + txt;
          topSearchItemsElems.push(<a key={tsKey} href={`${process.env.PUBLIC_URL}`+href} className="header-search__topsearch-item">{txt}</a>);
        });
        TopsearchElem.push(<div key={key} className="header-search__topsearch">
          <h3 className="header-search__topsearch-title">{data.topSearchTxt}:</h3>
          {topSearchItemsElems}
        </div>);
      }
    });


    const { headerSearchReducer } = this.props;
    const dataAutoComplete = headerSearchReducer.data || {};
    const autoCompleteItems = dataAutoComplete.items || {};

    let autoCompleteItemsElems = [];
    if (autoCompleteItems.length) {
      Object.keys(autoCompleteItems || {}).map(function (key) {
        let value = autoCompleteItems[key];

        let itemTxt = value.txt;
        let itemHref = value.href;

        let autoCompleteSubItems = value.subitems;
        let autoCompleteSubItemsElems = [];

        Object.keys(autoCompleteSubItems || {}).map(function (cKey) {
          let childVal = autoCompleteSubItems[cKey];
          let txt = childVal.txt;
          let href = childVal.href;
          autoCompleteSubItemsElems.push(<a key={'cKey-' + cKey} href={'/search?search-type='+href+'&search-query='+itemTxt} className="header-search__autocomplet-subitem">{txt}</a>);
        });
        autoCompleteItemsElems.push(<div key={key} className="header-search__autocomplet-grp">
          <a href={'/search?search-query='+itemTxt} className="header-search__autocomplet-item">{itemTxt}</a>
          {autoCompleteSubItemsElems}
        </div>);
      });
    }
    return (
      <div className="header-search">
        <a href="javascript:;" onClick={this.toggleSearch.bind(null, 'search')} className="header-search__btn" action="search">
          <span className="header-search__txt-open">{data['searchTxt']}</span>
          <span className="header-search__txt-close">{data['searchCloseTxt']}</span>
        </a>
        <div className="header-search__panel">
          <div className="content-wrapper">
            <form action="/search" className="header-search__wrapper" autocomplete="off">
              <h2 className="header-search__title">{data.searchTxt}</h2>
              <div className="header-search__filters">
                {searchTypesElems}
              </div>
              <div className="header-search__input-holder">
                <input name="search-query" type="search" placeholder={data.searchHints} className="header-serach__input" onKeyDown={this.handleKeyPress} />
                {autoCompleteItemsElems.length ?
                  <div className="header-search__autocomplete">
                    {autoCompleteItemsElems}
                  </div> :
                  ''}
                <button type="submit" className="header-search__input-btn" />
              </div>
              {TopsearchElem}
            </form>
          </div>
        </div>
      </div>
    );
  }
}