import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import PageIntroContainer from '../../containers/PageIntroContainer';
import CareerContainer from '../../containers/CareerContainer';
import WebTrends from '../WebTrends/';

export default class CorporateInformation extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'career' }} />
        <PageIntroContainer curPage={"career"}/>
        <CareerContainer />
        <WebTrends />
      </main>
    );
  }
}
