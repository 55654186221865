import { connect } from 'react-redux';
import React, { Component}  from 'react';
import ExecutiveTraineeSchemePage from '../components/ExecutiveTraineeSchemePage/';

const mapStateToProps = state => ({
  executiveTraineeSchemePageReducer: state.executiveTraineeSchemePageReducer,
  match: state.match,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(ExecutiveTraineeSchemePage);