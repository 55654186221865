import React, { Component } from "react";


export default class OurWorkDetails extends Component {

  constructor(props) {
    super(props);
  }

  convertHex(hexCode, opacity) {
    var hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  }

  render() {
    let itemsHTML = [];
    this.props.items.map((item, index) => {
      itemsHTML.push(
        <div key={index} className="ourwork-item" >
          {/*<div className="ourwork-item__overlay" style={  this.props.themeColor ? ({"backgroundColor": this.convertHex( this.props.themeColor, 90)}) :'' }>
            <div className="ourwork-item__title">{item.title}</div>
            <div className="ourwork-item__text">{item.content}</div>
            </div>*/}
          <div className="ourwork-item__content" style={item.thumb ? ({ "backgroundImage": "url(" + item.thumb + ")" }) : ''}>
            <div className="ourwork-item__title">{item.title}</div>
            <div className="ourwork-item__text" dangerouslySetInnerHTML={{ __html: item.content }} />
          </div>
        </div>
      );
    })

    return (
      <div className="ourwork-content">
        <h4 className="ourwork-content__title">{this.props.title}</h4>
        <div className="ourwork-content__items">
          {itemsHTML}
        </div>
      </div>
    );
  }
}