import { Provider } from 'react-redux';
import React, { Component } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { setLocale } from 'react-redux-i18n';
import SmartBanner from 'react-smartbanner';
import '../node_modules/react-smartbanner/dist/main.css';
import { Provider as AlertProvider } from 'react-alert';
import 'react-responsive-modal/styles.css'

import { axios } from 'axios';
import store from './store/';

import 'babel-polyfill';

//CSS
import './assets/css/normalize.css';
import './assets/css/swiper.min.css';
import './App.css';
import LangHandler from './components/LangHandler/';

import Header from './components/Header/';
import Footer from './components/Footer/';
import AlertTemplate from './components/AlertTemplate';

import Home from './components/Layout/Home';
import OurMission from './components/Layout/OurMission';
import CompanyProfile from './components/Layout/CompanyProfile';
import EnhancingCapability from './components/Layout/EnhancingCapability';
import OurBusiness from './components/Layout/OurBusiness';
import OurManagement from './components/Layout/OurManagement';
import ContactUs from './components/Layout/ContactUs';
import GlobalNetwork from './components/Layout/GlobalNetwork';
import CorporateStructure from './components/Layout/CorporateStructure';
import CorporateInformation from './components/Layout/CorporateInformation';
import SocialMedia from './components/Layout/SocialMedia';
import Career from './components/Layout/Career';
import OurWork from './components/Layout/OurWork';
import OurCouncil from './components/Layout/OurCouncil';
import notfound from './components/Layout/NotFound';

import Business from './components/Layout/Business';
import Textsize from './components/Layout/Textsize';
import Events from './components/Layout/Events';
import HKEvents from './components/Layout/EventsInHK';
import OverSeaEvents from './components/Layout/OverSeaEvents';
import Services from './components/Layout/Services';
import Insights from './components/Layout/Insights';
import SearchResult from './components/Layout/SearchResult';
import MobileApp from './components/Layout/MobileApp';
import SuccessStories from './components/Layout/SuccessStories';
import FeedbackPanel from './components/FeedbackPanel';


import ExecutiveTraineeSchemePage from './components/Layout/ExecutiveTraineeSchemePage';

import AlertBarContainer from './containers/AlertBarContainer';
import CookiesBarContainer from './containers/CookiesBarContainer';

import { fetchData, fetchEventData, fetchIntroData } from './actions/';

// optional cofiguration
const options = {
  position: 'bottom right',
  timeout: 0,
  offset: '30px',
  transition: 'scale',
  zIndex: 300
}

function checkPlatform() {
  // Firefox 1.0+
  var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  // Chrome 1+
  var isChrome = !!window.chrome && !!window.chrome.webstore;

  var classBrowser = "is-other-browser";
  if (isFirefox) {
    classBrowser = "is-firefox";
  } else if (isSafari) {
    classBrowser = "is-safari";
  } else if (isIE) {
    classBrowser = "is-ie";
  } else if (isEdge) {
    classBrowser = "is-edge";
  } else if (isChrome) {
    classBrowser = "is-chrome";
  }
  document.body.classList.add(classBrowser);
  //load footer script
  // console.log(locale);
  // const script = document.createElement("script");
  // script.src = "https://home-uat.hktdc.com/common/js/footer/standard-en-latest.js";
  // script.async = true;
  // document.body.appendChild(script);
}

class App extends Component {
  constructor() {
    super();
    this.history = createHistory();
  }
  componentWillMount() {
    //store.dispatch(setLocale('en'));
    checkPlatform();
  }
  componentDidMount() {
  }

  componentDidUpdate() {
  }
  render() {
    return (
      <Provider store={store}>
        {/* <HashRouter> */}
        <Router basename={process.env.PUBLIC_URL} history={this.history}>
          <div className="app">
            <div className="app__top">
              <Route path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={AlertBarContainer}></Route>
              <Route path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={CookiesBarContainer}></Route>
              <Route path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={FeedbackPanel}></Route>
            </div>
            <div className="app__main">
              <Route path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={LangHandler}></Route>
              <Route path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={Header}></Route>
              <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?`} component={Home}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/our-mission`} component={OurMission}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/our-work`} component={OurWork}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/test`} component={OurBusiness}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/our-work/explore-connect`} render={(props) => (
                  <Redirect
                    to={`${process.env.PUBLIC_URL}/${props.match.params.locale ? props.match.params.locale + "/" : ""}our-work`}
                  />
                )}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/our-work/showcasing-hkservices`} render={(props) => (
                  <Redirect
                    to={`${process.env.PUBLIC_URL}/${props.match.params.locale ? props.match.params.locale + "/" : ""}our-work`}
                  />
                )}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/our-work/enhancing-hksme`} render={(props) => (
                  <Redirect
                    to={`${process.env.PUBLIC_URL}/${props.match.params.locale ? props.match.params.locale + "/" : ""}our-work`}
                  />
                )}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/the-council`} component={OurCouncil}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/the-management`} component={OurManagement}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/corporate-structure`} component={CorporateStructure}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/corporate-publications`} component={CorporateInformation}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/social-media`} component={SocialMedia}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/career`} component={Career}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/contact-us`} component={ContactUs}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/global-network`} component={GlobalNetwork}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/start-grow-business`} component={Business}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/join-fairs-conferences-events`} component={Events}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/more-hongkong-events`} component={HKEvents}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/more-outside-hongkong-events`} component={OverSeaEvents}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/source-products-services`} component={Services}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/explore-industry-market-insights`} component={Insights}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/search`} component={SearchResult}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/mobile-apps`} component={MobileApp}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/success-stories`} component={SuccessStories}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/notfound`} component={notfound}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/textsize`} component={Textsize}></Route>
                <Route exact path={`${process.env.PUBLIC_URL}/:locale(en|tc|sc)?/career/executive-trainee-programme/:pageName?`} component={ExecutiveTraineeSchemePage}></Route>
                <Route render={(props) => (
                  <Redirect
                    to={`${process.env.PUBLIC_URL}/${props.match.params.locale ? props.match.params.locale + "/" : ""}notfound`}
                  />
                )} />
              </Switch>
              {/* <Route path="/:locale(en|tc|sc)?" component={Footer}></Route> */}
              <div id="subportal-footer"></div>
            </div>
          </div>
        </Router >
        {/* </HashRouter> */}
      </Provider >
    );
  }
}

export default App;
