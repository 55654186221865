
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import OurWork from '../components/OurWork/OurWork';

const mapStateToProps = state => ({
	//homeReducer: state.ourWorkReducer,
    ourWorkReducer: state.ourWorkReducer,
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(OurWork);