import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import PageIntroContainer from '../../containers/PageIntroContainer';
import CorporateInformationContainer from '../../containers/CorporateInformationContainer';

import WebTrends from '../WebTrends/';

export default class CorporateInformation extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'corporatePublications' }} />
        <PageIntroContainer curPage={"corporatePublications"}/>
        <CorporateInformationContainer />
        <WebTrends />
      </main>
    );
  }
}
