import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import AnimateHeight from 'react-animate-height';
import ThumbItem from './ThumbItem';

const upArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/upArrow.png";
const downArrow = `${process.env.PUBLIC_URL}` + "/images/corporate/downArrow.png";


export default class ExpandableItem extends Component {
	constructor(props) {
		super(props);
		this.state={
			isOpened: false
		}
	}

	toggleView()
	{
		this.setState({ isOpened: !this.state.isOpened})
	}

	handleClick = () => {
		
	}

	render() {
		
		return (
			<div class="expandableItem">
				<div class="wrapper">
				<AnimateHeight duration={ 500 } height={ this.state.isOpened ? 'auto' : 100 } >
					<div className="titleWrapper" onClick={() => this.toggleView()}>
						<p>{this.props.title}</p>
						<img className="arrowBtn" src={`${process.env.PUBLIC_URL}` +  this.state.isOpened ? upArrow : downArrow } />
					</div>
					<div className="imageWrapper">
						<ul>
						{(() => {
							let itemHTML = [];
							let propsDesc = this.props.descriptions;
							if(propsDesc){
								let descriptionsArr = [];
								//console.log(propsDesc);
								if(typeof propsDesc == "string"){
									if(propsDesc.indexOf("\r\n")>1){
										propsDesc = propsDesc.split("\r\n");
									} else {
										propsDesc = propsDesc.split("\n");
									}
								}
								(propsDesc || []).map((description) => {
									itemHTML.push (
										<li><span dangerouslySetInnerHTML={{ __html: description }} /></li>
									)
								})
							}
							return itemHTML;
						})()}
						</ul>
					</div>
					<div className="imageWrapper">
						{(() => {
							let propsContents = this.props.contents;
							let htmlArr = [];
							if(propsContents){
								let contentsArr = [];
								if(!Array.isArray(propsContents)){
									contentsArr.push(propsContents);
									propsContents = contentsArr;
								}
								(propsContents || []).map((content) => {
									htmlArr.push(
										<ThumbItem
											type="link"
											item={content}
											hasRemark={false}
											isFullWidth={false}
											onClick={() => this.handleClick}
										/>
									)
								})
							}
							return htmlArr;
						})()}
					</div>
				</AnimateHeight>
				</div>
			</div>
		);
	}
}