import React, { Component } from 'react';

import PageIntroContainer from '../../containers/PageIntroContainer';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';

export default class Textsize extends Component {
  constructor(props) {
		super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <main>        
        <Helmet bodyAttributes={{ 'data-page': 'textsize' }} />    
        <PageIntroContainer curPage={"textsize"}/>
        <WebTrends />
      </main>
    );
  }
}
