import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestCompanyData() {
	return { type: types.REQ_COMPANY_DATA }
};

function receiveCompanyData(json,lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_COMPANY_DATA,
		data: data
	}
};

function receiveCompanyError(json) {
	return {
		type: types.RECV_COMPANY_ERROR,
		data: json
	}
};

export function fetchCompanyData(url,lang) {
	return function (dispatch) {
		dispatch(requestCompanyData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
		.then(function (response) {
			dispatch(receiveCompanyData(response.data,lang));
		})
		.catch(function (response) {
			dispatch(receiveCompanyError(response.data));
		})
	}
};