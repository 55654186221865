import React, { Component } from 'react';

export default class FeatureListing extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var items = this.props.listData;

        let itemsElems = [];
        if (items.length) {
            Object.keys(items || {}).map(function (key) {
                let value = items[key];

                let title = value.title;
                let link = value.href;
                let show_detail_head = false;

                let detail_title = value.detail_title || '';
                let detail_desc = value.detail_desc || '';

                if (detail_title) {
                    show_detail_head = true;
                }


                // Detail Infos
                let detail_infos = value.detail_infos;
                let detail_infos_elems = [];
                if (detail_infos) {
                    Object.keys(detail_infos || {}).map(function (diKey) {
                        let diVal = detail_infos[diKey];
                        detail_infos_elems.push(<div key={diKey} className="feature-listing__info">{diVal}</div>);
                    });
                }

                // Detail Title Icons
                let detail_title_icons = value.detail_title_icons;
                let detail_title_icons_elems = [];
                if (detail_title_icons) {
                    Object.keys(detail_title_icons || {}).map(function (dtiKey) {
                        let dtiVal = detail_title_icons[dtiKey];
                        let iconClass = "feature-listing__icon feature-listing__icon--" + dtiVal;
                        detail_title_icons_elems.push(<div key={dtiKey} className={iconClass}></div>);
                    });
                    show_detail_head = true;
                }

                // Detail Title Specs
                let detail_specs = value.detail_specs;
                let detail_specs_elems = [];
                if (detail_specs) {
                    Object.keys(detail_specs || {}).map(function (dsKey) {
                        let dsVal = detail_specs[dsKey];
                        detail_specs_elems.push(<div key={dsKey} className="feature-listing__spec">{dsVal}</div>);
                    });
                }

                // Detail Icons
                let detail_icons = value.detail_icons;
                let detail_icons_elems = [];
                if (detail_icons) {
                    Object.keys(detail_icons || {}).map(function (dicoKey) {
                        let dicoVal = detail_icons[dicoKey];
                        let iconClass = "feature-listing__icon feature-listing__icon--" + dicoVal;
                        detail_icons_elems.push(<div key={dicoKey} className={iconClass}></div>);
                    });
                }

                // Detail Btns
                let btns = value.btns;
                let btns_elems = [];
                if (btns) {
                    Object.keys(btns || {}).map(function (bKey) {
                        let bVal = btns[bKey];
                        let href = bVal.href;
                        let txt = bVal.txt;
                        btns_elems.push(<a key={bKey} href={href} className="feature-listing__btn">{txt}</a>);
                    });
                }

                // Thumbs
                let thumbs = value.thumbs;
                let thumbs_elems = [];
                if (thumbs) {
                    Object.keys(thumbs || {}).map(function (tKey) {
                        let tVal = thumbs[tKey];
                        thumbs_elems.push(<span key={tKey} className="feature-listing__img-holder">
                            { tVal ? <img className="feature-listing__thumb" src={`${process.env.PUBLIC_URL}` + tVal} alt="" /> :''}
                            <div className="feature-listing__pop">
                                { tVal ? <img src={`${process.env.PUBLIC_URL}` + tVal} alt="" /> :''}
                                <div className="feature-listing__popcap">
                                    <div className="feature-listing__popcap-title" dangerouslySetInnerHTML={{ __html: title }} />
                                    {btns_elems.length ? <div className="feature-listing__popcap-btns">
                                        {btns_elems}
                                    </div>
                                        : ''}
                                </div>
                            </div>
                        </span>);
                    });
                }



                itemsElems.push(<div key={key} className="feature-listing__item">
                    {title ? <a href={link} target="_blank"><h4 className="feature-listing__item-big-title" dangerouslySetInnerHTML={{ __html: title }} /></a> : ''}
                    <div className="feature-listing__item-row">
                        {thumbs_elems.length ? <div className="feature-listing__img">
                            {thumbs_elems}
                        </div>
                            : ''}
                        <div className="feature-listing__detail">
                            {show_detail_head ? <div className="feature-listing__item-head">
                                <h5 className="feature-listing__item-title">{detail_title}</h5>
                                {detail_title_icons_elems.length ? <div className="feature-listing__note-icons">
                                    {detail_title_icons_elems}
                                </div>
                                    : ''}
                            </div>
                                : ''}
                            {detail_infos_elems.length ? <div className="feature-listing__infos">
                                {detail_infos_elems}
                            </div>
                                : ''}
                            {detail_specs_elems.length ? <div className="feature-listing__specs">
                                {detail_specs_elems}
                            </div>
                                : ''}
                            {detail_specs_elems.length ? <div className="feature-listing__icons">
                                {detail_icons_elems}
                            </div>
                                : ''}
                                {detail_desc ? <p className="feature-listing__desc" dangerouslySetInnerHTML={{ __html: detail_desc }} /> : ''}
                            {btns_elems.length ? <div className="feature-listing__btns">
                                {btns_elems}
                            </div>
                                : ''}
                        </div>
                    </div>
                </div >);
            });
        }
        return (
            <div className="feature-listing">
                <div className="content-wrapper">
                    <div className="feature-listing__holder">
                        <div className="feature-listing__head">
                            <h3>Preferred Listing</h3>
                        </div>
                        <div className="feature-listing__items">
                            {itemsElems}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
