
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import SearchListing from '../components/SearchListing/';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  i18n: state.i18n,
  ownProps: ownProps,
  getSearchResultReducer: state.getSearchResultReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(SearchListing);