import React, { Component } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import ThirdLevelBio from './ThirdLevelBio';

export default class ManyMenInARow extends Component {

	generateBios() {
		var bios = [];
		let size = this.props.members.length;
		if (this.props.members.length % 2 == 1) {
			size += 1;
		}
		for (var i = 0; i < size; i++) {
			if (i < this.props.members.length) {
				bios.push(
					<ThirdLevelBio key={i} member={this.props.members[i]} />
				);
			} else {
				bios.push(
					<div key={i} className="noBorder">
						<div className="bio">
							<p className="name" />
							<p className="position" />
						</div>
					</div>
				);
			}

		}
		return bios;
	}

	render() {
		return (
			<section className="management-manymen" style={{ "backgroundColor": this.props.backgroundColor }}>
				<div className="wrapper">
					{this.generateBios()}
				</div>
			</section>
		);
	}
}