import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestData() {
	return { type: types.REQ_DATA }
};

function receiveData(json, lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_DATA,
		data: data
	}
};

function receiveError(json) {
	return {
		type: types.RECV_ERROR,
		data: json
	}
};

export function fetchData(url, lang) {
	return function (dispatch) {
		dispatch(requestData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				dispatch(receiveData(response.data, lang));
			})
			.catch(function (response) {
				dispatch(receiveError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};
