import React, { Component } from "react";
import AnimateHeight from 'react-animate-height';
import { TweenMax } from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class OurWorkCard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isOpened: false,
			showItems: false
		};

		this.itemsElm = null;
		this.itemsElmHeight = 0;
		const plugins = [ScrollToPlugin];
	}

	toggle() {
		this.setState({ isOpened: !this.state.isOpened }, () => {
			if (this.props.onChange) {
				this.props.onChange(this.state);
			}

			/*if(this.state.isOpened){
				TweenMax.to(window, 1, { scrollTo: this.itemsElm.getBoundingClientRect().top + window.scrollY });
			}*/

		});

	}
	/*	componentDidMount() {
	
		this.button.addEventListener('mouseover', );
		this.updateDimensions();
	
	  }
	  componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	  }
	
		*/
	onSliderAnimateEnd(obj) {

	}

	render() {

		let displayItems;
		let itemsHTML = this.props.items.map((item, index) => {
			return (
				<div key={index} className="ourwork-head__item" >
					<div className="ourwork-head__item__thumb" style={item.thumb ? ({ "backgroundImage": "url(" + item.thumb + ")" }) : ''} ></div>
					<div className="ourwork-head__item__title">{item.title}</div>
					<div className="ourwork-head__item__text" dangerouslySetInnerHTML={{ __html: item.content }} />
				</div>
			);
		})

		displayItems =
			<div className="ourwork-head__items" style={this.props.themeColor ? ({ "backgroundColor": this.props.themeColor }) : ''} ref={(instance) => { this.itemsElm = instance }} >
				<AnimateHeight duration={500} height={this.state.isOpened ? 'auto' : 0} onAnimationEnd={(obj) => this.onSliderAnimateEnd(obj)}>
					<div className="ourwork-head__items__arrow-down"></div>
					<div className="ourwork-head__items__title">{this.props.title}</div>
					{itemsHTML}
				</AnimateHeight>
			</div>

		return (
			<div className={`ourwork-head-wrapper ${this.state.isOpened == false ? '' : 'ourwork-head-wrapper--active'}`} ref={(instance) => { this.card = instance }}  >
				<div className="ourwork-head">
					{this.props.thumb ? <img className="ourwork-head__thumb" src={this.props.thumb || ""} /> : ''}
					<div className="ourwork-head__line" style={this.props.themeColor ? ({ "backgroundColor": this.props.themeColor }) : ''}></div>
					<h4 className="ourwork-head__title">{this.props.title}</h4>
					<button className={`ourwork-head__button ${this.state.isOpened == false ? '' : 'ourwork-head__button--active'}`} onClick={() => { this.toggle(); return false; }} type="button" >{this.state.isOpened ? this.props.hideBtn : this.props.detailBtn}</button>
					<div className="ourwork-head__arrow-down"></div>
				</div>
				{this.state.showItems ? displayItems : null}
			</div>
		);
	}

}