import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-responsive-modal';
import QRCode from 'qrcode.react';
import { DH_UNABLE_TO_CHECK_GENERATOR } from 'constants';


export default class buttonShare extends Component {
  constructor(props) {
    super(props);
    this.shareWebsite = this.shareWebsite.bind(this);
    this.PopupCenter = this.PopupCenter.bind(this);
    this.state = {
      open: false
    };
  }


  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  shareWebsite(channel) {

    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    var pageShareTitle = encodeURIComponent(document.getElementsByTagName("title")[0].innerHTML);
    var pageShareContent = window.location.href;
    var href = "";
    var type = 'default';
    var pageUrl = encodeURIComponent(window.location.href);
    var $windowCount = 0;
    switch (channel) {
      case 'email':
        href = "mailto:?subject=" + pageShareTitle + "&body=" + pageShareTitle + '%0A' + pageUrl;
        break;
      case 'wa':
        if (isMobile) {
          href = "whatsapp://send?text=" + pageShareTitle + ": " + pageUrl;
        } else {
          href = "https://web.whatsapp.com/send?text=" + pageShareTitle + ": " + pageUrl;
        }
        break;
      case 'fb':
        href = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl;
        break;
      case 'tw':
        href = "http://twitter.com/share?text=" + pageShareTitle + ": " + "&url=" + pageUrl;
        break;
      case 'ln':
        href = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl + "&title=" + pageShareTitle + "&source=LinkedIn";
        break;
      case 'wb':
        href = "http://service.weibo.com/share/share.php?url=" + pageUrl + "&title=" + pageShareTitle;
        break;
      case 'fbm':
        href = "http://www.facebook.com/dialog/send?app_id=123456789&link=" + pageUrl;
        break;
    }
    this.PopupCenter(href, "shareWindow_" + $windowCount, 600, 500, type);
    $windowCount++;
  }
  PopupCenter(url, title, w, h, type) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }
  render() {
    const { dataReducer } = this.props;
    const { data = {} } = dataReducer || {};
    const { share = {} } = data || {};
    const { open } = this.state;
    return (
      <div className="button-share__holder">
        <h4 className="button-share__title">{share.title}:</h4>
        <div className="button-share__btn-holder">
          <button onClick={this.shareWebsite.bind(null, 'email')} className="button-share button-share--email"></button>
          <button onClick={this.shareWebsite.bind(null, 'wa')} className="button-share button-share--wa"></button>
          <button onClick={this.shareWebsite.bind(null, 'fbm')} className="button-share button-share--fbm"></button>
          <button onClick={this.onOpenModal} className="button-share button-share--wc"></button>
          <button onClick={this.shareWebsite.bind(null, 'fb')} className="button-share button-share--fb"></button>
          <button onClick={this.shareWebsite.bind(null, 'tw')} className="button-share button-share--tw"></button>
          <button onClick={this.shareWebsite.bind(null, 'ln')} className="button-share button-share--ln"></button>
          <button onClick={this.shareWebsite.bind(null, 'wb')} className="button-share button-share--wb"></button>
          <Modal open={open} center onClose={this.onCloseModal}>
            <div className="button-share__wcqr">
              <QRCode value={window.location.href} />
              <p className="button-share__wcqr-txt">{share.wechatDesc}</p>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}