
import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class NotFound extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, openCareer: false, isOverlayViewOpen: false, currentOfficeOnOverlayView: null };
    this.swicthLanJson();
  }

  componentWillMount() {
    //console.log('refreshed');
    this.swicthLanJson();
  }


  swicthLanJson() {
    const { i18n } = this.props;
    let currentLang = (i18n || {}).locale;
    if (currentLang == null) { currentLang = "en" }
  }


  render() {
    const { i18n } = this.props;
    let currentLang = (i18n || {}).locale;
    let meta_container = [];

    const label = {
      en: 'We\'re sorry, we couldn\'t find the page you requested.',
      tc: '很抱歉，您尋找的網頁暫時未能顯示。',
      sc: '很抱歉，您寻找的网页暂时未能显示。'
    }

    const title = {
      en: 'Page Not Found | About HKTDC',
      tc: '找不到網頁 | 關於香港貿發局',
      sc: '找不到网页 | 关於香港贸发局'
    }

    meta_container.push(
      <Helmet>
        <title>{title[currentLang]}</title>
      </Helmet>
    );

    const style = {
      width: '100%',
      position: 'relative',
      padding: '0px 40px 80px 40px',
      textAlign: 'center',
    }


    return (
      <div style={style}>
        {meta_container}
        <p>
          {label[currentLang]}
        </p>
      </div>
    );
  }

}