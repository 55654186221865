import React, { Component } from "react";
import store from "../../store/";
import { fetchHomeData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import CorporateInformationItem from '../AboutHome/CorporateInformationItem';

export default class CorporateInformation extends Component {
	constructor(props) {
		super(props);
		this.swicthLanJson();
		// store.dispatch(fetchHomeData("/data/about-home-en.json"));
	}

	componentWillMount() {
		//console.log('refreshed');
		this.swicthLanJson();
	}


	swicthLanJson() {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		//store.dispatch(fetchHomeData("/data/about-home-" + currentLang + ".json"));
		store.dispatch(fetchHomeData(CMSAPIs.apiHome, currentLang));
	}

	render() {

		const { homeReducer } = this.props;
		const staticData = homeReducer.data || {};
		const corporatePublications = staticData.corporatePublications || {};
		const metaData = staticData.infoMetaData || {};

		console.log({corporatePublications})

		return (
			<div className="corporate-information">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />
					<meta itemprop="image" content={metaData.img} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:type" content={metaData.type} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:image" content={metaData.img} />
					<meta property="og:description" content={metaData.description} />
					<meta property="og:site_name" content={metaData.sitename} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				{
					<CorporateInformationItem
						items={corporatePublications}
					/>
				}
			</div>
		);
	}
}