import * as types from '../constants/actionTypes';
import axios from 'axios';

function requestManagementData() {
	return { type: types.REQ_MANAGEMENT_DATA }
};

function receiveManagementData(json, lang) {
	var data = json["result"]["assets"][0]["content"]["content"][lang];
	return {
		type: types.RECV_MANAGEMENT_DATA,
		data: data
	}
};

function receiveManagementError(json) {
	return {
		type: types.RECV_MANAGEMENT_ERROR,
		data: json
	}
};

export function fetchManagementData(url, lang) {
	return function (dispatch) {
		dispatch(requestManagementData());
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json'
		})
			.then(function (response) {
				dispatch(receiveManagementData(response.data, lang));
			})
			.catch(function (response) {
				dispatch(receiveManagementError(response.data));
				//dispatch(pushState(null,'/error'));
			})
	}
};