
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import PageAds from '../components/PageItem/PageAds';

const mapStateToProps = state => ({
  dataReducer: state.dataReducer,
  itemsReducer: state.itemsReducer,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(PageAds);