
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import PageIntro from '../components/PageItem/PageIntro';

const mapStateToProps = state => ({
  dataReducer: state.dataReducer,
  introReducer: state.introReducer,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(PageIntro);