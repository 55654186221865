import React, { Component } from 'react';
import { Alert } from 'react-alert'

export default class AlertTemplate extends Component {
  render() {
    return (
      <div className="alert-template">
        <button onClick={this.props.closeAlertMsg} className="alert-template__close" ></button>
        <div className="alert-template__msg">
          <p>Tell us what you think of our new HKTDC homepage by <a href="#">clicking here</a>. In this trial period, you may also visit our previous <a href="#">desktop</a> or <a href="#">mobile</a> site. </p>
        </div>
      </div>
    )
  }
}
