import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import dataReducer from './dataReducer.js';
import eventReducer from './eventReducer.js';
import introReducer from './introReducer.js';
import itemsReducer from './itemsReducer.js';
import headerSearchReducer from './headerSearchReducer.js';
import topSearchReducer from './topSearchReducer.js';
import getSearchResultReducer from './getSearchResultReducer.js';
import mobileAppReducer from './mobileAppReducer.js';
import metaReducer from './metaReducer.js';
import homeReducer from './homeReducer.js';
import managementReducer from './managementReducer.js';
import corporateReducer from './corporateReducer.js';
import companyReducer from './companyReducer.js';
import businessReducer from './businessReducer.js';
import ourWorkReducer from './ourWorkReducer.js';
import socialMediaReducer from './socialMediaReducer.js';
import contactUsReducer from './contactUsReducer.js';
import missionReducer from './missionReducer.js';
import careerReducer from './careerReducer.js';
import executiveTraineeSchemePageReducer from './executiveTraineeSchemePageReducer.js';


const rootReducer = combineReducers({
    eventReducer,
    dataReducer,
    introReducer,
    itemsReducer,
    headerSearchReducer,
    topSearchReducer,
    getSearchResultReducer,
    mobileAppReducer,
    metaReducer,
    homeReducer,
    companyReducer,
    businessReducer,
    ourWorkReducer,
    i18n: i18nReducer,
    managementReducer,
    corporateReducer,
    socialMediaReducer,
    contactUsReducer,
    missionReducer,
    careerReducer,
	executiveTraineeSchemePageReducer
});

export default rootReducer;