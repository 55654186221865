import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

//translate
import store from '../../store/';
import BtnShareContainer from "../../containers/BtnShareContainer";
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import translationsObject from '../../translations/index.js';
import PageIntro from '../../containers/PageIntroContainer';


//TODO: explore the possibility of loading content thru Axios
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
var I18n = require('react-redux-i18n').I18n;


export default class Mainnav extends Component {
  constructor(props) {
    super(props);
    // console.log('Mainnav Props:');
    // console.log(this.props);
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.backSubMenu = this.backSubMenu.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.state = { isHovered: false, onSubmenu: false };
  }

  handleClick(keyId) {
    var windowJsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowJsWidth > 991) {
      this.setState({
        isHovered: false
      });
    } else {
      document.body.classList.remove('mob-menu-open');
    }
    //console.log("test", keyId);
    if (keyId == "nav-link-8") {
      window.utag.view({
        "site_subsection": "Contact Us",
        "page_type": "section"
      });
      console.log("test", keyId, "Contact Us");
    } else if (keyId == "nav-link-9") {
      window.utag.view({
        "site_subsection": "Global Network",
        "page_type": "section"
      });
      console.log("test", keyId, "Global Network");
    }
  }
  handleHover(id) {
    var windowJsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowJsWidth > 991) {
      this.setState({
        isHovered: id
      });
    }
  }

  backSubMenu() {
    this.setState({ onSubmenu: false });

    var activeItem = document.querySelector('.nav-link.is-open');
    if (activeItem) { activeItem.classList.remove("is-open") }
  }

  toggleSubMenu(key) {
    const curOnSub = this.state.onSubmenu;
    this.setState({ onSubmenu: true });
    var tarId = "nav-link-" + key;
    var tar = document.getElementById(tarId);
    if (tar) { tar.classList.add('is-open'); }
  }

  render() {
    const backFun = this.backSubMenu;
    const toggleSubMenu = this.toggleSubMenu;
    let hoverItem = this.state.isHovered;
    let handleClick = this.handleClick;
    let handleHover = this.handleHover;

    function toggleMenu() {
      document.body.classList.toggle('mob-menu-open');
    }
    const { dataReducer } = this.props;
    const { data } = dataReducer || {};
	const isETSPage =  window.location.href.toLowerCase().indexOf('/executive-trainee-programme') >  0 ;
	 
	const ETSNavLinks  = {navLinks:[{
    "submenu": [{
        "txt": "Overview",
        "href": "/career/executive-trainee-programme"
    },{
        "txt": "Executive Trainee Opportunities",
        "href": "/career/executive-trainee-programme/opportunities"
    },{
        "txt": "Quotes from our Executive Trainees",
        "href": "/career/executive-trainee-programme/quotes"
    },{
        "txt": "Online Application",
        "href": "/career/executive-trainee-programme/online-application"
    }],
	"small_title": "",
    "big_title": "Executive Trainee Programme",
    "id": 1,
    "href": "#"
},
{
    "submenu": [],
	"small_title": "",
    "big_title": "About HKTDC",
    "id": 2,
    "href": "/en/"
}
	]};
	
	 const { navLinks } = (isETSPage?ETSNavLinks:data )|| {};
	
	
	
  

    const { topBar } = data || {};
    let lastNavLinkId = -1;
    if (navLinks) {
      lastNavLinkId = navLinks.length - 1;
    }

    let quick_links = (topBar || {}).quick_links || {};
    // let quick_links_elems = [];
    let quick_links_elems = Object.keys(quick_links || {})
      .map(function (key) {
        let val = quick_links[key];
        let txt = val.txt;
        let href = val.href;
        let external = val.external;

        if (external == 'true') {
          return (
            <NavLink key={key} exact to="/" className="top-bar__link">
              <span className="top-bar__txt">{txt}</span>
            </NavLink>
          );
        } else {
          return (
            <NavLink key={key} exact to="/" className="top-bar__link">
              <span className="top-bar__txt">{txt}</span>
            </NavLink>
          );
        }
      });

    const { i18n } = this.props || {};
    const lang = i18n.locale;
    const langTxt = (data || {}).lang || {};
    const langTitle = langTxt[`title_${lang}`] || '';

    let currentURL = window.location.href;
    let rootPath = window.location.protocol + '//' + window.location.host + '/';
    let hashPath = rootPath + '#/';
    let langCurrPath = "/" + lang + "/"
    if (currentURL.indexOf('/#/') > 0) {
      rootPath = hashPath;
    }
    let langLink1Path = currentURL.replace(rootPath, rootPath + "tc/");
    let langLink2Path = currentURL.replace(rootPath, rootPath + "sc/");
    if (currentURL.indexOf(langCurrPath) > 0) {
      langLink1Path = currentURL.replace(langCurrPath, "/tc/");
      langLink2Path = currentURL.replace(langCurrPath, "/sc/");
    } else if (currentURL.endsWith('/en')){
      langLink1Path = currentURL.replace('/en', "/tc/");
      langLink2Path = currentURL.replace('/en', "/sc/");
	}
    let langLink1Txt = '繁體中文';
    let langLink2Txt = '简体中文';
    if (lang == 'tc') {
      if (currentURL.indexOf(langCurrPath) > 0) {
        langLink1Path = currentURL.replace(langCurrPath, "/en/");
        langLink2Path = currentURL.replace(langCurrPath, "/sc/");
	  } else if (currentURL.endsWith('/tc')){
        langLink1Path = currentURL.replace('/tc', "/en/");
        langLink2Path = currentURL.replace('/tc', "/sc/");
	  }
      langLink1Txt = 'English';
      langLink2Txt = '简体中文';
    } else if (lang == 'sc') {
      if (currentURL.indexOf(langCurrPath) > 0) {
        langLink1Path = currentURL.replace(langCurrPath, "/en/");
        langLink2Path = currentURL.replace(langCurrPath, "/tc/");
	  } else if (currentURL.endsWith('/sc')){
        langLink1Path = currentURL.replace('/sc', "/en/");
        langLink2Path = currentURL.replace('/sc', "/tc/");
	  }
      langLink1Txt = 'English';
      langLink2Txt = '繁體中文';
    }

    return (
      <nav id="nav-link-holder" className={this.state.onSubmenu ? "nav-link__holder on-submenu" : "nav-link__holder"}>
        <div id="mob-share" className="nav-link__subholder">
          <BtnShareContainer />
        </div>
        <div id="mob-lang" className="nav-link__subholder">
          <h3 className="mob-lang__headlink">{langTitle}</h3>
          <a href={langLink1Path} className="mob-lang__link">
            <span>{langLink1Txt}</span>
          </a>
          <a href={langLink2Path} className="mob-lang__link">
            <span>{langLink2Txt}</span>
          </a>
        </div>
        <div id="mob-menu" className="nav-link__subholder">
          <a href="javascript:;" onClick={() => backFun()} className="header__submenu-back">Back</a>
          {dataReducer.isLoading ? 'Loading...' : ''}
          {Object.keys(navLinks || {}).map(function (key) {
            let value = navLinks[key];
            let big_title = value['big_title'];
            let small_title = value['small_title'];
            let href = value.href;
            let submenu = value.submenu;
            let keyId = "nav-link-" + key;

            let submenuOutput = '';
            let navClass = 'nav-link';
            if (key == lastNavLinkId) {
              navClass += ' is-last';
            }
            let hasSubmenu = false;
            if (submenu && submenu.length > 1) {
              hasSubmenu = true;
              let submenu_intro_links_elems = [];
              Object.keys(submenu || {}).map(function (slKey) {
                let childVal = submenu[slKey];
                let txt = childVal.txt;
                let href = childVal.href;

                submenu_intro_links_elems.push(<a key={'slKey-' + slKey} href={`${process.env.PUBLIC_URL}` + href} className="submenu__headlink">{txt}</a>);
              });

              //----Submenu More----//
              if (hoverItem == keyId) {
                navClass += " is-hover";
              }
              //----END Submenu More----//
              submenuOutput = (<div className="submenu submenu--about-us">
                <div className="submenu__wrapper">
                  {submenu_intro_links_elems}
                </div>
              </div>);
            }
            //----END Submenu Events----//
            return (
              <div key={'key-' + key} id={keyId} className={navClass} onMouseEnter={handleHover.bind(null, keyId)} onMouseLeave={handleHover.bind(null, false)} key={key}>
                {(href.indexOf('#') > -1 && href.indexOf('contact-us#globalNetwork') < 0) ?
                  <Link to={`${process.env.PUBLIC_URL}` + href} onClick={handleClick.bind(null, keyId)} activeclassname="is-active" className="nav-link__link">
                    <strong>{big_title}</strong>{small_title}
                  </Link>
                  :
                  <div className="nav-link__link">
                    <NavLink to={`${process.env.PUBLIC_URL}` + href} onClick={handleClick.bind(null, keyId)} activeclassname="is-active" className="nav-link__hidden"></NavLink>
                    <a href={`${process.env.PUBLIC_URL}` + href} onClick={handleClick.bind(null, keyId)}>
                      <strong>{big_title}</strong>{small_title}
                    </a>
                  </div>
                }
                {hasSubmenu ? <a href="javascript:;" onClick={() => toggleSubMenu(key)} className="nav-link__mob-toggle"></a>
                  : ''}

                {submenuOutput}
              </div>);
          })}
          {/*}
          <div>
            <PageIntro />
          </div>
        {*/}
          <div className="top-bar top-bar--mob">
            <div className="top-bar__holder">
              {quick_links_elems}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}