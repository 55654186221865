
import React, { Component } from "react";
import store from "../../store/";
import { fetchExecutiveTraineeSchemePageData } from "../../actions/";
import Popup from "reactjs-popup";
import * as CMSAPIs from '../../constants/api_url.js';
import { Helmet } from "react-helmet";

export default class ExecutiveTraineeSchemePage extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, videoLink: "" };
    this.swicthLanJson(this.props);
  }

  swicthLanJson(obj) {
    const { i18n,curPage } = this.props;
    let currentLang = i18n.locale;
    if (currentLang == null) { currentLang = "en" }
    store.dispatch(fetchExecutiveTraineeSchemePageData(CMSAPIs.apiExecutiveTraineeSchemePage.replace("Path:.xml",`Path:${curPage}.xml`), currentLang));
  }

  render() {
    const { executiveTraineeSchemePageReducer , match } = this.props;
    const staticData = executiveTraineeSchemePageReducer.data || {};
    const metaData = staticData.seo || {};
	
    return (
      <div className="etsPageContent">
        <section className="sec-intro static-page-container">
          <Helmet>
            <title>{metaData.seo_title}</title>
            <meta name="description" content={metaData.seo_desc} />
            <meta itemprop="name" content={metaData.seo_title} />
            <meta itemprop="description" content={metaData.seo_desc} />
            <meta name="twitter:card" content={metaData.seo_desc} />
            <meta name="twitter:site" content={metaData.url} />
            <meta property="og:title" content={metaData.seo_title} />
            <meta property="og:url" content={metaData.url} />
            <meta property="og:description" content={metaData.seo_desc} />
            <meta name="keywords" content={metaData.seo_keywords} />
			<style name="customCSS" type="text/css">{ staticData.css_style_block  }</style>
			<script name="customScript">{  staticData.js_script_block  }</script>
		  </Helmet>
		  <h2 className="sec-intro__title">
				{staticData.headline}
		  </h2>
        </section>
        <section className="static-page-intro-container">
		  <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: staticData.description }} />
        </section>
        <section className="static-page-body-container">
		  <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: staticData.content_body }} />
        </section>
		<section className="static-page-footer-container">
		  <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: staticData.content_footer }} />
        </section>
      </div>
    );
  }

}