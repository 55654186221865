import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';
import PageIntroContainer from '../../containers/PageIntroContainer';
import OurMissionContainer from '../../containers/OurMissionContainer';

export default class OurMission extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'ourMission' }} />
        <PageIntroContainer curPage={"ourMission"}/>
        <OurMissionContainer />
        <WebTrends />
      </main>
    );
  }
}
