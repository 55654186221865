import React, { Component } from "react";
import { Helmet } from "react-helmet";

import * as CMSAPIs from '../../constants/api_url.js';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

export default class MediaBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
		}
	}

	handleMediaLinkClickToOpenModal = (e, qrcode) => {
		e.preventDefault();
		console.log('handle click', qrcode)
	}

	//TODO: simplify the object and array "if hell"
	generateCategoryData() {
		var categoryCollection = [];
		if (!this.props.media) {
			return false;
		}
		let { categories } = this.props.media;

		if (!Array.isArray(categories)) {
			categories = [categories]
		}

		return categories.map((category) => {
			let { name, links } = category
			if (!Array.isArray(links)) {
				links = [links]
			}

			return (
				<div key={name}>
					<p className='category'>{name}</p>
					<ul>
						{
							links.map(link => (
								<li key={link.name}>
									<a target='_blank' href={link.qrcode ? 'javascript:void(0)' : link.url} onClick={(e) => link.qrcode && this.props.onOpenModal(e, link)}>
										{link.name}
									</a>
								</li>
							))
						}
					</ul>
				</div>
			);
		});
		
		// if (categories && categories.length > 1) {
		// 	const _categories = categories.map((category) => (
    //     <div key={category.name}>
    //       <p className='category'>{category.name}</p>
    //       <ul>
    //         {category.links.length > 1 ? (
    //           category.links.map((link) => (
    //             <li key={link}>
    //               <a target='_blank' href={!link.qrcode && link.url} onClick={link.qrcode && this.handleMediaLinkClickToOpenModal}>
    //                 {link.name}
    //               </a>
    //             </li>
    //           ))
    //         ) : (
    //           <li key={category.links}>
    //             <a target='_blank' href={category.links.url}>
    //               {category.links.name}
    //             </a>
    //           </li>
    //         )}
    //       </ul>
    //     </div>
    //   ));
		// 	categoryCollection.push(_categories);
		// 	for (var i = 0; i < this.props.media.categories.length; i++) {
		// 		var links = [];
		// 		if (this.props.media.categories[i].links.length > 1) {
		// 			for (var j = 0; j < this.props.media.categories[i].links.length; j++) {
		// 				links.push(<li key={j}><a target="_blank" href={this.props.media.categories[i].links[j].url}>{this.props.media.categories[i].links[j].name}</a></li>);
		// 			}
		// 		} else {
		// 			links.push(<li key={i}><a target="_blank" href={this.props.media.categories[i].links.url}>{this.props.media.categories[i].links.name}</a></li>);
		// 		}

		// 		var block = (
		// 			<div key={i}>
		// 				<p className="category">{this.props.media.categories[i].name}</p>
		// 				<ul>{links}</ul>
		// 			</div>
		// 		);

		// 		categoryCollection.push(block);
		// 	}
		// } else {
		// 	var links = [];
		// 	const _categories = (
    //     <div>
    //       <p className='category'>{this.props.media.categories.name}</p>
    //       <ul>
    //         {categories.links.length > 1 ? (
    //           categories.links.map((link) => (
    //             <li key={link}>
    //               <a target='_blank' href={link.url}>
    //                 {link.name}
    //               </a>
    //             </li>
    //           ))
    //         ) : (
    //           <li>
    //             <a target='_blank' href={categories.links.url}>
    //               {categories.links.name}
    //             </a>
    //           </li>
    //         )}
    //       </ul>
    //     </div>
    //   );
		// 	if (this.props.media.categories.links.length > 1) {
		// 		for (var j = 0; j < this.props.media.categories.links.length; j++) {
		// 			links.push(<li key={j}><a target="_blank" href={this.props.media.categories.links[j].url}>{this.props.media.categories.links[j].name}</a></li>);
		// 		}
		// 	} else {
		// 		links.push(<li><a target="_blank" href={this.props.media.categories.links.url}>{this.props.media.categories.links.name}</a></li>);
		// 	}

		// 	var block = (
		// 		<div>
		// 			<p className="category">{this.props.media.categories.name}</p>
		// 			<ul>{links}</ul>
		// 		</div>
		// 	);
		// 	categoryCollection.push(_categories);
		// }
		// return categoryCollection;
	}
	generateWCCategoryData() {
		if (!this.props.media) {
			return false;
		}
		var categoryCollection = [];
		if (this.props.media.categories.length > 1) {
			for (var i = 0; i < this.props.media.categories.length; i++) {

				var links = [];
				if (this.props.media.categories[i].links.length > 1) {
					for (var j = 0; j < this.props.media.categories[i].links.length; j++) {

						links.push(<li><ButtonWechatContainer url={this.props.media.categories[i].links[j].url} txt={this.props.media.categories[i].links[j].name} /></li>);
					}
				} else {
					links.push(<li><ButtonWechatContainer url={this.props.media.categories[i].links.url} txt={this.props.media.categories[i].links.name} /></li>);
				}

				var block = (
					<div>
						<p className="category">{this.props.media.categories[i].name}</p>
						<ul>{links}</ul>
					</div>
				);

				categoryCollection.push(block);
			}
		} else {


			var links = [];
			if (this.props.media.categories.links.length > 1) {
				for (var j = 0; j < this.props.media.categories.links.length; j++) {

					links.push(<li><ButtonWechatContainer url={this.props.media.categories.links[j].url} txt={this.props.media.categories.links[j].name} /></li>);
				}
			} else {
				links.push(<li><ButtonWechatContainer url={this.props.media.categories.links.url} txt={this.props.media.categories.links.name} /></li>);
			}

			var block = (
				<div>
					<p className="category">{this.props.media.categories.name}</p>
					<ul>{links}</ul>
				</div>
			);

			categoryCollection.push(block);
		}
		return categoryCollection;
	}

	render() {
		if (this.props.media) {
			console.log({ thisMedia: this.props.media })
			if (this.props.media.ico.indexOf('share-wc') > -1) {
				return (
					<div className="mediaBlock mediaBlock--wechat">
						{this.props.media.ico ? <img src={CMSAPIs.generateSrcPath(this.props.media.ico)} style={{ "width": "30px" }} /> : ''}
						<p className="platform">{this.props.media.platform}</p>
						{
							(this.props.media.categories) ? this.generateWCCategoryData() : ""
						}
					</div>
				);
			} else {
				if(this.props.media.platform == "Twitter"){
					return (
						<div className="mediaBlock">
							{this.props.media.ico ? <img src={CMSAPIs.generateSrcPath(this.props.media.ico)} style={{ "width": "25px" , "height": "25px"}} /> : ''}
							<p className="platform">{this.props.media.platform}</p>
							{
								(this.props.media.categories) ? this.generateCategoryData() : ""
							}
						</div>
					);
				} else {
					return (
						<div className="mediaBlock">
							{this.props.media.ico ? <img src={CMSAPIs.generateSrcPath(this.props.media.ico)} style={{ "width": "30px" }} /> : ''}
							<p className="platform">{this.props.media.platform}</p>
							{
								(this.props.media.categories) ? this.generateCategoryData() : ""
							}
						</div>
					);
				}
			}
		} else {
			return ("");
		}
	}
}