import React, { Component } from "react";
import store from "../../store/";
import { fetchCorporateData, fetchItemsData } from "../../actions/";

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import * as CMSAPIs from '../../constants/api_url.js';

import CorporateStructure from './CorporateStructure';

export default class Management extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
		this.state = { isOpened: [] };
		// store.dispatch(fetchCorporateData("/data/corporate-structure.json"));
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		store.dispatch(fetchCorporateData(CMSAPIs.apiCorporateStructure, currentLang));
		//store.dispatch(fetchCorporateData("/data/corporate-structure-" + currentLang + ".json"));
	}

	toggleCorpStruct(index) {
		//console.log(this.state.isOpened[index]);
		if (this.state.isOpened[index] != true) {
			this.state.isOpened[index] = true;

			for (var i = 0; i < this.state.isOpened.length; i++) {
				if (i != index && this.state.isOpened[i] == true) {
					this.state.isOpened[i] = false;
				}
			}
		}
		else {
			this.state.isOpened[index] = false;
		}
		this.setState({ isOpened: this.state.isOpened });
	}

	generateCorporateData(sections, meta) {
		var data = [];
		if (sections != null) {
			for (var i = 0; i < sections.length; i++) {
				data.push(
					<CorporateStructure
						key={i}
						section={sections[i]}
						index={i}
						isOpened={this.state.isOpened[i]}
						toggleCorpStruct={(index) => this.toggleCorpStruct(index)}
						download={meta.download || ""}
					/>
				);
			}
		}

		return data;
	}

	render() {
		const { corporateReducer } = this.props;
		var corporateData = corporateReducer.data;
		const metaData = corporateReducer.data.metaData || {};

		return (
			<section className="page-items">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />
					<meta itemprop="image" content={metaData.img} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:type" content={metaData.type} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:image" content={metaData.img} />
					<meta property="og:description" content={metaData.description} />
					<meta property="og:site_name" content={metaData.sitename} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				<div className="corporateStructureOverview">
					<div className="wrapper">
						<p>{corporateData.overview}</p>
					</div>
				</div>
				{this.generateCorporateData(corporateData.sections, corporateData.meta)}
			</section>
		);
	}
}