import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class FooterBar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { dataReducer } = this.props;
    const { data } = dataReducer || {};

    const { footer = {} } = data || {};
    const { footerBar = {} } = footer || {};

    return (
        <ScrollAnimation animateIn="fadeIn" animateOnce="true" className="footer-bar">
          <a href={footerBar['href']} className="content-wrapper">
            <h2 className="footer-bar__title">{footerBar['title']}</h2>
            <p className="footer-bar__desc">{footerBar['desc']}</p>
            <span className="footer-bar__arrow"></span>
          </a>
        </ScrollAnimation>
    );
  }
}