
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import PageItemChildItem from '../components/PageItem/PageItemChildItem';

const mapStateToProps = state => ({
	dataReducer: state.dataReducer,
	itemsReducer: state.itemsReducer
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(PageItemChildItem);