
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import AboutHome from '../components/AboutHome/AboutHome';

const mapStateToProps = state => ({
    homeReducer: state.homeReducer,
	contactUsReducer: state.contactUsReducer,
	ourWorkReducer: state.ourWorkReducer,
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(AboutHome);