
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import CompanyProfile from '../components/CompanyProfile/CompanyProfile';

const mapStateToProps = state => ({
    companyReducer: state.companyReducer,
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(CompanyProfile);