
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import Media from '../components/SocialMediaItems/Media';

const mapStateToProps = (state, ownProps) => ({
  data: state.dataReducer,
  socialMediaReducer: state.socialMediaReducer,
  curPage: ownProps.curPage,
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(Media);