
import { connect } from 'react-redux';
import React, { Component}  from 'react';
import ButtonWechat from '../components/ButtonWechat/';

const mapStateToProps = (state, ownProps) => ({
  dataReducer: state.dataReducer,
  i18n: state.i18n,
  ownProps: ownProps
});

export default connect(mapStateToProps, null, null, {
  pure: false
})(ButtonWechat);