import React, { Component } from 'react';

export default class Conformance extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { dataReducer } = this.props;
    const { data } = dataReducer || {};

    const { footer = {} } = data || {};
    const { conf = {} } = footer || {};

    const allItems = [];

    const rows = conf.rows;
    Object.keys(rows || {}).map(function (key) {
      let row = rows[key];
      const rowItems = [];

      let rowLen = row.length;
      let i = 0;
      for (i = 0; i < rowLen; i++) {
        let itemKey = 'item-'+key+'-'+i;
        let item = row[i];
        let href = item.href;
        let external = item.external;
        let img = item.img;
        if(img){
          if(external=='true'){
            rowItems.push(
              <a target="_blank" key={itemKey} href={href} className="comformance__item"><img src={`${process.env.PUBLIC_URL}` + img} alt="" /></a>
            );
          } else {
            rowItems.push(
              <a key={itemKey} href={href} className="comformance__item"><img src={`${process.env.PUBLIC_URL}` + img} alt="" /></a>
            );
          }
        }
      }
      allItems.push(
        <div class="comformance">
          <div class="content-wrapper">
            {rowItems}
          </div>
        </div>
      )
    })


    return (
      <div>
        {allItems}
      </div>
    );
  }
}