import React, { Component } from 'react';
import store from '../../store/';
import { fetchSearchAutocomplete } from '../../actions/';

export default class PageItemSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { 'showSearchAutocomplete': false };
  }
  handleKeyPress = (event) => {
    if (event.key == 'Enter') {
      //console.log('enter press here! ');
    } else {
      //this.setState({'showSearchAutocomplete': true})
      store.dispatch(fetchSearchAutocomplete('/data/search-autocomplete.json'));
    }
  }
  render() {
    const { dataReducer } = this.props;
    const { data = {} } = dataReducer || {};

    const itemSearchObj = this.props.itemSearchObj;
    let itemSearchType = itemSearchObj.searchType;
    let topSearchObj = itemSearchObj.top_searchs;
    let topSearchItemsElems = [];

    Object.keys(topSearchObj || {}).map(function (itsKey) {
      let childVal = topSearchObj[itsKey];
      let txt = childVal.txt;
      let href = '/search?search-type='+itemSearchType+'&search-query=' + txt;
      topSearchItemsElems.push(<a key={itsKey} href={href} className="item-search__topsearch-item">{txt}</a>);
    });

    const { headerSearchReducer } = this.props;
    const dataAutoComplete = headerSearchReducer.data || {};
    const autoCompleteItems = dataAutoComplete.items || {};

    let autoCompleteItemsElems = [];
    if (autoCompleteItems.length) {
      Object.keys(autoCompleteItems || {}).map(function (key) {
        let value = autoCompleteItems[key];

        let itemTxt = value.txt;
        let itemHref = value.href;

        let autoCompleteSubItems = value.subitems;
        let autoCompleteSubItemsElems = [];

        Object.keys(autoCompleteSubItems || {}).map(function (cKey) {
          let childVal = autoCompleteSubItems[cKey];
          let txt = childVal.txt;
          let href = childVal.href;
          autoCompleteSubItemsElems.push(<a key={'cKey-' + cKey} href={href} className="item-search__autocomplet-subitem">{txt}</a>);
        });
        autoCompleteItemsElems.push(<div key={key} class="item-search__autocomplet-grp">
          <a href={itemHref} className="item-search__autocomplet-item">{itemTxt}</a>
          {autoCompleteSubItemsElems}
        </div>);
      });
    }
    
    return (
      <div class="item-search">
        <div className="item-search__panel">
          <div class="content-wrapper">
            <form action="search-result/" className="item-search__wrapper">
              <div className="item-search__input-holder">
                <input name="search-query" type="search" placeholder={itemSearchObj.searchHints} className="item-search__input" onKeyPress={this.handleKeyPress} />
                {autoCompleteItemsElems.length ?
                  <div class="item-search__autocomplete">
                    {/* {autoCompleteItemsElems} */}
                  </div> :
                  ''}
                <button type="submit" className="item-search__input-btn" />
              </div>
              <div className="item-search__topsearch">
                <h3 className="item-search__topsearch-title">{itemSearchObj.topSearchTxt}:</h3>
                {topSearchItemsElems}
            </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}