import React, { Component } from 'react';

import store from '../../store/';
import * as CMSAPIs from '../../constants/api_url.js';

import { setLocale } from 'react-redux-i18n';

import { fetchData, fetchEventData, fetchIntroData, fetchItemsData } from '../../actions/';

function loadData() {
  //console.log('loadData');
  
  //store.dispatch(fetchData('data/events.json'));
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.initLang();
  }
  componentWillMount() {
    this.initLang();

    
    const { match } = this.props;
    const { params } = match || '';
    const lang = params.locale || 'en';
    store.dispatch(setLocale(lang));
    let chatbotScriptSrc1 = `${process.env.PUBLIC_URL}/js/lib/chatbot/${lang}/${process.env.REACT_APP_CHATBOT_JS}`;
    const chatbotScript1 = document.createElement("script");
    chatbotScript1.src = chatbotScriptSrc1;
    chatbotScript1.type = "text/javascript";
  }
  componentDidUpdate() {
    this.initLang();
  }
  initLang(){
    const { match } = this.props;
    const { params } = match || '';
    const lang = params.locale || 'en';
    store.dispatch(setLocale(lang));
    
    let filePath = '/data/static-content-'+lang+'.json';
    store.dispatch(fetchData(CMSAPIs.apiStaticContent, lang));
    store.dispatch(fetchIntroData(CMSAPIs.apiPageIntro, lang));
    //store.dispatch(fetchItemsData("/data/page-items-"+lang+".json"));


    let urlEnv = "";
    switch (CMSAPIs.global_env_settings) {
      case "sit":
        urlEnv = "-sit";
        break;
      case "uat":
        urlEnv = "-uat";
        break;
      case "prod":
        urlEnv = "";
        break;
    }

    const scriptSrc = `https://home${urlEnv}.hktdc.com/common/js/footer/standard-${lang}-latest.js`;
    
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return ('');
  }
}